import React from 'react';
import Logo from '../../../assets/images/logo.png';
import { height } from '@fortawesome/free-solid-svg-icons/fa0';

export default function Sibebar() {
	return (
		<div class="tab-sm-100 sidebar col-lg-3 col-md-4" style={{ paddingRight: 0 }}>
			<div class="sidebar-inner">
				<div class="client-img" style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<img src={Logo} alt=""  style={{height: '20rem', width: "20rem"}}  />
				</div>
				{/* <div style={{ height: '50vh' }}>
					<div class="client-name">
						<h5>Mira Peterson</h5>
						<p>Ceo & Designer</p>
					</div>
					<div class="client-testimonial">
						<div id="client-review" class="carousel slide" data-bs-ride="carousel">
							<div class="carousel-inner">
								<div class="carousel-item active">
									<div class="carousel-caption">
										<p>
											Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaliqut eeni m ad minim veniam, quis nostrud exercitation ullamco laboris
										</p>
										<div class="row">
											<div class="stars">
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
											</div>
										</div>
									</div>
								</div>
								<div class="carousel-item">
									<div class="carousel-caption">
										<p>
											Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaliqut eeni m ad minim veniam, quis nostrud exercitation ullamco laboris
										</p>
										<div class="row">
											<div class="stars">
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
											</div>
										</div>
									</div>
								</div>
								<div class="carousel-item">
									<div class="carousel-caption">
										<p>
											Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaliqut eeni m ad minim veniam, quis nostrud exercitation ullamco laboris
										</p>
										<div class="row">
											<div class="stars">
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
											</div>
										</div>
									</div>
								</div>
								<div class="carousel-item">
									<div class="carousel-caption">
										<p>
											Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magnaliqut eeni m ad minim veniam, quis nostrud exercitation ullamco laboris
										</p>
										<div class="row">
											<div class="stars">
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
												<i class="fa-solid fa-star"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="carousel-indicators">
								<button type="button" data-bs-target="#client-review" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
								<button type="button" data-bs-target="#client-review" data-bs-slide-to="1" aria-label="Slide 2"></button>
								<button type="button" data-bs-target="#client-review" data-bs-slide-to="2" aria-label="Slide 3"></button>
								<button type="button" data-bs-target="#client-review" data-bs-slide-to="3" aria-label="Slide 4"></button>
							</div>
						</div>
					</div>
				</div> */}
			</div>
		</div>
	)
}
