import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';

import { toast, Slide } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faArrowLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';

import { ProgressBar } from '../../../components/signup/progressbar/progressbar';
import Sibebar from '../../../components/signup/sidebar/sibebar';
import UploadComponent from '../../../components/signup/upload/upload';

import { validateEmail, isNotEmptyStringWithoutSpaces } from '../../../helpers/validate';

//IMAGES
import UserImage from '../../../assets/images/user-circle.png';
import CompanyImage from '../../../assets/images/company.jpg';
import Service1 from '../../../assets/images/service_icons/service-1.png'
import Service2 from '../../../assets/images/service_icons/service-2.png'
import Service3 from '../../../assets/images/service_icons/service-3.png'
import Robot from '../../../assets/images/robot.gif';



export default function InvestisseurPrive() {
    const refDate = useRef();
    const refDate2 = useRef();

    const history = useHistory();

    const [step, setStep] = useState(1);
    const totalSteps = 25;
    const progress = (step / totalSteps) * 100;

    const [civility, setCivility] = useState("");
    const [nomPrenom, setnomPrenom] = useState("");
    const [contact, setContact] = useState("");
    const [email, setEmail] = useState("");
    const [naturePiece, setNaturePiece] = useState("");
    const [numeroPiece, setNumeroPiece] = useState("");
    const [dateDelivrance, setDateDelivrance] = useState("");
    const [lieuDelivrance, setLieuDelivrance] = useState("");
    const [dateNaissance, setDateDeNaissance] = useState("");
    const [lieuDeNaissance, setLieuDeNaissance] = useState("");
    const [addresse, setAdresse] = useState("");
    const [situationFamiliale, setSituationFamiliale] = useState("");
    const [regimeMatrimonial, setRegimeMatrimonial] = useState("")
    const [situationProfessionnelle, setSituationProfessionnelle] = useState("")
    const [patrimoine, setPatrimoine] = useState("")
    const [patrimoineFinancier, setPatrimoineFinancier] = useState("")
    const [patrimoineFoncier, setPatrimoineFoncier] = useState("")
    const [empruntRemboursement, setEmpruntRemboursement] = useState("")
    const [revenuAnnuel, setRevenuAnnuel] = useState("")
    const [provenanceRevenu, setProvenanceRevenu] = useState("")
    const [partRevenu, setPartRevenu] = useState("")
    const [niveauStabilite, setNiveauStabilite] = useState("")
    const [typeInvestissement, setTypeInvestissement] = useState("")
    const [typeInvestissementAutre, setTypeInvestissementAutre] = useState("")
    const [preferenceSectoriel, setPreferenceSectoriel] = useState("")
    const [projetSimilaire, setProjetSimilaire] = useState("")
    const [attenteProjet, setAttenteProjet] = useState("")
    const [pourcentageEnBourse, setPourcentageEnBourse] = useState("")
    const [retourSurInvestissement, setRetourSurInvestissement] = useState("")
    const [horizonInvestissement, setHorizonInvestissement] = useState("")
    const [niveauRendement, setNiveauDeRendement] = useState("")
    const [epargne, setEpargne] = useState("")
    const [sommeInvestit, setSommeInvestit] = useState("")
    const [objectifInvest, setObjectifInvest] = useState("")
    const [variationsInvestissement, setVariationInvestissement] = useState("")
    const [rendementRisque, setRendementRisque] = useState("")

    const [files, setFiles] = useState([]);

    const handleFileUpload = (uploadedFiles) => {
        setFiles(uploadedFiles);
    };

    const sendToasError = (message, time) => {
        toast.error(message, {
            position: "top-right",
            autoClose: time,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Slide,
        });
    }

    const nextStep = () => {
        if (step === 1) {
            if (!isNotEmptyStringWithoutSpaces(civility) ||
                !isNotEmptyStringWithoutSpaces(nomPrenom) ||
                !isNotEmptyStringWithoutSpaces(contact) ||
                !validateEmail(email) ||
                !isNotEmptyStringWithoutSpaces(naturePiece) ||
                !isNotEmptyStringWithoutSpaces(numeroPiece) ||
                !isNotEmptyStringWithoutSpaces(dateDelivrance) ||
                !isNotEmptyStringWithoutSpaces(lieuDelivrance) ||
                !isNotEmptyStringWithoutSpaces(dateNaissance) ||
                !isNotEmptyStringWithoutSpaces(lieuDeNaissance) ||
                !isNotEmptyStringWithoutSpaces(addresse)) {
                return sendToasError("Veuillez remplir les champs", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 2) {
            if (isNotEmptyStringWithoutSpaces(situationFamiliale)) {
                if (!isNotEmptyStringWithoutSpaces(regimeMatrimonial)) {
                    return sendToasError("Choisir le régime matrimonial", 3500)
                }
                setStep(step + 1)
            }
            else {
                sendToasError("Veuillez faire un choix", 3500)
            }
        }
        else if (step === 3) {
            if (!isNotEmptyStringWithoutSpaces(situationProfessionnelle)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 4) {
            if (!isNotEmptyStringWithoutSpaces(patrimoine)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 5) {
            if (!isNotEmptyStringWithoutSpaces(patrimoineFinancier)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 6) {
            if (!isNotEmptyStringWithoutSpaces(patrimoineFoncier)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 7) {
            if (!isNotEmptyStringWithoutSpaces(empruntRemboursement)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 8) {
            if (!isNotEmptyStringWithoutSpaces(revenuAnnuel)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 9) {
            if (!isNotEmptyStringWithoutSpaces(provenanceRevenu)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 10) {
            if (!isNotEmptyStringWithoutSpaces(partRevenu)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 11) {
            if (!isNotEmptyStringWithoutSpaces(niveauStabilite)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 12) {
            if (isNotEmptyStringWithoutSpaces(typeInvestissement)) {
                if (typeInvestissement === 'type_invest_autre') {
                    if (!isNotEmptyStringWithoutSpaces(typeInvestissementAutre)) {
                        return sendToasError("Saisir le type d'investissement", 3500)
                    }
                    setStep(step + 1);
                }
            } else {
                sendToasError("Veuillez faire un choix", 3500)
            }

        }
        else if (step === 13) {
            if (!isNotEmptyStringWithoutSpaces(preferenceSectoriel)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 14) {
            if (!isNotEmptyStringWithoutSpaces(projetSimilaire)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 15) {
            if (!isNotEmptyStringWithoutSpaces(attenteProjet)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 16) {
            if (!isNotEmptyStringWithoutSpaces(pourcentageEnBourse)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 17) {
            if (!isNotEmptyStringWithoutSpaces(retourSurInvestissement)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 18) {
            if (!isNotEmptyStringWithoutSpaces(horizonInvestissement)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 19) {
            if (!isNotEmptyStringWithoutSpaces(niveauRendement)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 20) {
            if (!isNotEmptyStringWithoutSpaces(epargne)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 21) {
            if (!isNotEmptyStringWithoutSpaces(sommeInvestit)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 22) {
            if (!isNotEmptyStringWithoutSpaces(objectifInvest)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 23) {
            if (!isNotEmptyStringWithoutSpaces(variationsInvestissement)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 24) {
            if (!isNotEmptyStringWithoutSpaces(rendementRisque)) {
                return sendToasError("Veuillez faire un choix", 3500)
            }
            setStep(step + 1)
        }
    }

    const prevStep = () => {
        if (step > 1) {
            setStep(step - 1);
        } else {
            history.push('/signup')
        }
    };

    const handleSituationFamiliale = e => {
        setSituationFamiliale(e.target.value)
        // setPlafondInvestissement(e.target.value)
        if (e.target.value !== 'situation_marie') {
            setStep(step + 1)
        }
    }
    const handleSituationProfessionelle = e => {
        setSituationProfessionnelle(e.target.value)
        setStep(step + 1)
    }
    const handlePatrimoine = e => {
        setPatrimoine(e.target.value)
        setStep(step + 1)
    }
    const handlePatrimoineFinancier = e => {
        setPatrimoineFinancier(e.target.value)
        setStep(step + 1)
    }
    const handlePatrimoineFoncier = e => {
        setPatrimoineFoncier(e.target.value)
        setStep(step + 1)
    }

    const handleEmpruntRemboursement = e => {
        setEmpruntRemboursement(e.target.value)
        setStep(step + 1)
    }

    const handleRevenuAnnuel = e => {
        setRevenuAnnuel(e.target.value)
        setStep(step + 1)
    }

    const handleProvenanceRevenu = e => {
        setProvenanceRevenu(e.target.value)
        setStep(step + 1)
    }

    const handlePartRevenu = e => {
        setPartRevenu(e.target.value)
        setStep(step + 1);
    }
    const handleNiveauStabilite = e => {
        setNiveauStabilite(e.target.value)
        setStep(step + 1)
    }
    const handleTypeInvestissement = e => {
        setTypeInvestissement(e.target.value)
        if (e.target.value !== 'type_invest_autre') {
            setStep(step + 1)
        }
        // setStep(step + 1)
    }
    const handlePreferenceSectoriel = e => {
        setPreferenceSectoriel(e.target.value)
        setStep(step + 1)
    }
    const handleProjetSimilaire = e => {
        setProjetSimilaire(e.target.value)
        setStep(step + 1)
    }
    const handleAttenteProjet = e => {
        setAttenteProjet(e.target.value)
        setStep(step + 1)
    }
    const handlePourcentageEnBourse = e => {
        setPourcentageEnBourse(e.target.value)
        setStep(step + 1)
    }
    const handleRetourSurInvestissement = e => {
        setRetourSurInvestissement(e.target.value)
        setStep(step + 1)
    }
    const handleHorizonInvestissement = e => {
        setHorizonInvestissement(e.target.value)
        setStep(step + 1)
    }
    const handleNiveauDeRendement = e => {
        setNiveauDeRendement(e.target.value)
        setStep(step + 1)
    }
    const handleEpargne = e => {
        setEpargne(e.target.value)
        setStep(step + 1)
    }
    const handleSommeInvesti = e => {
        setSommeInvestit(e.target.value)
        setStep(step + 1)
    }
    const handleObjectifInvest = e => {
        setObjectifInvest(e.target.value)
        setStep(step + 1)
    }
    const handleVariationInvestissement = e => {
        setVariationInvestissement(e.target.value)
        setStep(step + 1)
    }
    const handleRendementRisque = e => {
        setRendementRisque(e.target.value)
        setStep(step + 1)
    }

    return (
        <div style={{ overflow: 'clip' }}>
            {step === 1 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div>
                            <div className="step-inner container">
                                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                    <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                    <p className='ms-2'>Retour</p>
                                </div>
                                <div className="p-5">
                                    <article className='mb-5'>
                                        <div className="step-heading text-center">
                                            {/* <img src={Robot} alt='robot-img' className='me-3' /> */}
                                            <div class="heading-marker"></div>
                                            <TypeAnimation
                                                sequence={[
                                                    "Présentez vous s'il vous plait !",
                                                    1000,
                                                    // () => {
                                                    //   console.log('Sequence completed');
                                                    // },
                                                ]}
                                                wrapper="h3"
                                                cursor={false}
                                                className='heading text-center'
                                            // style={{ fontSize: '2em', display: 'inline-block' }}
                                            />
                                        </div>
                                    </article>

                                    {/* <!-- step-2-form --> */}
                                    <div className="pop-right step-form" id="step3">
                                        <div className='form-part'>
                                            <div className='row'>
                                                <div class="col-md-2">
                                                    <div class="select-field">
                                                        <select id="selectservice" value={civility} onChange={e => setCivility(e.target.value)}>
                                                            <optgroup label='selectionnez la civilité'>
                                                                <option hidden>Civilité</option>
                                                                <option value="m">M.</option>
                                                                <option value="mme">Mme</option>
                                                                <option value="mlle">Mlle</option>
                                                            </optgroup>
                                                        </select>
                                                        {/* <span></span> */}
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <div class="select-field">
                                                        <input type="text" placeholder="Nom & Prénoms" value={nomPrenom} onChange={e => setnomPrenom(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <div class="select-field">
                                                        <input type="text" placeholder="Contact" value={contact} onChange={e => setContact(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="select-field">
                                                        <input type="email" id="mail-email" placeholder="Addresse Mail" value={email} onChange={e => setEmail(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="select-field">
                                                        <input type="text" placeholder="Addresse" value={addresse} onChange={e => setAdresse(e.target.value)} />
                                                    </div>
                                                </div>
                                                {/* <div class="col-md-6">
                                                    <div class="select-field">
                                                        <input type="text" placeholder="Contact" />
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className='row mt-5'>
                                                <div class="col-md-4">
                                                    <div class="select-field">
                                                        <select id="selectservice" value={naturePiece} onChange={e => setNaturePiece(e.target.value)}>
                                                            <optgroup label='selectionnez la nature'>
                                                                <option hidden>Nature de la piece</option>
                                                                <option value="m">CNI</option>
                                                                <option value="mme">Passport</option>
                                                                <option value="mlle">ect</option>
                                                            </optgroup>
                                                        </select>
                                                        {/* <span></span> */}
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="select-field">
                                                        <input type="text" placeholder="Numero de la piece" value={numeroPiece} onChange={e => setNumeroPiece(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="select-field">
                                                        <input type="text" placeholder="Lieu de delivrance" value={lieuDelivrance} onChange={e => setLieuDelivrance(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="select-field">
                                                        {/* <span>Date de delivrance de la pièce</span> */}
                                                        <input className='pe-5' placeholder={`Date de delivrance de la pièce`} type="text" value={dateDelivrance} onChange={e => setDateDelivrance(e.target.value)} ref={refDate} onFocus={() => (refDate.current.type = "date")} onBlur={() => (refDate.current.type = "date")} />
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="select-field">
                                                        {/* <span>Date de naissance</span> */}
                                                        <input className='pe-5' type="text" placeholder="Date de naisssance" value={dateNaissance} onChange={e => setDateDeNaissance(e.target.value)} ref={refDate2} onFocus={() => (refDate2.current.type = "date")} onBlur={() => (refDate2.current.type = "date")} />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="select-field">
                                                        <input type="text" placeholder="Lieu de naissance" value={lieuDeNaissance} onChange={e => setLieuDeNaissance(e.target.value)} />
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    {/* <!-- next previous button --> */}
                                    <div className="next-prev-btn">
                                        {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                                        <button type="button"
                                            id="step3btn"
                                            className="next"
                                            onClick={nextStep}
                                            style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 2 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "Quelle est votre situation familiale?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                        <FontAwesomeIcon data-tooltip-id="my-tooltip-inline" data-tooltip-content="La situation familiale peut influencer les capacités financières, la tolérance au risque, et les objectifs d'un investisseur" icon={faInfoCircle} style={{ marginLeft: 15, fontSize: 30 }} />
                                        <Tooltip
                                            id="my-tooltip-inline"
                                            style={{ backgroundColor: "#8e736a", color: "#fff" }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={situationFamiliale} onChange={handleSituationFamiliale}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>votre situation familiale</option>
                                                            <option value="type_fond_propre">Célibataire</option>
                                                            <option value="situation_marie">Marié(e)</option>
                                                            <option value="type_dette_moyen_terme">Divorcé(e)</option>
                                                            <option value="type_dette_long_terme">En concubinage</option>
                                                            <option value="type_subvention">Veuf(vé)</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                            {situationFamiliale === 'situation_marie' &&
                                                <>
                                                    <div class="col-md-12">
                                                        <h3 className='text-center'>Votre régime matrimonial</h3>
                                                        <div class="select-field">
                                                            <select name="select-service" id="selectservice" value={regimeMatrimonial} onChange={e => setRegimeMatrimonial(e.target.value)}>
                                                                <optgroup label='Selectionnez'>
                                                                    <option hidden>régime matrimonial</option>
                                                                    <option value="regime_communaute_de_bien">Communauté de bien</option>
                                                                    <option value="regime_separation_de_bien">Séparation de bien</option>
                                                                </optgroup>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="next-prev-btn">
                                                        {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                                                        <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 3 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "Quelle est votre situation professionnelle?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={situationProfessionnelle} onChange={handleSituationProfessionelle}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>votre situation professionnelle</option>
                                                            <option value="type_fond_propre">Actif(ve)</option>
                                                            <option value="type_dette_court_terme">Retraité(e)</option>
                                                            <option value="type_dette_moyen_terme">Profession libérale</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 4 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "Quelle est la valeur de votre patrimoine ?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={patrimoine} onChange={handlePatrimoine}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>Valeur patrimoine</option>
                                                            <option value="type_fond_propre">Entre 500 millions et 1 milliard</option>
                                                            <option value="type_dette_court_te">Entre 1 milliard et 3 milliard</option>
                                                            <option value="type_dette_moyen_ter">Entre 3 milliard et 5 milliard</option>
                                                            <option value="type_dette_moyen_term">Plus de 5 milliards</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 5 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "Quelle est la valeur de votre patrimoine financier?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={patrimoineFinancier} onChange={handlePatrimoineFinancier}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>votre patrimoine financier</option>
                                                            <option value="type_fond_propre">Entre 500 millions et 1 milliard</option>
                                                            <option value="type_dette_court_te">Entre 1 milliard et 3 milliard</option>
                                                            <option value="type_dette_moyen_ter">Entre 3 milliard et 5 milliard</option>
                                                            <option value="type_dette_moyen_term">Plus de 5 milliards</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 6 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "Quelle est la valeur de votre patrimoine foncier?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                        <FontAwesomeIcon data-tooltip-id="my-tooltip-inline" data-tooltip-content="Le patrimoine foncier désigne l'ensemble des biens immobiliers possédés par une personne ou une entreprise" icon={faInfoCircle} style={{ marginLeft: 15, fontSize: 30 }} />
                                        <Tooltip
                                            id="my-tooltip-inline"
                                            style={{ backgroundColor: "#8e736a", color: "#fff" }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={patrimoineFoncier} onChange={handlePatrimoineFoncier}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>votre situation professionnelle</option>
                                                            <option value="type_fond_propre">Entre 500 millions et 1 milliard</option>
                                                            <option value="type_dette_court_te">Entre 1 milliard et 3 milliard</option>
                                                            <option value="type_dette_moyen_ter">Entre 3 milliard et 5 milliard</option>
                                                            <option value="type_dette_moyen_term">Plus de 5 milliards</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 7 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "Avez-vous des emprunts en cours de remboursement ?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={empruntRemboursement} onChange={handleEmpruntRemboursement}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>emprunts en cours de remboursement</option>
                                                            <option value="type_fond_propre">Oui</option>
                                                            <option value="type_dette_court_te">Non</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 8 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "Pourriez vous nous indiquer une tranche de vos revenus annuels?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={revenuAnnuel} onChange={handleRevenuAnnuel}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>Tranche de vos revenus annuels</option>
                                                            <option value="type_fond_propre">Entre 500 millions et 1 milliard</option>
                                                            <option value="type_dette_court_te">Entre 1 milliard et 3 milliard</option>
                                                            <option value="type_dette_moyen_ter">Entre 3 milliard et 5 milliard</option>
                                                            <option value="type_dette_moyen_term">Plus de 5 milliards</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 9 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "D'où proviennent ces revenus ? ",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={provenanceRevenu} onChange={handleProvenanceRevenu}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>Provenance des revenus</option>
                                                            <option value="type_fond_propre">Revenus Professionnels</option>
                                                            <option value="type_dette_court_te">Revenus financiers</option>
                                                            <option value="type_dette_moyen_ter">Revenus immobiliers</option>
                                                            <option value="type_dette_moyen_term">Retraite</option>
                                                            <option value="type_dette_moyen_termee">Autres revenus</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 10 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "Quelles parts de vos revenus consacrez vous a vos depenses regulieres ?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={partRevenu} onChange={handlePartRevenu}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>Revenus consacré</option>
                                                            <option value="type_fond_propre">Plus de 75%</option>
                                                            <option value="type_dette_court_te">De 50 a 75%</option>
                                                            <option value="type_dette_moyen_ter">De 25 a 50%</option>
                                                            <option value="type_dette_moyen_term">Moins de 25%</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 11 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "Quel est le niveau de votre stabilité financière?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={niveauStabilite} onChange={handleNiveauStabilite}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>Revenus consacré</option>
                                                            <option value="type_fond_propre">Stable</option>
                                                            <option value="type_dette_court_te">Instable</option>
                                                            <option value="type_dette_moyen_ter">Plutôt Stable</option>
                                                            <option value="type_dette_moyen_term">Plutôt Instable</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 12 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "Quel type d'investissement préférez-vous ?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={typeInvestissement} onChange={handleTypeInvestissement}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>Type d'investissement</option>
                                                            <option value="type_fond_propre">Fond propre</option>
                                                            <option value="type_dette_court_te">Dette</option>
                                                            <option value="type_dette_moyen_ter">Fonds communs de placement</option>
                                                            <option value="type_dette_moyen_term">Actifs financiers</option>
                                                            <option value="type_dette_moyen_ter">Start-ups</option>
                                                            <option value="type_invest_autre">Autres (avec champ de texte pour spécifier)</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                            {typeInvestissement === 'type_invest_autre' &&
                                                <>
                                                    <div class="col-md-12">
                                                        <div class="select-field">
                                                            <input type="text" placeholder="Si autre Précisez" value={typeInvestissementAutre} onChange={e => setTypeInvestissementAutre(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="next-prev-btn">
                                                        {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                                                        <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 13 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "Quelles sont vos préférences sectorielles pour vos investissements? ",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={preferenceSectoriel} onChange={handlePreferenceSectoriel}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>préférences sectorielles pour vos investissements</option>
                                                            <option value="type_fond_propre">Agroalimentaire</option>
                                                            <option value="type_dette_court_te">Automobile</option>
                                                            <option value="type_dette_moyen_ter">Banque / Assurance</option>
                                                            <option value="type_dette_moyen_term">Bois / Papier / Carton / Imprimerie</option>
                                                            <option value="type_dette_moyen_ter">BTP / Matériaux de construction</option>
                                                            <option value="type_dette_moyen_te">Chimie / Parachimie</option>
                                                            <option value="type_dette_moyen_t"> Commerce / Négoce / Distribution</option>
                                                            <option value="type_dette_moyen_"> Édition / Communication / Multimédia</option>
                                                            <option value="type_dette_moyen">Électronique / Energie</option>
                                                            <option value="type_dette_moye">Études et conseils</option>
                                                            <option value="type_dette_moy">Tourisme</option>
                                                            <option value="type_dette_mo">Industrie pharmaceutique</option>
                                                            <option value="type_dette_moyen_teoijip">Informatique / Télécoms</option>
                                                            <option value="type_dette_moyen_teacazce">Machines et équipements</option>
                                                            <option value="type_dette_moyen_dzdaqc">Métallurgie / Travail du métal</option>
                                                            <option value="type_dette_moyenczeczc">Plastique / Caoutchouc</option>
                                                            <option value="type_dette_moyen_zeczecfe">Services aux entreprises</option>
                                                            <option value="type_dette_moyenzevezv">Textile / Habillement / Chaussure</option>
                                                            <option value="type_dette_moyen_zvzvezvzr">Transports / Logistique</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 14 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "Avez-vous déjà investi dans des projets similaires ?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">
                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={projetSimilaire} onChange={handleProjetSimilaire}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>préférences sectorielles pour vos investissements</option>
                                                            <option value="type_fond_propre">0</option>
                                                            <option value="type_dette_court_te">1</option>
                                                            <option value="type_dette_moyen_ter">2</option>
                                                            <option value="type_dette_moyen_term">3</option>
                                                            <option value="type_dette_moyen_ter">4</option>
                                                            <option value="type_dette_moyen_te">5</option>
                                                            <option value="type_dette_moyen_t">Plus de 5</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 15 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "Qu'attendez-vous de vos investissements ?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">
                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={attenteProjet} onChange={handleAttenteProjet}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>attente de vos investissements</option>
                                                            <option value="type_fond_propre">La croissance la plus rapide possible ; les revenus courants ne sont pas importants.</option>
                                                            <option value="type_dette_court_te">Une croissance plus forte que l'inflation</option>
                                                            <option value="type_dette_moyen_ter">Une croissance faible avec de bons dividendes et intérêts.</option>
                                                            <option value="type_dette_moyen_term">Une garantie du montant principal investi, quoiqu'il arrive</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 16 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "A quel pourcentage de vos actifs totaux prévoyez-vous investir en bourse ?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">
                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={pourcentageEnBourse} onChange={handlePourcentageEnBourse}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>prévoyez-vous investir en bourse</option>
                                                            <option value="type_fond_propre">Moins de 25%</option>
                                                            <option value="type_dette_court_te">Entre 25% et 50%</option>
                                                            <option value="type_dette_moyen_ter">Entre 50% et 75%</option>
                                                            <option value="type_dette_moyen_term">Plus de 75%</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 17 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "En général, combien de temps vous a-t-il fallu pour obtenir un retour sur investissement ?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">
                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={retourSurInvestissement} onChange={handleRetourSurInvestissement}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>prévoyez-vous investir en bourse</option>
                                                            <option value="type_fond_propre">Moins de 6 mois</option>
                                                            <option value="type_dette_court_te">6 à 12 mois</option>
                                                            <option value="type_dette_moyen_ter">1 à 2 ans</option>
                                                            <option value="type_dette_moyen_term">Plus de 2 ans</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 18 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "Horizon d'investissement",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">
                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={horizonInvestissement} onChange={handleHorizonInvestissement}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>Horizon d'investissement</option>
                                                            <option value="type_fond_propre">Placement très court terme (inférieur à 1 an)</option>
                                                            <option value="type_dette_court_te">Placement court terme (inférieur à 3 ans)</option>
                                                            <option value="type_dette_moyen_ter">Placement moyen terme (inférieur à 5 ans)</option>
                                                            <option value="type_dette_moyen_term">Placement long terme (supérieur à 5 ans)</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 19 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "Quel niveau de rendement attendez vous de votre placement?",
                                                1000,
                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">
                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={niveauRendement} onChange={handleNiveauDeRendement}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>Niveau de rendement</option>
                                                            <option value="type_fond_propre">Moins de 8%</option>
                                                            <option value="type_dette_court_te">Entre 8 et 10%</option>
                                                            <option value="type_dette_moyen_ter">Entre 10% et 15%</option>
                                                            <option value="type_dette_moyen_term">Plus de 15%</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 20 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "Avez-vous une épargne pour faire face aux urgences?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">
                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={epargne} onChange={handleEpargne}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>Niveau de rendement</option>
                                                            <option value="type_fond_propre">Oui</option>
                                                            <option value="type_dette_court_te">Non</option>
                                                            <option value="type_dette_moyen_ter">Oui, mais importante que je voudrais</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 21 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "Quand commencerez-vous à utiliser les sommes investies ? ",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">
                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={sommeInvestit} onChange={handleSommeInvesti}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>Quand commencerez-vous à utiliser les sommes investies ? </option>
                                                            <option value="type_fond_propre">Dans moins de 3 ans</option>
                                                            <option value="type_dette_court_te">D'ici 3 à 10 ans</option>
                                                            <option value="type_dette_moyen_ter">Dans plus de 10 ans</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 22 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "Quels sont vos objectifs d'investissement principaux ?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">
                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={objectifInvest} onChange={handleObjectifInvest}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>Quand commencerez-vous à utiliser les sommes investies ? </option>
                                                            <option value="type_fond_propre">Croissance du capital</option>
                                                            <option value="type_dette_court_te">Revenus réguliers</option>
                                                            <option value="type_dette_moyen_tr">Diversification du portefeuille</option>
                                                            <option value="type_dette_moyen_">Investissement éthique</option>
                                                            <option value="type_dette_moye">Autre</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 23 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "Quelles variations pouvez-vous accepter concernant l’investissement que vous souhaitez réaliser",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">
                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={variationsInvestissement} onChange={handleVariationInvestissement}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>variations</option>
                                                            <option value="type_fond_propre">Portefeuille A (la performance peut être négative en termes réel si l’inflation est supérieure à la performance enregistrée)</option>
                                                            <option value="type_dette_court_te">Portefeuille B</option>
                                                            <option value="type_dette_moyen_tr">Portefeuille C</option>
                                                            <option value="type_dette_moyen_">Portefeuille D</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 24 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        <TypeAnimation
                                            sequence={[
                                                "A quel rendement/risque pensez vous être à l'aise?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />

                                    </div>
                                </article>
                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">
                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select name="select-service" id="selectservice" value={rendementRisque} onChange={handleRendementRisque}>
                                                        <optgroup label='Selectionnez'>
                                                            <option hidden>variations</option>
                                                            <option value="type_fond_propre">Rendement faible, risque faible</option>
                                                            <option value="type_dette_court_te">Rendement modéré, risque modéré</option>
                                                            <option value="type_dette_moyen_tr">Rendement élevé, risque élevé</option>
                                                            <option value="type_dette_moyen_">Rendement très élevé, risque très élevé</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </section>
            )}
            {step === 25 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "Charger les documents",
                                                1000,
                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className='mb-5'>
                                    <UploadComponent onFileUpload={handleFileUpload} />
                                </div>
                                {/* <h2>Uploaded Files</h2>
                                <ul>
                                    {files.map((file, index) => (
                                        <li key={index}>{file.name}</li>
                                    ))}
                                </ul> */}
                                <h2>Liste des fichiers à charger</h2>
                                <div className='row mb-3'>
                                    <div className='col-md-4'>
                                        <p> - Pièce d'identité recto verso en cours de validité (carte nationale d’identité / carte de séjour / carte de résident / 4 premières pages du passeport). </p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p> - Deux (2) photos d'identité de même tirage 3.</p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p> - Une facture d'eau ou d'électricité (portant le nom du client) ou à défaut un certificat de résidence</p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p> - Le relevé d’identité bancaire (RIB)</p>
                                    </div>
                                </div>
                                {/* <!-- next previous button --> */}
                                <div className="next-prev-btn">
                                    {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Soumettre</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </div>
    )
}