import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';

import { toast, Slide } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faArrowLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';

import { ProgressBar } from '../../../components/signup/progressbar/progressbar';
import Sibebar from '../../../components/signup/sidebar/sibebar';
import UploadComponent from '../../../components/signup/upload/upload';

import { validateEmail, isNotEmptyStringWithoutSpaces } from '../../../helpers/validate';


export default function InvestisseurInstitutionnel() {



    const history = useHistory();

    const [step, setStep] = useState(1);
    const totalSteps = 21;
    const progress = (step / totalSteps) * 100;

    const categories = ['Placements investis dans l’immobilier locatif', 'Actions d’entreprises non cotées', 'Comptes titres (Actions cotées )', 'FCP', 'Obligations', 'DAT', 'Aucun']


    const [domaineActivite, setDomaineActivite] = useState("");
    const [socialReason, setSocialReason] = useState("");
    const [socialCapital, setSocialCapital] = useState("");
    const [juridique, setJuridique] = useState("");
    const [moralAdress, setMoralAdress] = useState("");
    const [moralContact, setMoralContact] = useState("");
    const [representant, setRepresentant] = useState("");
    const [selectedCategory, setSelectedCategory] = useState([]);

    const [representantEntreprise, setRepresentantEntreprise] = useState("");
    const [fonctionEntreprise, setFonctionEntreprise] = useState("");
    const [mandat, setMandat] = useState("");
    const [critereDePlacement, setCritereDePlacement] = useState("");
    const [modeDeGestion, setModeDeGestion] = useState("");
    const [preferenceSectorielle, setPreferenceSectorielle] = useState("");
    const [classeActifs, setClasseActifs] = useState("");
    const [listExclustion, setListExclustion] = useState("");
    const [listExclustionDetail, setListExclustionDetail] = useState("");
    const [plafondInvestissement, setPlafondInvestissement] = useState("");
    const [plafondInvestissementDetail, setPlafondInvestissementDetail] = useState("");
    const [tailleActif, setTailleActif] = useState("");
    const [stadeInvestissement, setStadeInvestissement] = useState("");
    const [objectifInvestissement, setObjectifInvestissement] = useState("");
    const [allocationActif, setAllocationActif] = useState("");
    const [horizonInvestissement, setHorizonInvestissement] = useState("");
    const [pourcentageActif, setPourcentageActif] = useState("");
    const [niveauRendement, setNiveauRendement] = useState("");
    const [strategieInstitution, setStrategieInstitution] = useState("");
    const [rendementRisque, setRendementRisque] = useState("");
    const [niveauDePerte, setNiveauDePerte] = useState("");


    const [files, setFiles] = useState([]);

    const handleFileUpload = (uploadedFiles) => {
        setFiles(uploadedFiles);
    };


    const sendToasError = (message, time) => {
        toast.error(message, {
            position: "top-right",
            autoClose: time,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Slide,
        });
    }

    const nextStep = () => {
        if (step === 1) {
            if (
                !isNotEmptyStringWithoutSpaces(domaineActivite) ||
                !isNotEmptyStringWithoutSpaces(socialReason) ||
                !isNotEmptyStringWithoutSpaces(socialCapital) ||
                !isNotEmptyStringWithoutSpaces(juridique) ||
                !isNotEmptyStringWithoutSpaces(moralAdress) ||
                !isNotEmptyStringWithoutSpaces(moralContact) ||
                !isNotEmptyStringWithoutSpaces(representant)
            ) {
                return sendToasError("Veuillez remplir les champs*", 3500)
            }
            setStep(step + 1)

        }
        else if (step === 2) {
            if (!isNotEmptyStringWithoutSpaces(representantEntreprise) || !isNotEmptyStringWithoutSpaces(fonctionEntreprise)) {
                return sendToasError("Veuillez remplir les champs*", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 3) {
            if (!isNotEmptyStringWithoutSpaces(mandat)) {
                return sendToasError("Veuillez remplir les champs*", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 4) {
            if (selectedCategory.length === 0) {
                return sendToasError("Veuillez faire une selection*", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 5) {
            if (!isNotEmptyStringWithoutSpaces(critereDePlacement)) {
                return sendToasError("Veuillez faire une selection*", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 6) {
            if (!isNotEmptyStringWithoutSpaces(modeDeGestion)) {
                return sendToasError("Veuillez faire une selection*", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 7) {
            if (!isNotEmptyStringWithoutSpaces(preferenceSectorielle)) {
                return sendToasError("Veuillez faire une selection*", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 8) {
            if (!isNotEmptyStringWithoutSpaces(classeActifs)) {
                return sendToasError("Veuillez faire une selection*", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 9) {
            if (isNotEmptyStringWithoutSpaces(listExclustion)) {
                if (!isNotEmptyStringWithoutSpaces(listExclustionDetail)) {
                    return sendToasError("Veuillez remplir le champ*", 3500)
                }
                setStep(step + 1)
            } else {
                sendToasError("Veuillez faire une selection*", 3500)
            }
            // setStep(step + 1)
        }
        else if (step === 10) {
            if (isNotEmptyStringWithoutSpaces(plafondInvestissement)) {
                if (plafondInvestissement === 'plafond_invest_oui') {
                    if (!isNotEmptyStringWithoutSpaces(plafondInvestissementDetail)) {
                        return sendToasError("Veuillez remplir le champ*", 3500)
                    }
                    setStep(step + 1)
                } else {
                    setStep(step + 1)
                }
            } else {
                sendToasError("Veuillez faire une selection*", 3500)
            }
            // setStep(step + 1)
        }
        else if (step === 11) {
            if (!isNotEmptyStringWithoutSpaces(tailleActif)) {
                return sendToasError("Veuillez faire une selection*", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 12) {
            if (!isNotEmptyStringWithoutSpaces(stadeInvestissement)) {
                return sendToasError("Veuillez faire une selection*", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 13) {
            if (!isNotEmptyStringWithoutSpaces(objectifInvestissement)) {
                return sendToasError("Veuillez faire une selection*", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 14) {
            if (!isNotEmptyStringWithoutSpaces(allocationActif)) {
                return sendToasError("Veuillez faire une selection*", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 15) {
            if (!isNotEmptyStringWithoutSpaces(horizonInvestissement)) {
                return sendToasError("Veuillez faire une selection*", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 16) {
            if (!isNotEmptyStringWithoutSpaces(pourcentageActif)) {
                return sendToasError("Veuillez faire une selection*", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 17) {
            if (!isNotEmptyStringWithoutSpaces(niveauRendement)) {
                return sendToasError("Veuillez faire une selection*", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 18) {
            if (!isNotEmptyStringWithoutSpaces(strategieInstitution)) {
                return sendToasError("Veuillez faire une selection*", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 19) {
            if (!isNotEmptyStringWithoutSpaces(rendementRisque)) {
                return sendToasError("Veuillez faire une selection*", 3500)
            }
            setStep(step + 1)
        }
        else if (step === 20) {
            if (!isNotEmptyStringWithoutSpaces(niveauDePerte)) {
                return sendToasError("Veuillez faire une selection*", 3500)
            }
            setStep(step + 1)
        }
    }

    const prevStep = () => {
        if (step > 1) {
            setStep(step - 1);
        } else {
            history.push('/signup')
        }
    };

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedCategory([...selectedCategory, value]);
        } else {
            setSelectedCategory(selectedCategory.filter((category) => category !== value));
        }
    };


    const handleMandat = (e) => {
        setMandat(e.target.value)
        setStep(step + 1)

    }

    const handleListExclusion = (e) => {
        setListExclustion(e.target.value)
        if (e.target.value === 'liste_exclusion_non') {
            setStep(step + 1)
        }
    }

    const handleCritereDePlacement = (e) => {
        setCritereDePlacement(e.target.value)
        setStep(step + 1)
    }

    const handleModeDeGestion = (e) => {
        setModeDeGestion(e.target.value)
        setStep(step + 1)
    }
    const handlePreferenceSectorielle = (e) => {
        setPreferenceSectorielle(e.target.value)
        setStep(step + 1)
    }
    const handleClasseActif = (e) => {
        setClasseActifs(e.target.value)
        setStep(step + 1)
    }

    const handlePlafondInvestissement = (e) => {
        setPlafondInvestissement(e.target.value)
        if (e.target.value === 'plafond_invest_non') {
            setStep(step + 1)
        }
    }
    const handleTailleActif = (e) => {
        setTailleActif(e.target.value)
        setStep(step + 1)
    }

    const handleStadeInvestissement = (e) => {
        setStadeInvestissement(e.target.value)
        setStep(step + 1)
    }

    const handleObjectifInvestissement = (e) => {
        setObjectifInvestissement(e.target.value)
        setStep(step + 1)
    }
    const handleAllocationActif = (e) => {
        setAllocationActif(e.target.value)
        setStep(step + 1)
    }
    const handleHorizonInvestissement = (e) => {
        setHorizonInvestissement(e.target.value)
        setStep(step + 1)
    }
    const handlePourcentageActif = e => {
        setPourcentageActif(e.target.value)
        setStep(step + 1)
    }
    const handleNiveauRendement = e => {
        setNiveauRendement(e.target.value)
        setStep(step + 1)
    }
    const handleStrategieInstitution = e => {
        setStrategieInstitution(e.target.value)
        setStep(step + 1)
    }
    const handleRendementRisque = e => {
        setRendementRisque(e.target.value)
        setStep(step + 1)
    }
    const handleNiveauDePerte = e => {
        console.log('on handle Niveau de perte')
        setNiveauDePerte(e.target.value)
        setStep(step + 1)
    }

    return (
        <div style={{ overflow: 'clip' }}>
            {step === 1 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "Presentez votre structure s'il vous plait !",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select id="selectservice" value={domaineActivite} onChange={e => setDomaineActivite(e.target.value)}>
                                                        <optgroup label='selectionnez la civilité'>
                                                            <option hidden>Domaine d'activite</option>
                                                            <option value="m">Domaine activite 1 </option>
                                                            <option value="mme">Domaine activite 2</option>
                                                            <option value="mlle">Domaine activite 3</option>
                                                        </optgroup>
                                                    </select>
                                                    {/* <span></span> */}
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="select-field">
                                                    <input type="text" placeholder="Raison Sociale" value={socialReason} onChange={(e) => setSocialReason(e.target.value)} />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="select-field">
                                                    <input type="text" placeholder="Capital Social" value={socialCapital} onChange={(e) => setSocialCapital(e.target.value)} />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="select-field">
                                                    <input type="text" placeholder="Forme juridique" value={juridique} onChange={e => setJuridique(e.target.value)} />
                                                </div>
                                            </div>

                                        </div>
                                        <div className='row'>
                                            <div class="col-md-4">
                                                <div class="select-field">
                                                    <input type="text" placeholder="Adresse" value={moralAdress} onChange={e => setMoralAdress(e.target.value)} />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="select-field">
                                                    <input type="text" placeholder="Contact" value={moralContact} onChange={(e) => setMoralContact(e.target.value.replace(/\D/g, ''))} />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="select-field">
                                                    <input type="text" placeholder="Nom du représentant légal" value={representant} onChange={(e) => setRepresentant(e.target.value)} />
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                <div className="next-prev-btn">
                                    {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div>
                            </div>
                        </div>


                    </div>

                </section>
            )}
            {step === 2 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "Représentant de l’entreprise",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <input type="text" placeholder="Entreprise representée par" value={representantEntreprise} onChange={e => setRepresentantEntreprise(e.target.value)} />
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <input type="text" placeholder="Fonction" value={fonctionEntreprise} onChange={e => setFonctionEntreprise(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                <div className="next-prev-btn">
                                    {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div>
                            </div>
                        </div>


                    </div>

                </section>
            )}
            {step === 3 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "Disposez vous d'un mandat pour engager l'entreprise ?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                        <FontAwesomeIcon data-tooltip-id="my-tooltip-inline" data-tooltip-content="Le mandat autorise votre entreprise à agir en votre nom pour prendre des décisions spécifiques" icon={faInfoCircle} style={{ marginLeft: 15, fontSize: 30 }} />
                                        <Tooltip
                                            id="my-tooltip-inline"
                                            style={{ backgroundColor: "#8e736a", color: "#fff" }}
                                        />

                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select id="selectservice" value={mandat} onChange={handleMandat}>
                                                        <optgroup label='selectionnez'>
                                                            <option hidden>mandat pour engager l'entreprise</option>
                                                            <option value="mandat_oui">Oui</option>
                                                            <option value="mandat_non">Non</option>
                                                        </optgroup>
                                                    </select>
                                                    {/* <span></span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>


                    </div>

                </section>
            )}
            {step === 4 && (
                <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
                    {/* <!-- step-1 --> */}
                    <section class="steps row">
                        {/* <!-- step-1-sidebar --> */}
                        <Sibebar />
                        <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
                            <ProgressBar progress={progress} />
                            <div className="step-inner container">
                                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                    <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                    <p className='ms-2'>Retour</p>
                                </div>
                                <div className="p-5">
                                    <article>
                                        <div className="step-heading text-center mb-5">
                                            <div className="heading-marker"></div>
                                            <TypeAnimation
                                                sequence={[
                                                    "Quelles sont les étapes déja realisées ? ",
                                                    1000,

                                                    // () => {
                                                    //   console.log('Sequence completed');
                                                    // },
                                                ]}
                                                wrapper="h3"
                                                cursor={false}
                                                className='heading text-center'
                                            // style={{ fontSize: '2em', display: 'inline-block' }}
                                            />

                                        </div>
                                    </article>

                                    {/* <!-- step-2-form --> */}
                                    <div className="pop-right step-form mx-auto" id="step3">

                                        <div className='form-part'>
                                            <div className='row mb-3'>
                                                <div class="col-md-12">
                                                    <div class="row justify-content-center">
                                                        {categories.map((category) => {
                                                            return <div class="category">
                                                                <div class="category-inner">
                                                                    <input class="gradiant_border" type="checkbox" name="category" value={category} checked={selectedCategory.includes(category)} onChange={handleCheckboxChange} />
                                                                    <label>{category}</label>
                                                                </div>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- next previous button --> */}
                                    <div className="next-prev-btn">
                                        {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                                        <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </form>
            )}
            {step === 5 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "Le critère d’un placement responsable / durable est-il important pour vous au moment d’effectuer vos placements?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select id="selectservice" value={critereDePlacement} onChange={handleCritereDePlacement}>
                                                        <optgroup label='selectionnez'>
                                                            <option hidden>Le critère d’un placement responsable </option>
                                                            <option value="critere_oui">Oui</option>
                                                            <option value="critere_non">Non</option>
                                                        </optgroup>
                                                    </select>
                                                    {/* <span></span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>


                    </div>

                </section>
            )}
            {step === 6 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "A quels modes de gestion avez-vous eu recours?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select id="selectservice" value={modeDeGestion} onChange={handleModeDeGestion}>
                                                        <optgroup label='selectionnez'>
                                                            <option hidden>Le critère d’un placement responsable </option>
                                                            <option value="m">Gestion directe. Vous vous occupez vous-même de votre gestion.</option>
                                                            <option value="mme">Gestion conseillée. Vous êtes conseillé par votre conseiller financier pour effectuer vos choix de gestion.</option>
                                                            <option value="mmee">Gestion sous mandat. Votre gestion est déléguée à un organisme de gestion</option>
                                                        </optgroup>
                                                    </select>
                                                    {/* <span></span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>


                    </div>

                </section>
            )}
            {step === 7 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "Quelles sont vos préférences sectorielles pour vos investissements? ",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select id="selectservice" value={preferenceSectorielle} onChange={handlePreferenceSectorielle}>
                                                        <optgroup label='selectionnez'>
                                                            <option hidden>Le critère d’un placement responsable </option>
                                                            <option value="m">Actions</option>
                                                            <option value="mme">Obligations</option>
                                                            <option value="mmee">Dette senior</option>
                                                            <option value="mmee">Immobilier</option>
                                                        </optgroup>
                                                    </select>
                                                    {/* <span></span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {step === 8 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "Quelle sont les classes d'actifs qui  vous interessent ?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select id="selectservice" value={classeActifs} onChange={handleClasseActif}>
                                                        <optgroup label='selectionnez'>
                                                            <option hidden> classes d'actifs</option>
                                                            <option value="m">Immobilier</option>
                                                            <option value="mme">Infrastructure</option>
                                                            <option value="mmee">Industrie</option>
                                                            <option value="mmeee">Distribution</option>
                                                            <option value="mmeec">Technologie</option>
                                                            <option value="mmezezee">Hôtellerie</option>
                                                            <option value="mmeeze">Tourisme</option>
                                                            <option value="mmeezce">Start-up</option>
                                                            <option value="mmeegh">Institution financière</option>
                                                            <option value="mmeeger">Actifs financier</option>
                                                            <option value="mmeeegz">Autre</option>
                                                        </optgroup>
                                                    </select>
                                                    {/* <span></span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {step === 9 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "Avez-vous une liste d’exclusions d'investissements?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select id="selectservice" value={listExclustion} onChange={handleListExclusion}>
                                                        <optgroup label='selectionnez'>
                                                            <option hidden>Exclusions d'investissements</option>
                                                            <option value="liste_exclusion_oui">Oui</option>
                                                            <option value="liste_exclusion_non">Non</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </div>
                                            {listExclustion === 'liste_exclusion_oui' &&
                                                <>
                                                    <div className='col-md-12'>
                                                        <h3 className='text-center'>Donnez plus de détails</h3>
                                                        <textarea name="textarea" id="textarea" value={listExclustionDetail} onChange={e => setListExclustionDetail(e.target.value)} ></textarea>
                                                    </div>
                                                    <div className="next-prev-btn">
                                                        {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                                                        <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                                    </div>
                                                </>

                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}

                            </div>
                        </div>
                    </div>
                </section>
            )}
            {step === 10 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "Avez un plafond d'investissement hors marché financier de vos actifs?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select id="selectservice" value={plafondInvestissement} onChange={handlePlafondInvestissement}>
                                                        <optgroup label='selectionnez'>
                                                            <option hidden>plafond d'investissement hors marché financier</option>
                                                            <option value="plafond_invest_oui">Oui</option>
                                                            <option value="plafond_invest_non">Non</option>
                                                        </optgroup>
                                                    </select>
                                                    {/* <span></span> */}
                                                </div>
                                            </div>
                                            {plafondInvestissement === 'plafond_invest_oui' &&
                                                <>
                                                    <div className='col-md-12'>
                                                        <h3 className='text-center'>Donnez plus de détails</h3>
                                                        <textarea name="textarea" id="textarea" value={plafondInvestissementDetail} onChange={e => setPlafondInvestissementDetail(e.target.value)} ></textarea>
                                                    </div>
                                                    <div className="next-prev-btn">
                                                        {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                                                        <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                                    </div>
                                                </>

                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </section>
            )}
            {step === 11 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "Quelle est la taille de vos actifs ?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select id="selectservice" value={tailleActif} onChange={handleTailleActif}>
                                                        <optgroup label='selectionnez'>
                                                            <option hidden>taille de vos actifs</option>
                                                            <option value="m">Entre 10 et 50 milliards</option>
                                                            <option value="mmex">Entre 50 et 100 milliards</option>
                                                            <option value="mmey">Entre 100 milliards et 200 milliards</option>
                                                            <option value="mmed">lus de 200 milliards</option>
                                                        </optgroup>
                                                    </select>
                                                    {/* <span></span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {step === 12 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "A quel stade souhaiteriez vous investir ?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select id="selectservice" value={stadeInvestissement} onChange={handleStadeInvestissement}>
                                                        <optgroup label='selectionnez'>
                                                            <option hidden>Stade d'investissement</option>
                                                            <option value="m">Capital Amorçage (Investit dans des entreprises qui en sont à leur démarrage avec juste une idée ou un prototype sans génération de flux)</option>
                                                            <option value="mmex">Capital Risque (Finance des structures ayant donné de premiers signes d’adéquation du produit par rapport au marché en démontrant la progression d’un produit spécifique et des revenus)</option>
                                                            <option value="mmey">Capital Développement (Apporte des fonds aux entreprises matures souvent sous-évaluées et qui connaissent des difficultés ou qui souhaitent se développer)</option>
                                                            <option value="mmed"> Capital Transmission (Fournit des capitaux et de la dette aux entités à maturité souhaitant réaliser des opérations de fusions acquisitions ou s’expandre sur d’autres marchés)</option>
                                                            <option value="mmed"> Marché des capitaux</option>
                                                        </optgroup>
                                                    </select>
                                                    {/* <span></span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {step === 13 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "Objectifs d'investissement",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select id="selectservice" value={objectifInvestissement} onChange={handleObjectifInvestissement}>
                                                        <optgroup label='selectionnez'>
                                                            <option hidden>Objectifs investissement</option>
                                                            <option value="m">Préservation du capital (Stratégie d'investissement prudente dont l'objectif principal est de préserver le capital et d'éviter les pertes au sein d'un portefeuille.</option>
                                                            <option value="mmex">Cette stratégie ne permet pas d'investir sur le marché action</option>
                                                            <option value="mmey">Croissance du capital (Stratégie d'investissement dont l'objectif principal est d'augmenter le capital avec en contrepartie un risque de perte plus élevé.</option>
                                                            <option value="mmed">Cette stratégie permet de s'exposer plus ou moins sur le marché des actions)</option>
                                                            <option value="mmed">Hedging (couverture de risque)</option>
                                                            <option value="mmed">-Exposition à effet de levier (Stratégie d'investissement qui vous permet, contre couverture, de prendre plus de positions sur les marchés que votre investissement réel.</option>
                                                            <option value="mmed">Les gains sont potentiellement élevés mais en contrepartie vous risquez de perdre plus que la somme réellement investie)</option>
                                                        </optgroup>
                                                    </select>
                                                    {/* <span></span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {step === 14 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "Quel allocation d'actifs vous conviendrait le mieux ? ",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select id="selectservice" value={allocationActif} onChange={handleAllocationActif}>
                                                        <optgroup label='selectionnez'>
                                                            <option hidden>Allocation d'actifs</option>
                                                            <option value="m">70% obligations , 20% d'actions et 10% de liquidités</option>
                                                            <option value="mmex">50% obligations, 30% d'actions et 20% de liquidités</option>
                                                            <option value="mmey">40% obligations , 50% dactions et 10% de liquidités</option>
                                                            <option value="mmed">autres ( A renseigner)</option>

                                                        </optgroup>
                                                    </select>
                                                    {/* <span></span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {step === 15 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "Horizon d'investissement",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select id="selectservice" value={horizonInvestissement} onChange={handleHorizonInvestissement}>
                                                        <optgroup label='selectionnez'>
                                                            <option hidden>Allocation d'actifs</option>
                                                            <option value="m">Placement très court terme (inférieur à 1 an)</option>
                                                            <option value="mmex">Placement court terme (inférieur à 3 ans)</option>
                                                            <option value="mmey">Placement moyen terme (inférieur à 5 ans)</option>
                                                            <option value="mmed">Placement long terme (supérieur à 5 ans)</option>
                                                        </optgroup>
                                                    </select>
                                                    {/* <span></span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {step === 16 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "A quel pourcentage de vos actifs totaux prévoyez-vous investir ?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select id="selectservice" value={pourcentageActif} onChange={handlePourcentageActif}>
                                                        <optgroup label='selectionnez'>
                                                            <option hidden>Pourcentage de vos actifs totaux</option>
                                                            <option value="m">Moins de 25%</option>
                                                            <option value="mmex">Entre 25% et 50%</option>
                                                            <option value="mmey">Entre 50% et 75%</option>
                                                            <option value="mmed">Plus de 75%</option>
                                                        </optgroup>
                                                    </select>
                                                    {/* <span></span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {step === 17 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "Quel niveau de rendement attendez vous de votre placement?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select id="selectservice" value={niveauRendement} onChange={handleNiveauRendement}>
                                                        <optgroup label='selectionnez'>
                                                            <option hidden>Pourcentage de vos actifs totaux</option>
                                                            <option value="m">Moins de 8%</option>
                                                            <option value="mmex">Entre 8 et 10%</option>
                                                            <option value="mmey">Entre 10% et 15%</option>
                                                            <option value="mmed">Plus de 15%</option>
                                                        </optgroup>
                                                    </select>
                                                    {/* <span></span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {step === 18 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "En matière de placement de trésorerie, quel est la stratégie de votre institution ?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select id="selectservice" value={strategieInstitution} onChange={handleStrategieInstitution}>
                                                        <optgroup label='selectionnez'>
                                                            <option hidden>stratégie de votre institution</option>
                                                            <option value="m">Il ne faut pas prendre de risque</option>
                                                            <option value="mmex">On peut placer une petite partie de sa trésorerie sur des placements risqués</option>
                                                            <option value="mmey">On peut placer une part importante de sa trésorerie sur des actifs risqués si le gain en vaut la peine</option>
                                                            <option value="mmed">On doit placer l'essentiel de sa trésorerie dans des actifs risqués dès qu'il y a des chances de gains très importants</option>
                                                        </optgroup>
                                                    </select>
                                                    {/* <span></span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {step === 19 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "A quel rendement/risque pensez vous être à l'aise?",
                                                1000,

                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select id="selectservice" value={rendementRisque} onChange={handleRendementRisque}>
                                                        <optgroup label='selectionnez'>
                                                            <option hidden>stratégie de votre institution</option>
                                                            <option value="m">Placement A</option>
                                                            <option value="mmex">Placement B </option>
                                                            <option value="mmey">Placement C </option>
                                                        </optgroup>
                                                    </select>
                                                    {/* <span></span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {step === 20 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "Quel Niveau de perte peut supporter votre institution?",
                                                1000,
                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className="pop-right step-form mx-auto" id="step3">

                                    <div className='form-part'>
                                        <div className='row mb-3'>
                                            <div class="col-md-12">
                                                <div class="select-field">
                                                    <select id="selectservice" value={niveauDePerte} onChange={handleNiveauDePerte}>
                                                        <optgroup label='selectionnez'>
                                                            <option hidden>Quel Niveau de perte peut supporter votre institution?</option>
                                                            <option value="m">Aucune perte.</option>
                                                            <option value="mmex">Moins de 10%</option>
                                                            <option value="mmey">Entre 10% et 50%</option>
                                                        </optgroup>
                                                    </select>
                                                    {/* <span></span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- next previous button --> */}
                                {/* <div className="next-prev-btn">
                                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {step === 21 && (
                <section className="steps row">
                    {/* <!-- step-2-sidebar --> */}
                    <Sibebar />
                    <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                        <ProgressBar progress={progress} />
                        <div className="step-inner container">
                            <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                                <p className='ms-2'>Retour</p>
                            </div>
                            <div className="p-5">
                                <article>
                                    <div className="step-heading text-center mb-5">
                                        <div className="heading-marker"></div>
                                        {/* <h3 className="heading text-center" ref={step1} /> */}
                                        <TypeAnimation
                                            sequence={[
                                                "Charger les documents",
                                                1000,
                                                // () => {
                                                //   console.log('Sequence completed');
                                                // },
                                            ]}
                                            wrapper="h3"
                                            cursor={false}
                                            className='heading text-center'
                                        // style={{ fontSize: '2em', display: 'inline-block' }}
                                        />
                                    </div>
                                </article>

                                {/* <!-- step-2-form --> */}
                                <div className='mb-5'>
                                    <UploadComponent onFileUpload={handleFileUpload} />
                                </div>
                                {/* <h2>Uploaded Files</h2>
                                <ul>
                                    {files.map((file, index) => (
                                        <li key={index}>{file.name}</li>
                                    ))}
                                </ul> */}
                                <h2>Liste des fichiers à charger</h2>
                                <div className='row mb-3'>
                                    <div className='col-md-4'>
                                        <p> - Statuts de l’entreprise</p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p> - Registre de commerce</p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p> - Procès-verbaux d’assemblées générales de constitution</p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p> - Délibérations du conseil d’administration ou du directoire</p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p> - Délégation de pouvoirs</p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p> - Recueil de signatures autorisées, Pour le(s) représentant(s) de la personne morale : Tous documents justifiant de l’identité du (des) représentant(s) </p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p> - carte nationale d’identité (recto et du verso), • ou carte de séjour, • ou carte de résident, • ou passeport (4 premières pages). </p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p> - photos d’identité </p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p> - Le relevé d’identité bancaire (RIB) de la personne morale</p>
                                    </div>

                                </div>
                                {/* <!-- next previous button --> */}
                                <div className="next-prev-btn">
                                    {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Soumettre</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </div>
    )





}