import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';

import { toast, Slide } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faArrowLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';

//COMPONENTS
import { ProgressBar } from '../../../components/signup/progressbar/progressbar';
import Sibebar from '../../../components/signup/sidebar/sibebar';
import { Btp } from '../../../components/signup/porteurprojet/btp';
import { Industry } from '../../../components/signup/porteurprojet/industry'
import { Tech } from '../../../components/signup/porteurprojet/tech'
import { Tourisme } from '../../../components/signup/porteurprojet/tourisme'
import { FinancialInstitution } from '../../../components/signup/porteurprojet/finance';
import UploadComponent from '../../../components/signup/upload/upload';

import { validateEmail, isNotEmptyStringWithoutSpaces } from '../../../helpers/validate';

//IMAGES
import UserImage from '../../../assets/images/user-circle.png';
import CompanyImage from '../../../assets/images/company.jpg';
import Service1 from '../../../assets/images/service_icons/service-1.png'
import Service2 from '../../../assets/images/service_icons/service-2.png'
import Service3 from '../../../assets/images/service_icons/service-3.png'

export default function PorteurProjet() {


  const history = useHistory();
  const [step, setStep] = useState(1);
  const totalSteps = 22;
  const progress = (step / totalSteps) * 100;

  const [physic, setPhysic] = useState(false);
  const [moral, setMoral] = useState(false);
  const [civility, setCivility] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [socialReason, setSocialReason] = useState("");
  const [socialCapital, setSocialCapital] = useState("");
  const [juridique, setJuridique] = useState("");
  const [moralEmail, setMoralEmail] = useState("");
  const [moralAdress, setMoralAdress] = useState("");
  const [moralContact, setMoralContact] = useState("");
  const [representant, setRepresentant] = useState("");
  const [jobRepresentant, setJobRepresentant] = useState("");
  const [date, setDate] = useState('');

  const [etablie, setEtablie] = useState(false);
  const [creation, setCreation] = useState(false);
  const [chiffreAffaire1, setChiffreAffaire1] = useState("");
  const [chiffreAffaire2, setChiffreAffaire2] = useState("");
  const [chiffreAffaire3, setChiffreAffaire3] = useState("");

  const [needFund, setNeedFund] = useState(false);
  const [noNeedFund, setNoNeedFund] = useState(false);
  const [selectedNature, setSelectedNature] = useState("");

  const [projectTitle, setProjectTitle] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [projectProblem, setProjectProblem] = useState("");
  const [selectedTarget, setSelectedTarget] = useState("");
  const [selectedFinance, setSelectedFinance] = useState("");
  const [amountFinance, setAmountFinance] = useState("");

  const [btp, setBtp] = useState(false);
  const [industry, setIndustry] = useState(false);
  const [tech, setTech] = useState(false);
  const [tourism, setTourism] = useState(false);
  const [financialInstitution, setFinancialInstitution] = useState(false);

  const [partenaireTech, setPartenaireTech] = useState("");
  const [empruntEnCours, setEmpruntEnCours] = useState("");
  const [montantEmpruntEnCours, setMontantEmpruntEnCours] = useState("");
  const [financement, setFinancement] = useState("");
  const [montantFinancement, setMontantFinancement] = useState("");
  const [nomBailleur, setNomBailleur] = useState("");
  const [typeInvestissement, setTypeInvestissement] = useState("");
  const [calendrierDetailler, setCalendrierDetailler] = useState("")
  const [items, setItems] = useState([{ name: "" }]);

  const [btpData, setBtpData] = useState({});
  const handleBtpDataChange = (data) => {
    console.log('btp data=', data)
    setBtpData(data)
    // setBtpData((prevData) => ({ ...prevData, [selectedSector]: data }));
  };

  const [industryData, setIndustryData] = useState({});
  const handleIndustryDataChange = (data) => {
    console.log('industry data=', data)
    setIndustryData(data)
    // setBtpData((prevData) => ({ ...prevData, [selectedSector]: data }));
  };

  const [files, setFiles] = useState([]);

  const handleFileUpload = (uploadedFiles) => {
    setFiles(uploadedFiles);
  };


  const sendToasError = (message, time) => {
    toast.error(message, {
      position: "top-right",
      autoClose: time,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Slide,
    });
  }

  const nextStep = () => {
    if (step === 1) {
      if (moral || physic) {
        if (step < totalSteps) {
          console.log('current step', step)
          setStep(step + 1);
        }
      }

    } else if (step === 2) {
      if (moral) {
        if (!isNotEmptyStringWithoutSpaces(socialReason) ||
          !isNotEmptyStringWithoutSpaces(socialCapital) ||
          !isNotEmptyStringWithoutSpaces(juridique) ||
          !validateEmail(moralEmail) ||
          !isNotEmptyStringWithoutSpaces(moralAdress) ||
          !isNotEmptyStringWithoutSpaces(moralContact) ||
          !isNotEmptyStringWithoutSpaces(representant) ||
          !isNotEmptyStringWithoutSpaces(jobRepresentant) ||
          !isNotEmptyStringWithoutSpaces(date)) {
          return sendToasError("Veuillez remplir les champs*", 3500)
        }
        setStep(step + 1);

      }
      if (physic) {
        if (!isNotEmptyStringWithoutSpaces(civility) ||
          !isNotEmptyStringWithoutSpaces(username) ||
          !validateEmail(email) ||
          !isNotEmptyStringWithoutSpaces(phoneNumber)) {
          return sendToasError("Veuillez remplir les champs*", 3500)
        }
        setStep(step + 1);
      }

    }
    else if (step === 3) {
      console.log('step 3');
    } else if (step === 4) {
      if (!isNotEmptyStringWithoutSpaces(chiffreAffaire1) ||
        !isNotEmptyStringWithoutSpaces(chiffreAffaire2) ||
        !isNotEmptyStringWithoutSpaces(chiffreAffaire3)) {
        return sendToasError("Veuillez remplir les champs*", 3500)
      }
      setStep(step + 1);


    }
    else if (step === 5) {

    }
    else if (step === 6) {
      if (!isNotEmptyStringWithoutSpaces(selectedNature)) {
        return sendToasError("Veuillez remplir le champ*", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 7) {
      if (!isNotEmptyStringWithoutSpaces(projectTitle)) {
        return sendToasError("Veuillez remplir le champ*", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 8) {
      if (!isNotEmptyStringWithoutSpaces(projectDescription)) {
        return sendToasError("Veuillez remplir le champ*", 3500)
      }
      setStep(step + 1);

    }
    else if (step === 9) {
      if (!isNotEmptyStringWithoutSpaces(projectProblem)) {
        return sendToasError("Veuillez remplir le champ*", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 10) {
      if (!isNotEmptyStringWithoutSpaces(selectedTarget)) {
        return sendToasError("Veuillez selectionnez le champ*", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 11) {
      if (!isNotEmptyStringWithoutSpaces(selectedFinance)) {
        return sendToasError("Veuillez selectionnez le champ*", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 12) {
      if (!isNotEmptyStringWithoutSpaces(amountFinance)) {
        return sendToasError("Veuillez remplir le champ*", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 13) {
      if (btp || industry || tech || financialInstitution || tourism) {
        setStep(step + 1);
      } else {
        return sendToasError("Veuillez remplir le champ*", 3500)
      }
    } else if (step === 14) {
      setStep(step + 1);
    }
    else if (step === 15) {
      if (isNotEmptyStringWithoutSpaces(partenaireTech)) {
        if (partenaireTech === "partenaire_tech_non") {
          setStep(step + 1);
        }
        if (partenaireTech === 'partenaire_tech_oui') {
          if (!isNotEmptyStringWithoutSpaces(items[0].name)) {
            sendToasError("Veuillez saisir un champ*", 3500)
          }
          else {
            setStep(step + 1)
          }
        }
      } else {
        sendToasError("Veuillez saisir le champ*", 3500)
      }
      // setStep(step + 1);
    }
    else if (step === 16) {
      if (!isNotEmptyStringWithoutSpaces(financement)) {
        return sendToasError("Veuillez faire un choix", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 17) {
      if (!isNotEmptyStringWithoutSpaces(montantFinancement)) {
        return sendToasError("Veuillez saisir le champ", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 18) {
      if (!isNotEmptyStringWithoutSpaces(nomBailleur)) {
        return sendToasError("Saisir nom du bailleur")
      }
      setStep(step + 1);
    }
    else if (step === 19) {
      if (!isNotEmptyStringWithoutSpaces(typeInvestissement)) {
        return sendToasError("Veuillez faire une sélection", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 20) {
      if (isNotEmptyStringWithoutSpaces(empruntEnCours)) {
        if (empruntEnCours === 'emprunt_oui') {
          if (isNotEmptyStringWithoutSpaces(montantEmpruntEnCours)) {
            setStep(step + 1);
          }
          else {
            sendToasError("Saisir le montant");
          }
        }
        if (empruntEnCours === "emprunt_non") {
          setStep(step + 1)
        }
      } else {
        sendToasError("Veuillez faire une selection")
      }
      // setStep(step + 1);
    }
    else if (step === 21) {
      setStep(step + 1)
    }

  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      history.push('/signup')
    }
  };

  // const prevStepForActivitySector = () => {
  //   if(step > 1){

  //   }
  // }

  const nextStepForFund = () => {
    if (needFund || noNeedFund) {
      console.log('no Need Fund', noNeedFund)
      if (noNeedFund) {
        console.log('current step', step)
        setStep(step + 1);
      } else {
        setStep(step + 2);
      }
    }
  }

  const prevStepForFund = () => {
    console.log('current step', step);
    if (needFund) {
      setStep(step - 2);
    }
    else {
      setStep(step - 1);
    }
  }

  const nextStepForRccm = () => {
    if (etablie || creation) {
      if (etablie) {
        setStep(step + 1)
      } else {
        setStep(step + 4);
      }
    }
  }

  const nextStepForFinancement = () => {
    if (isNotEmptyStringWithoutSpaces(financement)) {
      if (financement === 'financement_non') {
        setStep(step + 4);
      }
      if (financement === 'financement_oui') {
        setStep(step + 1);
      }
    } else {
      sendToasError('Faites votre choix', 3500)
    }
  }

  const prevStepForRccm = () => {
    setStep(step - 4);
  }

  const prevStepForEmprunt = () => {
    if (financement === 'financement_non') {
      setStep(step - 4);
    }
    if (financement === 'financement_oui') {
      setStep(step - 1);
    }
  }

  const handlePhysic = () => {
    setPhysic(true);
    setMoral(false);
    setStep(step + 1)
  }

  const handleMoral = () => {
    setMoral(true)
    setPhysic(false);
    setStep(step + 1)
  }

  const handleEtablie = () => {
    setEtablie(true);
    setCreation(false);
    setStep(step + 1)
  }

  const handleCreation = () => {
    setCreation(true);
    setEtablie(false);
    setStep(step + 4)
  }

  const handleNeedFund = () => {
    setNeedFund(true);
    setNoNeedFund(false);
    setStep(step + 2);
  }

  const handleNoNeedFund = () => {
    setNoNeedFund(true);
    setNeedFund(false);
    setStep(step + 1);
  }

  const handleBtp = () => {
    setBtp(true);
    setIndustry(false);
    setTech(false);
    setTourism(false);
    setFinancialInstitution(false);
    setStep(step + 1)
  }
  const handleIndustry = () => {
    setIndustry(true);
    setBtp(false);
    setTech(false);
    setTourism(false);
    setFinancialInstitution(false);
    setStep(step + 1)
  }
  const handleTech = () => {
    setTech(true);
    setBtp(false);
    setIndustry(false);
    setTourism(false);
    setFinancialInstitution(false);
    setStep(step + 1)
  }
  const handleTourism = () => {
    setTourism(true);
    setBtp(false);
    setIndustry(false);
    setTech(false);
    setFinancialInstitution(false);
    setStep(step + 1)
  }
  const handleInstitutional = () => {
    setFinancialInstitution(true);
    setBtp(false);
    setIndustry(false);
    setTech(false);
    setTourism(false);
    setStep(step + 1)

  }

  const handleAddItem = () => {
    setItems([...items, { name: "" }]);
  };

  const handleDeleteItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const handleInputsChange = (index, event) => {
    const newItems = [...items];
    newItems[index].name = event.target.value;
    setItems(newItems);
  };

  const renderSector = () => {
    if (btp) {
      return <Btp onBack={prevStep} onNext={nextStep} onDataChange={handleBtpDataChange} />
    }
    else if (tech) {
      return <Tech onBack={prevStep} onNext={nextStep} />
    }
    else if (industry) {
      return <Industry onBack={prevStep} onNext={nextStep} onDataChange={handleIndustryDataChange} />
    }
    else if (tourism) {
      return <Tourisme onBack={prevStep} onNext={nextStep} />
    }
    else if (financialInstitution) {
      return <FinancialInstitution onBack={prevStep} onNext={nextStep} />
    } else {
      return;
    }

  }

  const handleNature = e => {
    setSelectedNature(e.target.value)
    setStep(step + 1)
  }
  const handleSelectedTarget = e => {
    setSelectedTarget(e.target.value)
    setStep(step + 1)
  }
  const handleSelectedFinance = e => {
    setSelectedFinance(e.target.value)
    setStep(step + 1)
  }
  const handlePartenaireTech = e => {
    setPartenaireTech(e.target.value)
    if (e.target.value === "partenaire_tech_non") {
      setStep(step + 1)
    }
  }
  const handleFinancement = e => {
    setFinancement(e.target.value)
    if (e.target.value === 'financement_non') {
      setStep(step + 4);
    }
    if (e.target.value === 'financement_oui') {
      setStep(step + 1);
    }
  }
  const handleTypeInvestissement = e => {
    setTypeInvestissement(e.target.value)
    setStep(step + 1)
  }
  const handleEmpruntEnCours = e => {
    setEmpruntEnCours(e.target.value)
    if (e.target.value === 'emprunt_non') {
      setStep(step + 1)
    }
  }
  const handleCalendrierDetailler = e => {
    console.log(physic, btp, btpData.btpSecteurActivite === 'btp_immobilier')
    console.log((physic, tourism))
    setCalendrierDetailler(e.target.value)
    setStep(step + 1)
  }

  return (
    <div style={{ overflow: 'clip' }}>
      {/* <ProgressBar progress={progress} /> */}
      {/* <!-- step-1 --> */}

      {step === 1 && (
        <section class="steps row">
          {/* <!-- step-1-sidebar --> */}
          <Sibebar />
          <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
            <ProgressBar progress={progress} />
            <div class="step-inner container">
              <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                <p className='ms-2'>Retour</p>
              </div>
              <div class="p-5">
                <div class="step-heading">
                  <div class="heading-marker"></div>
                  <TypeAnimation
                    sequence={[
                      'Faisons connaissance',
                      1000,

                      // () => {
                      //   console.log('Sequence completed');
                      // },
                    ]}
                    wrapper="div"
                    cursor={false}
                    className='heading'
                  // style={{ fontSize: '2em', display: 'inline-block' }}
                  />
                </div>

                {/* <!-- step-1 form--> */}
                <div class="pop-right step-form" id="step1">
                  <div class="row justify-content-center">

                    <div class="tab-50 col-md-4" onClick={() => handlePhysic()}>
                      <div class="radio-field">
                        <div class="icon mx-auto">
                          <img src={UserImage} alt="img1" className='w-100' />
                        </div>
                        <div class="service-text">
                          Personne physique
                        </div>
                        <input class="gradiant_border" type="radio" name="services" checked={physic} value="corporate service" />
                      </div>
                    </div>

                    <div class="tab-50 col-md-4" onClick={() => handleMoral()}>
                      <div class="radio-field">
                        <div class="icon mx-auto">
                          <img src={CompanyImage} alt="img2" className='w-100' />
                        </div>
                        <div class="service-text">
                          Personne morale
                        </div>
                        <input class="gradiant_border" type="radio" name="services" value="freelancing services" checked={moral} />
                      </div>
                    </div>

                  </div>
                </div>

                {/* <div className="next-prev-btn">
                  <button type="button" className="prev me-5" onClick={prevStep}>Retour</button>
                  <button type="button" id="step2btn" className="next" onClick={nextStep} style={{ backgroundImage: (moral || physic) ? 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' : '' }} >Suivant</button>
                </div> */}

              </div>
            </div>
          </div>
        </section>
      )}
      {/* <!-- step-2 --> */}
      {step === 2 && (
        <section className="steps row">
          {/* <!-- step-2-sidebar --> */}
          <Sibebar />
          <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
            <ProgressBar progress={progress} />
            {moral &&
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      {/* <h3 className="heading text-center" ref={step1} /> */}
                      <TypeAnimation
                        sequence={[
                          'Veuillez présenter votre structure',
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                    {/* <div className="step-text">
                  I see great value in using collaborative tools to make my work more effective and efficient.
                </div> */}
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-4">
                          <div class="select-field">
                            <input type="text" placeholder="Raison Sociale" value={socialReason} onChange={(e) => setSocialReason(e.target.value)} />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="select-field">
                            <input type="text" placeholder="Capital Social" value={socialCapital} onChange={(e) => setSocialCapital(e.target.value)} />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="select-field">
                            <input type="date" data-placeholder="Date de création" value={date} onChange={(e) => setDate(e.target.value)} className={!date ? 'placeholder-shown' : ''} />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="select-field">
                            <input type="text" placeholder="Forme juridique" value={juridique} onChange={e => setJuridique(e.target.value)} />
                          </div>
                        </div>

                      </div>
                      <div className='row'>
                        <div class="col-md-4">
                          <div class="select-field">
                            <input type="email" placeholder="Addresse mail" value={moralEmail} onChange={e => setMoralEmail(e.target.value)} />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="select-field">
                            <input type="text" placeholder="Adresse" value={moralAdress} onChange={e => setMoralAdress(e.target.value)} />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="select-field">
                            <input type="text" placeholder="Contact" value={moralContact} onChange={(e) => setMoralContact(e.target.value.replace(/\D/g, ''))} />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="select-field">
                            <input type="text" placeholder="Nom du représentant" value={representant} onChange={(e) => setRepresentant(e.target.value)} />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="select-field">
                            <input type="text" placeholder="Fonction" value={jobRepresentant} onChange={e => setJobRepresentant(e.target.value)} />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  <div className="next-prev-btn">
                    {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div>
                </div>
              </div>
            }
            {physic &&
              <div className="tab-sm-100 step-3 col-lg-9 col-md-8" style={{ paddingLeft: 0, margin: 'auto' }}>
                <div className="step-inner">
                  <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                    <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                    <p className='ms-2'>Retour</p>
                  </div>
                  <div className="p-5">
                    <article className='mb-5'>
                      <div className="step-heading text-center">
                        <div class="heading-marker"></div>
                        <TypeAnimation
                          sequence={[
                            "Présentez vous s'il vous plait !",
                            1000,
                            // () => {
                            //   console.log('Sequence completed');
                            // },
                          ]}
                          wrapper="h3"
                          cursor={false}
                          className='heading text-center'
                        // style={{ fontSize: '2em', display: 'inline-block' }}
                        />
                      </div>
                      {/* <div className="step-text">
                  I see great value in using collaborative tools to make my work more effective and efficient.
                </div> */}
                    </article>

                    {/* <!-- step-2-form --> */}
                    <div className="pop-right step-form" id="step3">
                      <div className='form-part'>
                        <div className='row'>
                          <div class="col-md-12">
                            <div class="select-field">
                              <select value={civility} onChange={(e) => setCivility(e.target.value)} id="selectservice">
                                <optgroup label='selectionnez la civilité'>
                                  <option hidden>Civilité</option>
                                  <option value="m">M.</option>
                                  <option value="mme">Mme</option>
                                  <option value="mlle">Mlle</option>
                                </optgroup>
                              </select>
                              <span></span>
                            </div>
                            <div class="col-md-12">
                              <div class="select-field">
                                <input value={username} onChange={(e) => setUsername(e.target.value)} type="text" placeholder="Nom & Prénoms" required />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="select-field">
                              <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" id="mail-email" placeholder="Email Address" required />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="select-field">
                              <input type="text" placeholder="Contact" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/g, ''))} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!-- next previous button --> */}
                    <div className="next-prev-btn">
                      {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                      <button type="button"
                        id="step3btn"
                        className="next"
                        onClick={nextStep}
                        style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>

        </section>
      )}
      {/* step 3 */}
      {step === 3 && (
        <section class="steps row">
          {/* <!-- step-1-sidebar --> */}
          <Sibebar />
          <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
            <ProgressBar progress={progress} />
            <div class="step-inner container">
              <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                <p className='ms-2'>Retour</p>
              </div>
              <div class="p-5">
                <div class="step-heading">
                  <div class="heading-marker"></div>
                  <TypeAnimation
                    sequence={[
                      'Êtes vous immatriculé au RCCM ?',
                      1000,

                      // () => {
                      //   console.log('Sequence completed');
                      // },
                    ]}
                    wrapper="div"
                    cursor={false}
                    className='heading'
                  // style={{ fontSize: '2em', display: 'inline-block' }}
                  />
                  {/* <abbr className='info-bulle' title='Lorem ipsum' style={{margin: 0}}>
                    <FontAwesomeIcon icon={faInfoCircle} style={{marginLeft: 15, fontSize: 30}}  />
                  </abbr> */}
                  <FontAwesomeIcon data-tooltip-id="my-tooltip-inline" data-tooltip-content="Le RCCM est un registre où les entreprises et leurs informations légales sont enregistrées." icon={faInfoCircle} style={{ marginLeft: 15, fontSize: 30 }} />
                  <Tooltip
                    id="my-tooltip-inline"
                    style={{ backgroundColor: "#8e736a", color: "#fff" }}
                  />
                </div>
                {/* <div class="info-block">
                    <p>    
                      <a href="#" data-tooltip="Tooltip text :)" className='rounded-circle'><span>i</span></a>
                    </p>
                  </div> */}
                {/* <!-- step-1 form--> */}
                <div class="pop-right step-form" id="step1">
                  <div class="row justify-content-center">

                    <div class="tab-50 col-md-4" onClick={() => handleCreation()}>
                      <div class="radio-field">
                        <div class="icon mx-auto">
                          <img src={UserImage} alt="img1" className='w-100' />
                        </div>
                        <div class="service-text">
                          Dejà en cours de création
                        </div>
                        <input class="gradiant_border" type="radio" name="services" checked={creation} value="corporate service" />
                      </div>
                    </div>

                    <div class="tab-50 col-md-4" onClick={() => handleEtablie()}>
                      <div class="radio-field">
                        <div class="icon mx-auto">
                          <img src={CompanyImage} alt="img2" className='w-100' />
                        </div>
                        <div class="service-text">
                          Déjà établie
                        </div>
                        <input class="gradiant_border" type="radio" name="services" value="freelancing services" checked={etablie} />
                      </div>
                    </div>

                  </div>
                </div>

                {/* <div className="next-prev-btn">
                  <button type="button" className="prev me-5" onClick={prevStep}>Retour</button>
                  <button type="button" id="step2btn" className="next" onClick={nextStepForRccm} style={{ backgroundImage: (creation || etablie) ? 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' : '' }} >Suivant</button>
                </div> */}

              </div>
            </div>
          </div>
        </section>
      )}
      {step === 4 && (
        <section className="steps row">
          {/* <!-- step-2-sidebar --> */}
          <Sibebar />
          <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
            <ProgressBar progress={progress} />
            {etablie &&
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <h3 className="heading text-center"></h3>
                      <TypeAnimation
                        sequence={[
                          "Quelle est le chiffre d'affaire des 3 dernières années",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="div"
                        cursor={false}
                        className='heading'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                    {/* <div className="step-text">
                  I see great value in using collaborative tools to make my work more effective and efficient.
                </div> */}
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <input type="text" placeholder="Chiffre d'affaire année 1" value={chiffreAffaire1} onChange={e => setChiffreAffaire1(e.target.value)} />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="select-field">
                            <input type="text" placeholder="Chiffre d'affaire Année 2" value={chiffreAffaire2} onChange={e => setChiffreAffaire2(e.target.value)} />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="select-field">
                            <input type="text" placeholder="Chiffre d'affaire Année 3" value={chiffreAffaire3} onChange={e => setChiffreAffaire3(e.target.value)} />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  <div className="next-prev-btn">
                    {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div>
                </div>
              </div>
            }
            {creation &&
              <div className="tab-sm-100 step-3 col-lg-9 col-md-8" style={{ paddingLeft: 0, margin: 'auto' }}>

                <div className="step-inner">
                  <div className="p-5">
                    <article className='mb-5'>
                      <div className="step-heading text-center">
                        <div class="heading-marker"></div>
                        <h3 className="heading text-center">Deja en cours de création</h3>
                      </div>
                      {/* <div className="step-text">
                  I see great value in using collaborative tools to make my work more effective and efficient.
                </div> */}
                    </article>

                    {/* <!-- step-2-form --> */}


                    {/* <!-- next previous button --> */}
                    <div className="next-prev-btn">
                      <button type="button" className="prev" onClick={prevStep}>Précédent</button>

                      <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>

        </section>
      )}
      {step === 5 && (
        <section class="steps row">
          {/* <!-- step-1-sidebar --> */}
          <Sibebar />
          <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
            <ProgressBar progress={progress} />
            <div class="step-inner container">
              <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                <p className='ms-2'>Retour</p>
              </div>
              <div class="p-5">
                <div class="step-heading">
                  <div class="heading-marker"></div>
                  <TypeAnimation
                    sequence={[
                      "RECHERCHEZ VOUS DES FONDS POUR UN NOUVEAU PROJET ?",
                      1000,

                      // () => {
                      //   console.log('Sequence completed');
                      // },
                    ]}
                    wrapper="div"
                    cursor={false}
                    className='heading'
                  // style={{ fontSize: '2em', display: 'inline-block' }}
                  />
                  {/* <div class="heading"></div> */}
                </div>
                {/* <p className='text-center' ref={el}/> */}

                {/* <!-- step-1 form--> */}
                <div class="pop-right step-form" id="step1">
                  <div class="row justify-content-center">

                    <div class="tab-50 col-md-4" onClick={() => handleNeedFund()}>
                      <div class="radio-field">
                        <div class="icon mx-auto">
                          <img src={UserImage} alt="img1" className='w-100' />
                        </div>
                        <div class="service-text">
                          Oui
                        </div>
                        <input class="gradiant_border" type="radio" name="services" checked={needFund} value="corporate service" />
                      </div>
                    </div>

                    <div class="tab-50 col-md-4" onClick={() => handleNoNeedFund()}>
                      <div class="radio-field p-5">
                        <div class="icon mx-auto">
                          <img src={CompanyImage} alt="img2" className='w-100' />
                        </div>
                        <div class="service-text">
                          Non, recherche des fonds pour un projet déjà entamé
                        </div>
                        <input class="gradiant_border" type="radio" name="services" value="freelancing services" checked={noNeedFund} />
                      </div>
                    </div>

                  </div>
                </div>

                {/* <div className="next-prev-btn">
                  <button type="button" className="prev me-5" onClick={prevStep}>Retour</button>
                  <button type="button" id="step2btn" className="next" onClick={nextStepForFund} style={{ backgroundImage: (needFund || noNeedFund) ? 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' : '' }} >Suivant</button>
                </div> */}

              </div>
            </div>
          </div>
        </section>
      )}
      {step === 6 && (
        <section className="steps row">
          {/* <!-- step-2-sidebar --> */}
          <Sibebar />
          <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
            <ProgressBar progress={progress} />
            {noNeedFund &&
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Nature du besoin de financement",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />

                    </div>
                    {/* <div className="step-text">
                  I see great value in using collaborative tools to make my work more effective and efficient.
                </div> */}
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={selectedNature} onChange={handleNature}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Nature du besoin de financement</option>
                                <option value="nature_exploitation">Exploitation</option>
                                <option value="nature_invest">Investissement</option>
                              </optgroup>
                            </select>
                            <span></span>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            }

          </div>

        </section>
      )}
      {step === 7 && (
        <section className="steps row">
          {/* <!-- step-2-sidebar --> */}
          <Sibebar />
          <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
            <ProgressBar progress={progress} />
            <div className="step-inner container">
              {etablie ?
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStepForFund}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div> :
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStepForRccm}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>}
              <div className="p-5">
                <article>
                  <div className="step-heading text-center mb-5">
                    <div className="heading-marker"></div>
                    <TypeAnimation
                      sequence={[
                        "Quelle est le titre de votre projet ?",
                        1000,
                      ]}
                      wrapper="h3"
                      cursor={false}
                      className='heading text-center'
                    // style={{ fontSize: '2em', display: 'inline-block' }}
                    />
                  </div>
                  {/* <div className="step-text">
                  I see great value in using collaborative tools to make my work more effective and efficient.
                </div> */}
                </article>

                {/* <!-- step-2-form --> */}
                <div className="pop-right step-form mx-auto" id="step3">

                  <div className='form-part'>
                    <div className='row mb-3'>
                      <div class="col-md-12">
                        <div class="select-field">
                          <input type="text" placeholder="Titre du projet" value={projectTitle} onChange={(e => setProjectTitle(e.target.value))} />
                        </div>
                      </div>

                    </div>

                  </div>
                </div>

                {/* <!-- next previous button --> */}
                <div className="next-prev-btn">
                  {/* {etablie ? <button type="button" className="prev" onClick={prevStepForFund}>Précédent</button> : <button type="button" className="prev" onClick={prevStepForRccm}>Précédent</button>} */}
                  <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                </div>
              </div>
            </div>


          </div>

        </section>
      )}
      {step === 8 && (
        <section className="steps row">
          {/* <!-- step-2-sidebar --> */}
          <Sibebar />
          <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
            <ProgressBar progress={progress} />
            <div className="step-inner container">
              <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                <p className='ms-2'>Retour</p>
              </div>
              <div className="p-5">
                <article>
                  <div className="step-heading text-center mb-5">
                    <div className="heading-marker"></div>
                    <TypeAnimation
                      sequence={[
                        "La description de votre projet",
                        1000,

                        // () => {
                        //   console.log('Sequence completed');
                        // },
                      ]}
                      wrapper="h3"
                      cursor={false}
                      className='heading text-center'
                    // style={{ fontSize: '2em', display: 'inline-block' }}
                    />

                  </div>
                  {/* <div className="step-text">
                  I see great value in using collaborative tools to make my work more effective and efficient.
                </div> */}
                </article>

                {/* <!-- step-2-form --> */}
                <div className="pop-right step-form mx-auto" id="step3">

                  <div className='form-part'>
                    <div className='row mb-3'>
                      <div class="col-md-12">
                        <textarea name="textarea" required="" id="textarea" value={projectDescription} onChange={e => setProjectDescription(e.target.value)}></textarea>
                      </div>

                    </div>

                  </div>
                </div>

                {/* <!-- next previous button --> */}
                <div className="next-prev-btn">
                  {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                  <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                </div>
              </div>
            </div>


          </div>

        </section>
      )}
      {step === 9 && (
        <section className="steps row">
          {/* <!-- step-2-sidebar --> */}
          <Sibebar />
          <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
            <ProgressBar progress={progress} />
            <div className="step-inner container">
              <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                <p className='ms-2'>Retour</p>
              </div>
              <div className="p-5">
                <article>
                  <div className="step-heading text-center mb-5">
                    <div className="heading-marker"></div>
                    <TypeAnimation
                      sequence={[
                        "Votre projet resout quelle problème ?",
                        1000,
                      ]}
                      wrapper="h3"
                      cursor={false}
                      className='heading text-center'
                    // style={{ fontSize: '2em', display: 'inline-block' }}
                    />
                  </div>
                  {/* <div className="step-text">
                  I see great value in using collaborative tools to make my work more effective and efficient.
                </div> */}
                </article>

                {/* <!-- step-2-form --> */}
                <div className="pop-right step-form mx-auto" id="step3">

                  <div className='form-part'>
                    <div className='row mb-3'>
                      <div class="col-md-12">
                        <textarea name="textarea" required="" id="textarea" value={projectProblem} onChange={e => setProjectProblem(e.target.value)}></textarea>
                      </div>

                    </div>

                  </div>
                </div>

                {/* <!-- next previous button --> */}
                <div className="next-prev-btn">
                  {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                  <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                </div>
              </div>
            </div>


          </div>

        </section>
      )}
      {step === 10 && (
        <section className="steps row">
          {/* <!-- step-2-sidebar --> */}
          <Sibebar />
          <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
            <ProgressBar progress={progress} />
            <div className="step-inner container">
              <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                <p className='ms-2'>Retour</p>
              </div>
              <div className="p-5">
                <article>
                  <div className="step-heading text-center mb-5">
                    <div className="heading-marker"></div>
                    <TypeAnimation
                      sequence={[
                        "Quelle est votre cible ? ",
                        1000,

                        // () => {
                        //   console.log('Sequence completed');
                        // },
                      ]}
                      wrapper="h3"
                      cursor={false}
                      className='heading text-center'
                    // style={{ fontSize: '2em', display: 'inline-block' }}
                    />
                  </div>
                  {/* <div className="step-text">
                  I see great value in using collaborative tools to make my work more effective and efficient.
                </div> */}
                </article>

                {/* <!-- step-2-form --> */}
                <div className="pop-right step-form mx-auto" id="step3">

                  <div className='form-part'>
                    <div className='row mb-3'>
                      <div class="col-md-12">
                        <div class="select-field">
                          <select name="select-service" id="selectservice" value={selectedTarget} onChange={handleSelectedTarget}>
                            <optgroup label='Selectionnez'>
                              <option hidden>Liste des cibles</option>
                              <option value="1">Cible 1 </option>
                              <option value="2">Cible 2</option>
                            </optgroup>
                            {/* <option value="1">Exploitation</option>
                              <option value="2">Investissement</option> */}
                          </select>
                          <span></span>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>

                {/* <!-- next previous button --> */}
                {/* <div className="next-prev-btn">
                  <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                  <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                </div> */}
              </div>
            </div>
          </div>

        </section>
      )}
      {step === 11 && (
        <section className="steps row">
          {/* <!-- step-2-sidebar --> */}
          <Sibebar />
          <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
            <ProgressBar progress={progress} />
            <div className="step-inner container">
              <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                <p className='ms-2'>Retour</p>
              </div>
              <div className="p-5">
                <article>
                  <div className="step-heading text-center mb-5">
                    <div className="heading-marker"></div>
                    <TypeAnimation
                      sequence={[
                        "Quel type de financement recherchez vous ? ",
                        1000,
                        // () => {
                        //   console.log('Sequence completed');
                        // },
                      ]}
                      wrapper="h3"
                      cursor={false}
                      className='heading text-center'
                    // style={{ fontSize: '2em', display: 'inline-block' }}
                    />

                  </div>
                  {/* <div className="step-text">
                  I see great value in using collaborative tools to make my work more effective and efficient.
                </div> */}
                </article>

                {/* <!-- step-2-form --> */}
                <div className="pop-right step-form mx-auto" id="step3">

                  <div className='form-part'>
                    <div className='row mb-3'>
                      <div class="col-md-12">
                        <div class="select-field">
                          <select name="select-service" id="selectservice" value={selectedFinance} onChange={handleSelectedFinance}>
                            <optgroup label='Selectionnez'>
                              <option hidden>Type de financement</option>
                              <option value="type_fond_propre">Fonds Propre</option>
                              <option value="type_dette_court_terme">Dette à Court Terme</option>
                              <option value="type_dette_moyen_terme">Dette à Moyen Terme</option>
                              <option value="type_dette_long_terme">Dette à Long Terme</option>
                              <option value="type_subvention">Subvention</option>
                              <option value="type_capital_risque">Capital Risque</option>
                            </optgroup>
                          </select>
                          <span></span>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>

                {/* <!-- next previous button --> */}
                {/* <div className="next-prev-btn">
                  <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                  <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                </div> */}
              </div>
            </div>
          </div>

        </section>
      )}
      {step === 12 && (
        <section className="steps row">
          {/* <!-- step-2-sidebar --> */}
          <Sibebar />
          <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
            <ProgressBar progress={progress} />
            <div className="step-inner container">
              <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                <p className='ms-2'>Retour</p>
              </div>
              <div className="p-5">
                <article>
                  <div className="step-heading text-center mb-5">
                    <div className="heading-marker"></div>
                    <TypeAnimation
                      sequence={[
                        "Montant du besoin de financement",
                        1000,

                        // () => {
                        //   console.log('Sequence completed');
                        // },
                      ]}
                      wrapper="h3"
                      cursor={false}
                      className='heading text-center'
                    // style={{ fontSize: '2em', display: 'inline-block' }}
                    />

                  </div>
                  {/* <div className="step-text">
                  I see great value in using collaborative tools to make my work more effective and efficient.
                </div> */}
                </article>

                {/* <!-- step-2-form --> */}
                <div className="pop-right step-form mx-auto" id="step3">

                  <div className='form-part'>
                    <div className='row mb-3'>
                      <div class="col-md-12">
                        <div class="select-field">
                          <input type="text" placeholder="Saisir montant du financement" value={amountFinance} onChange={e => setAmountFinance(e.target.value.replace(/\D/g, ''))} />
                        </div>
                      </div>

                    </div>

                  </div>
                </div>

                {/* <!-- next previous button --> */}
                <div className="next-prev-btn">
                  {/* {etablie ? <button type="button" className="prev" onClick={prevStepForFund}>Précédent</button> : <button type="button" className="prev" onClick={prevStepForRccm}>Précédent</button>} */}
                  <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                </div>
              </div>
            </div>


          </div>

        </section>
      )}
      {step === 13 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-13 --> */}
          <section class="steps row">
            {/* <!-- step-13-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div class="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div class="p-5">
                  <div class="step-heading">
                    <div class="heading-marker"></div>
                    <TypeAnimation
                      sequence={[
                        "Secteur d'activité",
                        1000,
                        // () => {
                        //   console.log('Sequence completed');
                        // },
                      ]}
                      wrapper="div"
                      cursor={false}
                      className='heading'
                    // style={{ fontSize: '2em', display: 'inline-block' }}
                    />
                    {/* <div class="heading"></div> */}
                  </div>

                  {/* <!-- step-1 form--> */}
                  <div class="pop-right step-form" id="step1">
                    <div class="row justify-content-center">

                      <div class="tab-50 col-md-4" onClick={() => handleBtp()}>
                        <div class="radio-field p-5">
                          <div class="icon mx-auto">
                            <img src={Service2} alt="service 2" />
                          </div>
                          <div class="service-text">
                            BTP
                          </div>
                          <input class="gradiant_border" type="radio" name="services" value={btp} checked={btp} />
                        </div>
                      </div>
                      <div class="tab-50 col-md-4" onClick={() => handleIndustry()}>
                        <div class="radio-field p-5">
                          <div class="icon mx-auto">
                            <img src={Service3} alt="service 3" />
                          </div>
                          <div class="service-text">
                            Industrie
                          </div>
                          <input class="gradiant_border" type="radio" name="services" value={industry} checked={industry} />
                        </div>
                      </div>
                      <div class="tab-50 col-md-4" onClick={() => handleTech()}>
                        <div class="radio-field p-5">
                          <div class="icon mx-auto">
                            <img src={Service3} alt="service 3" />
                          </div>
                          <div class="service-text">
                            Tech
                          </div>
                          <input class="gradiant_border" type="radio" name="services" value={tech} checked={tech} />
                        </div>
                      </div>
                      <div class="tab-50 col-md-4" onClick={() => handleTourism()}>
                        <div class="radio-field p-5">
                          <div class="icon mx-auto">
                            <img src={Service3} alt="service 3" />
                          </div>
                          <div class="service-text">
                            Hotellerie / Tourisme
                          </div>
                          <input class="gradiant_border" type="radio" name="services" value={tourism} checked={tourism} />
                        </div>
                      </div>
                      <div class="tab-50 col-md-4" onClick={() => handleInstitutional()}>
                        <div class="radio-field p-5">
                          <div class="icon mx-auto">
                            <img src={Service3} alt="service 3" />
                          </div>
                          <div class="service-text">
                            Institution financière
                          </div>
                          <input class="gradiant_border" type="radio" name="services" value={financialInstitution} checked={financialInstitution} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next button--> */}
                  {/* <div class="next-prev-btn">
                    <button type="button" id="step1btn" class="prev" onClick={() => prevStep()}>Précédent</button>
                    <button type="button" id="step1btn" style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }} onClick={() => nextStep()}>Continuer</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 14 && (
        <>{renderSector()}</>
      )}
      {step === 15 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />

              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Avez vous d'autres partenaires technniques intervenant dans le projet ?",
                          1000,
                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">
                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={partenaireTech} onChange={handlePartenaireTech}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="partenaire_tech_oui">Oui</option>
                                <option value="partenaire_tech_non">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                        {partenaireTech === 'partenaire_tech_oui' && <div className='col-md-12'>
                          <h3 className='text-center mb-3'>Citer les partenaires</h3>
                          {/* Afficher tous les éléments de la liste */}
                          {items.map((item, index) => (
                            <div key={index} className="form-group">
                              <div className="row align-items-center">
                                <div className="col-md-10">
                                  <div className='select-field'>
                                    <input key={index} type="text" placeholder="Saisir le nom" value={item.name} onChange={e => handleInputsChange(index, e)} />
                                  </div>
                                </div>
                                {items.length > 1 && (
                                  <div className='col-md-2'>
                                    <div className='container'>
                                      <FontAwesomeIcon icon={faMinus} onClick={() => handleDeleteItem(index)} className='cursor-pointer p-1' style={{ cursor: "pointer", border: "2px solid red", borderRadius: "50px", color: "red" }} />
                                    </div>

                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                          <div className='container'>
                            <FontAwesomeIcon icon={faPlus} onClick={handleAddItem} className='cursor-pointer p-1' style={{ cursor: "pointer", border: "2px solid #8e736a", borderRadius: "50px", color: "#8e736a" }} />
                          </div>
                          <div className="next-prev-btn">
                            {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                            <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                          </div>
                        </div>
                        }
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 16 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />

              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Votre projet actuel a-t-il déjà reçu un financement ?",
                          1000,
                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">
                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={financement} onChange={handleFinancement}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="financement_oui">Oui</option>
                                <option value="financement_non">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStepForFinancement} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 17 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Veuillez entrer le montant du financement reçu",
                          1000,
                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">
                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div className='select-field'>
                            <input type="text" placeholder="Saisir le montant" value={montantFinancement} onChange={e => setMontantFinancement(e.target.value.replace(/\D/g, ''))} />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  <div className="next-prev-btn">
                    {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 18 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />

              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Nom du bailleur",
                          1000,
                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">
                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div className='select-field'>
                            <input type="text" placeholder="Saisir le nom du bailleur" value={nomBailleur} onChange={e => setNomBailleur(e.target.value)} />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  <div className="next-prev-btn">
                    {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 19 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Quel était le type d'investissement ? ",
                          1000,
                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">
                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={typeInvestissement} onChange={handleTypeInvestissement}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="partenaire_tech_oui">Investissement en capital</option>
                                <option value="have_abe_non">Prêt bancaire</option>
                                <option value="have_abe_no">Subvention gouvernementale</option>
                                <option value="have_abe_nonn">Financement participatif</option>
                                <option value="have_abe_nonnn">Autre</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 20 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />

              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStepForEmprunt}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Avez-vous des emprunts en cours de remboursement?",
                          1000,
                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">
                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={empruntEnCours} onChange={handleEmpruntEnCours}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="emprunt_oui">Oui</option>
                                <option value="emprunt_non">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                        {empruntEnCours === 'emprunt_oui' && <div className='col-md-12'>
                          <h3 className='text-center mb-3'>Entrer le montant</h3>
                          <div className='select-field'>
                            <input type="text" placeholder="Entrer le montant" value={montantEmpruntEnCours} onChange={e => setMontantEmpruntEnCours(e.target.value.replace(/\D/g, ''))} />
                          </div>
                          <div className="next-prev-btn">
                            {/* <button type="button" className="prev" onClick={prevStepForEmprunt}>Précédent</button> */}
                            <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                          </div>
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStepForEmprunt}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 21 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Disposez-vous d'un calendrier détaillé des travaux ?",
                          1000,
                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">
                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={calendrierDetailler} onChange={handleCalendrierDetailler}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="emprunt_oui">Oui</option>
                                <option value="emprunt_non">En cours de finalisation</option>
                                <option value="emprunt_non">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 22 && (
        <section className="steps row">
          {
            // {BTP ImmoBILIER PERSONNE PHYSIQUE}
            ((physic && btp && btpData.btpSecteurActivite === 'btp_immobilier')) ? <> <Sibebar />
              <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                <ProgressBar progress={progress} />
                <div className="step-inner container">
                  <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                    <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                    <p className='ms-2'>Retour</p>
                  </div>
                  <div className="p-5">
                    <article>
                      <div className="step-heading text-center mb-5">
                        <div className="heading-marker"></div>
                        {/* <h3 className="heading text-center" ref={step1} /> */}
                        <TypeAnimation
                          sequence={[
                            "Charger les documents",
                            1000,
                            // () => {
                            //   console.log('Sequence completed');
                            // },
                          ]}
                          wrapper="h3"
                          cursor={false}
                          className='heading text-center'
                        // style={{ fontSize: '2em', display: 'inline-block' }}
                        />
                      </div>
                    </article>

                    {/* <!-- step-2-form --> */}
                    <div className='mb-5'>
                      <UploadComponent onFileUpload={handleFileUpload} />
                    </div>
                    {/* <h2>Uploaded Files</h2>
                                  <ul>
                                      {files.map((file, index) => (
                                          <li key={index}>{file.name}</li>
                                      ))}
                                  </ul> */}
                    <h2>Liste des fichiers à charger</h2>
                    <div className='row mb-3'>

                      <div className='col-md-4'>
                        <p> - Pièce d'identité recto verso en cours de validité (carte nationale d’identité / carte de séjour / carte de résident /  4 premières pages du passeport). </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Deux (2) photos d'identité de même tirage 3.</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Une facture d'eau ou d'électricité (portant le nom du client) ou à défaut un certificat de résidence</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Le relevé d’identité bancaire (RIB)</p>
                      </div>


                      <div className='col-md-4'>
                        <p> - Carte d'identité ou passeport</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Acte de propriété ou titre foncier</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - ACD</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Permis de construire </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Agrément promoteur</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Étude technique </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - DQE (Devis Quantitatif et Estimatif) </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Chronogramme d'execution</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Business Plan du projet</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Liste des partenaires techniques</p>
                      </div>

                    </div>
                    {/* <!-- next previous button --> */}
                    <div className="next-prev-btn">
                      {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                      <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                    </div>
                  </div>
                </div>
              </div></> : null
          }
          {
            // BTP Immobilier entité Morale
            ((moral && btp && btpData.btpSecteurActivite === 'btp_immobilier')) ? <> <Sibebar />
              <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                <ProgressBar progress={progress} />
                <div className="step-inner container">
                  <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                    <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                    <p className='ms-2'>Retour</p>
                  </div>
                  <div className="p-5">
                    <article>
                      <div className="step-heading text-center mb-5">
                        <div className="heading-marker"></div>
                        {/* <h3 className="heading text-center" ref={step1} /> */}
                        <TypeAnimation
                          sequence={[
                            "Charger les documents",
                            1000,
                            // () => {
                            //   console.log('Sequence completed');
                            // },
                          ]}
                          wrapper="h3"
                          cursor={false}
                          className='heading text-center'
                        // style={{ fontSize: '2em', display: 'inline-block' }}
                        />
                      </div>
                    </article>

                    {/* <!-- step-2-form --> */}
                    <div className='mb-5'>
                      <UploadComponent onFileUpload={handleFileUpload} />
                    </div>
                    {/* <h2>Uploaded Files</h2>
                                  <ul>
                                      {files.map((file, index) => (
                                          <li key={index}>{file.name}</li>
                                      ))}
                                  </ul> */}
                    <h2>Liste des fichiers à charger</h2>
                    <div className='row mb-3'>


                      <div className='col-md-4'>
                        <p> - Statuts de l’entreprise</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Registre de commerce</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Procès-verbaux d’assemblées générales de constitution</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Délibérations du conseil d’administration ou du directoire</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Délégation de pouvoirs</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Recueil de signatures autorisées, Pour le(s) représentant(s) de la personne morale : Tous documents justifiant de l’identité du (des) représentant(s) </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Carte nationale d’identité (recto et du verso), • ou carte de séjour, • ou carte de résident, • ou passeport (4 premières pages).  </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - photos d’identité</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Le relevé d’identité bancaire (RIB) de la personne morale</p>
                      </div>


                      <div className='col-md-4'>
                        <p> - Statut</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - DFE</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - RCCM</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - CNI//Passport du representant légal</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Etats financiers des 3 dernièrs exercices si applicable</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - ACD  </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Permis de construire </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Agrément promoteur </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Étude technique</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - DQE (Devis Quantitatif et Estimatif)</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Chronogramme d'execution</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Business Plan du projet</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Liste des partenaires techniques</p>
                      </div>

                    </div>
                    {/* <!-- next previous button --> */}
                    <div className="next-prev-btn">
                      {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                      <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                    </div>
                  </div>
                </div>
              </div></> : null
          }
          {
            // BTP Infrastructure physique
            (physic && btp && btpData.btpSecteurActivite === 'btp_infrastructure') ? <> <Sibebar />
              <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                <ProgressBar progress={progress} />
                <div className="step-inner container">
                  <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                    <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                    <p className='ms-2'>Retour</p>
                  </div>
                  <div className="p-5">
                    <article>
                      <div className="step-heading text-center mb-5">
                        <div className="heading-marker"></div>
                        {/* <h3 className="heading text-center" ref={step1} /> */}
                        <TypeAnimation
                          sequence={[
                            "Charger les documents",
                            1000,
                            // () => {
                            //   console.log('Sequence completed');
                            // },
                          ]}
                          wrapper="h3"
                          cursor={false}
                          className='heading text-center'
                        // style={{ fontSize: '2em', display: 'inline-block' }}
                        />
                      </div>
                    </article>

                    {/* <!-- step-2-form --> */}
                    <div className='mb-5'>
                      <UploadComponent onFileUpload={handleFileUpload} />
                    </div>
                    {/* <h2>Uploaded Files</h2>
                                  <ul>
                                      {files.map((file, index) => (
                                          <li key={index}>{file.name}</li>
                                      ))}
                                  </ul> */}
                    <h2>Liste des fichiers à charger</h2>
                    <div className='row mb-3'>

                      <div className='col-md-4'>
                        <p> - Pièce d'identité recto verso en cours de validité (carte nationale d’identité / carte de séjour / carte de résident /  4 premières pages du passeport). </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Deux (2) photos d'identité de même tirage 3.</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Une facture d'eau ou d'électricité (portant le nom du client) ou à défaut un certificat de résidence</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Le relevé d’identité bancaire (RIB)</p>
                      </div>

                      <div className='col-md-4'>
                        <p> - Statut</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - DFE</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - RCCM</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - CNI//Passport du representant légal</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Etats financiers des 3 dernièrs exercices si applicable</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Copie du marché  </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Compte d'exploitation prévisionnel </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - DQE </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Chronogramme d'execution</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Business Plan du projet</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Liste des partenaires techniques</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Attestation de bonnee exécution</p>
                      </div>
                    </div>
                    {/* <!-- next previous button --> */}
                    <div className="next-prev-btn">
                      {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                      <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                    </div>
                  </div>
                </div>
              </div></> : null
          }
          {
            // BTP Infrastructure moral
            (moral && btp && btpData.btpSecteurActivite === 'btp_infrastructure') ? <> <Sibebar />
              <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                <ProgressBar progress={progress} />
                <div className="step-inner container">
                  <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                    <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                    <p className='ms-2'>Retour</p>
                  </div>
                  <div className="p-5">
                    <article>
                      <div className="step-heading text-center mb-5">
                        <div className="heading-marker"></div>
                        {/* <h3 className="heading text-center" ref={step1} /> */}
                        <TypeAnimation
                          sequence={[
                            "Charger les documents",
                            1000,
                            // () => {
                            //   console.log('Sequence completed');
                            // },
                          ]}
                          wrapper="h3"
                          cursor={false}
                          className='heading text-center'
                        // style={{ fontSize: '2em', display: 'inline-block' }}
                        />
                      </div>
                    </article>

                    {/* <!-- step-2-form --> */}
                    <div className='mb-5'>
                      <UploadComponent onFileUpload={handleFileUpload} />
                    </div>
                    {/* <h2>Uploaded Files</h2>
                                  <ul>
                                      {files.map((file, index) => (
                                          <li key={index}>{file.name}</li>
                                      ))}
                                  </ul> */}
                    <h2>Liste des fichiers à charger</h2>
                    <div className='row mb-3'>

                      <div className='col-md-4'>
                        <p> - Statuts de l’entreprise</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Registre de commerce</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Procès-verbaux d’assemblées générales de constitution</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Délibérations du conseil d’administration ou du directoire</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Délégation de pouvoirs</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Recueil de signatures autorisées, Pour le(s) représentant(s) de la personne morale : Tous documents justifiant de l’identité du (des) représentant(s) </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Carte nationale d’identité (recto et du verso), • ou carte de séjour, • ou carte de résident, • ou passeport (4 premières pages).  </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - photos d’identité</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Le relevé d’identité bancaire (RIB) de la personne morale</p>
                      </div>

                      <div className='col-md-4'>
                        <p> - Statut</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - DFE</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - RCCM</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - CNI//Passport du representant légal</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Etats financiers des 3 dernièrs exercices si applicable</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Copie du marché  </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Compte d'exploitation prévisionnel </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - DQE </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Chronogramme d'execution</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Business Plan du projet</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Liste des partenaires techniques</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Attestation de bonnee exécution</p>
                      </div>

                    </div>
                    {/* <!-- next previous button --> */}
                    <div className="next-prev-btn">
                      {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                      <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                    </div>
                  </div>
                </div>
              </div></> : null
          }
          {
            //  Industrie physiqye
            (physic && industry) ? <> <Sibebar />
              <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                <ProgressBar progress={progress} />
                <div className="step-inner container">
                  <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                    <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                    <p className='ms-2'>Retour</p>
                  </div>
                  <div className="p-5">
                    <article>
                      <div className="step-heading text-center mb-5">
                        <div className="heading-marker"></div>
                        {/* <h3 className="heading text-center" ref={step1} /> */}
                        <TypeAnimation
                          sequence={[
                            "Charger les documents",
                            1000,
                            // () => {
                            //   console.log('Sequence completed');
                            // },
                          ]}
                          wrapper="h3"
                          cursor={false}
                          className='heading text-center'
                        // style={{ fontSize: '2em', display: 'inline-block' }}
                        />
                      </div>
                    </article>

                    {/* <!-- step-2-form --> */}
                    <div className='mb-5'>
                      <UploadComponent onFileUpload={handleFileUpload} />
                    </div>
                    {/* <h2>Uploaded Files</h2>
                                  <ul>
                                      {files.map((file, index) => (
                                          <li key={index}>{file.name}</li>
                                      ))}
                                  </ul> */}
                    <h2>Liste des fichiers à charger</h2>
                    <div className='row mb-3'>

                      <div className='col-md-4'>
                        <p> - Pièce d'identité recto verso en cours de validité (carte nationale d’identité / carte de séjour / carte de résident /  4 premières pages du passeport). </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Deux (2) photos d'identité de même tirage 3.</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Une facture d'eau ou d'électricité (portant le nom du client) ou à défaut un certificat de résidence</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Le relevé d’identité bancaire (RIB)</p>
                      </div>

                      <div className='col-md-4'>
                        <p> - Statut</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - DFE</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - RCCM</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - CNI / Passport du representant légal</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Etats financiers des 3 dernièrs exercices si applicable</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Business plan</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Certificat et permis</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Etudes réalisées</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - DQE</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Chronogramme d'execution</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Liste des partenaires techniques</p>
                      </div>
                    </div>
                    {/* <!-- next previous button --> */}
                    <div className="next-prev-btn">
                      {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                      <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Soumettre</button>
                    </div>
                  </div>
                </div>
              </div></> : null
          }
          {
            //  Industrie moral
            (moral && industry) ? <> <Sibebar />
              <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                <ProgressBar progress={progress} />
                <div className="step-inner container">
                  <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                    <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                    <p className='ms-2'>Retour</p>
                  </div>
                  <div className="p-5">
                    <article>
                      <div className="step-heading text-center mb-5">
                        <div className="heading-marker"></div>
                        {/* <h3 className="heading text-center" ref={step1} /> */}
                        <TypeAnimation
                          sequence={[
                            "Charger les documents",
                            1000,
                            // () => {
                            //   console.log('Sequence completed');
                            // },
                          ]}
                          wrapper="h3"
                          cursor={false}
                          className='heading text-center'
                        // style={{ fontSize: '2em', display: 'inline-block' }}
                        />
                      </div>
                    </article>

                    {/* <!-- step-2-form --> */}
                    <div className='mb-5'>
                      <UploadComponent onFileUpload={handleFileUpload} />
                    </div>
                    {/* <h2>Uploaded Files</h2>
                                  <ul>
                                      {files.map((file, index) => (
                                          <li key={index}>{file.name}</li>
                                      ))}
                                  </ul> */}
                    <h2>Liste des fichiers à charger</h2>
                    <div className='row mb-3'>


                      <div className='col-md-4'>
                        <p> - Statuts de l’entreprise</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Registre de commerce</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Procès-verbaux d’assemblées générales de constitution</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Délibérations du conseil d’administration ou du directoire</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Délégation de pouvoirs</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Recueil de signatures autorisées, Pour le(s) représentant(s) de la personne morale : Tous documents justifiant de l’identité du (des) représentant(s) </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Carte nationale d’identité (recto et du verso), • ou carte de séjour, • ou carte de résident, • ou passeport (4 premières pages).  </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - photos d’identité</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Le relevé d’identité bancaire (RIB) de la personne morale</p>
                      </div>


                      <div className='col-md-4'>
                        <p> - Statut</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - DFE</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - RCCM</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - CNI / Passport du representant légal</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Etats financiers des 3 dernièrs exercices si applicable</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Business plan</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Certificat et permis</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Etudes réalisées</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - DQE</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Chronogramme d'execution</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Liste des partenaires techniques</p>
                      </div>
                    </div>
                    {/* <!-- next previous button --> */}
                    <div className="next-prev-btn">
                      {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                      <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Soumettre</button>
                    </div>
                  </div>
                </div>
              </div></> : null
          }
          {
            //  Tech physiqye
            (physic && tech) ? <> <Sibebar />
              <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                <ProgressBar progress={progress} />
                <div className="step-inner container">
                  <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                    <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                    <p className='ms-2'>Retour</p>
                  </div>
                  <div className="p-5">
                    <article>
                      <div className="step-heading text-center mb-5">
                        <div className="heading-marker"></div>
                        {/* <h3 className="heading text-center" ref={step1} /> */}
                        <TypeAnimation
                          sequence={[
                            "Charger les documents",
                            1000,
                            // () => {
                            //   console.log('Sequence completed');
                            // },
                          ]}
                          wrapper="h3"
                          cursor={false}
                          className='heading text-center'
                        // style={{ fontSize: '2em', display: 'inline-block' }}
                        />
                      </div>
                    </article>

                    {/* <!-- step-2-form --> */}
                    <div className='mb-5'>
                      <UploadComponent onFileUpload={handleFileUpload} />
                    </div>
                    <h2>Liste des fichiers à charger</h2>
                    <div className='row mb-3'>

                      <div className='col-md-4'>
                        <p> - Pièce d'identité recto verso en cours de validité (carte nationale d’identité / carte de séjour / carte de résident /  4 premières pages du passeport). </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Deux (2) photos d'identité de même tirage 3.</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Une facture d'eau ou d'électricité (portant le nom du client) ou à défaut un certificat de résidence</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Le relevé d’identité bancaire (RIB)</p>
                      </div>

                      <div className='col-md-4'>
                        <p> - Statut</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - DFE</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - RCCM</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - CNI//Passport du representant légal</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Etats financiers des 3 dernièrs exercices si applicable</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Business plan</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Documentation du prototype ou MVP</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Copies des brevets déposés</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Résultats des tests utilisateurs</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - CVs des membres de l'entreprise</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Documentations légales</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Contrats de partenariats (si applicable)</p>
                      </div>
                    </div>
                    {/* <!-- next previous button --> */}
                    <div className="next-prev-btn">
                      {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                      <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Soumettre</button>
                    </div>
                  </div>
                </div>
              </div></> : null
          }
          {
            // Tech moral
            (moral && tech) ? <> <Sibebar />
              <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                <ProgressBar progress={progress} />
                <div className="step-inner container">
                  <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                    <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                    <p className='ms-2'>Retour</p>
                  </div>
                  <div className="p-5">
                    <article>
                      <div className="step-heading text-center mb-5">
                        <div className="heading-marker"></div>
                        {/* <h3 className="heading text-center" ref={step1} /> */}
                        <TypeAnimation
                          sequence={[
                            "Charger les documents",
                            1000,
                            // () => {
                            //   console.log('Sequence completed');
                            // },
                          ]}
                          wrapper="h3"
                          cursor={false}
                          className='heading text-center'
                        // style={{ fontSize: '2em', display: 'inline-block' }}
                        />
                      </div>
                    </article>

                    {/* <!-- step-2-form --> */}
                    <div className='mb-5'>
                      <UploadComponent onFileUpload={handleFileUpload} />
                    </div>
                    {/* <h2>Uploaded Files</h2>
                                  <ul>
                                      {files.map((file, index) => (
                                          <li key={index}>{file.name}</li>
                                      ))}
                                  </ul> */}
                    <h2>Liste des fichiers à charger</h2>
                    <div className='row mb-3'>

                      <div className='col-md-4'>
                        <p> - Statuts de l’entreprise</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Registre de commerce</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Procès-verbaux d’assemblées générales de constitution</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Délibérations du conseil d’administration ou du directoire</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Délégation de pouvoirs</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Recueil de signatures autorisées, Pour le(s) représentant(s) de la personne morale : Tous documents justifiant de l’identité du (des) représentant(s) </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Carte nationale d’identité (recto et du verso), • ou carte de séjour, • ou carte de résident, • ou passeport (4 premières pages).  </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - photos d’identité</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Le relevé d’identité bancaire (RIB) de la personne morale</p>
                      </div>

                      <div className='col-md-4'>
                        <p> - Statut</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - DFE</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - RCCM</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - CNI//Passport du representant légal</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Etats financiers des 3 dernièrs exercices si applicable</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Business plan</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Documentation du prototype ou MVP</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Copies des brevets déposés</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Résultats des tests utilisateurs</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - CVs des membres de l'entreprise</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Documentations légales</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Contrats de partenariats (si applicable)</p>
                      </div>
                    </div>
                    {/* <!-- next previous button --> */}
                    <div className="next-prev-btn">
                      {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                      <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Soumettre</button>
                    </div>
                  </div>
                </div>
              </div></> : null
          }
          {
            // Tourism physique
            (physic && tourism) ? <> <Sibebar />
              <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                <ProgressBar progress={progress} />
                <div className="step-inner container">
                  <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                    <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                    <p className='ms-2'>Retour</p>
                  </div>
                  <div className="p-5">
                    <article>
                      <div className="step-heading text-center mb-5">
                        <div className="heading-marker"></div>
                        {/* <h3 className="heading text-center" ref={step1} /> */}
                        <TypeAnimation
                          sequence={[
                            "Charger les documents",
                            1000,
                            // () => {
                            //   console.log('Sequence completed');
                            // },
                          ]}
                          wrapper="h3"
                          cursor={false}
                          className='heading text-center'
                        // style={{ fontSize: '2em', display: 'inline-block' }}
                        />
                      </div>
                    </article>

                    {/* <!-- step-2-form --> */}
                    <div className='mb-5'>
                      <UploadComponent onFileUpload={handleFileUpload} />
                    </div>
                    <h2>Liste des fichiers à charger</h2>
                    <div className='row mb-3'>

                      <div className='col-md-4'>
                        <p> - Pièce d'identité recto verso en cours de validité (carte nationale d’identité / carte de séjour / carte de résident /  4 premières pages du passeport). </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Deux (2) photos d'identité de même tirage 3.</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Une facture d'eau ou d'électricité (portant le nom du client) ou à défaut un certificat de résidence</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Le relevé d’identité bancaire (RIB)</p>
                      </div>

                      <div className='col-md-4'>
                        <p> - Statut</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - DFE</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - RCCM</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - CNI//Passport du representant légal</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Etats financiers des 3 dernièrs exercices si applicable</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Business plan</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Licences et permis</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Plans détaillés de l'établissement</p>
                      </div>
                    </div>
                    {/* <!-- next previous button --> */}
                    <div className="next-prev-btn">
                      {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                      <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Soumettre</button>
                    </div>
                  </div>
                </div>
              </div></> : null
          }
          {
            // Tourism moral
            (moral && tourism) ? <> <Sibebar />
              <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                <ProgressBar progress={progress} />
                <div className="step-inner container">
                  <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                    <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                    <p className='ms-2'>Retour</p>
                  </div>
                  <div className="p-5">
                    <article>
                      <div className="step-heading text-center mb-5">
                        <div className="heading-marker"></div>
                        {/* <h3 className="heading text-center" ref={step1} /> */}
                        <TypeAnimation
                          sequence={[
                            "Charger les documents",
                            1000,
                            // () => {
                            //   console.log('Sequence completed');
                            // },
                          ]}
                          wrapper="h3"
                          cursor={false}
                          className='heading text-center'
                        // style={{ fontSize: '2em', display: 'inline-block' }}
                        />
                      </div>
                    </article>

                    {/* <!-- step-2-form --> */}
                    <div className='mb-5'>
                      <UploadComponent onFileUpload={handleFileUpload} />
                    </div>
                    {/* <h2>Uploaded Files</h2>
                                  <ul>
                                      {files.map((file, index) => (
                                          <li key={index}>{file.name}</li>
                                      ))}
                                  </ul> */}
                    <h2>Liste des fichiers à charger</h2>
                    <div className='row mb-3'>

                      <div className='col-md-4'>
                        <p> - Statuts de l’entreprise</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Registre de commerce</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Procès-verbaux d’assemblées générales de constitution</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Délibérations du conseil d’administration ou du directoire</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Délégation de pouvoirs</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Recueil de signatures autorisées, Pour le(s) représentant(s) de la personne morale : Tous documents justifiant de l’identité du (des) représentant(s) </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Carte nationale d’identité (recto et du verso), • ou carte de séjour, • ou carte de résident, • ou passeport (4 premières pages).  </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - photos d’identité</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Le relevé d’identité bancaire (RIB) de la personne morale</p>
                      </div>

                      <div className='col-md-4'>
                        <p> - Statut</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - DFE</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - RCCM</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - CNI//Passport du representant légal</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Etats financiers des 3 dernièrs exercices si applicable</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Business plan</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Licences et permis</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Plans détaillés de l'établissement</p>
                      </div>
                    </div>
                    {/* <!-- next previous button --> */}
                    <div className="next-prev-btn">
                      {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                      <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Soumettre</button>
                    </div>
                  </div>
                </div>
              </div></> : null
          }
          {
            // Finance physique
            (physic && financialInstitution) ? <> <Sibebar />
              <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                <ProgressBar progress={progress} />
                <div className="step-inner container">
                  <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                    <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                    <p className='ms-2'>Retour</p>
                  </div>
                  <div className="p-5">
                    <article>
                      <div className="step-heading text-center mb-5">
                        <div className="heading-marker"></div>
                        {/* <h3 className="heading text-center" ref={step1} /> */}
                        <TypeAnimation
                          sequence={[
                            "Charger les documents",
                            1000,
                            // () => {
                            //   console.log('Sequence completed');
                            // },
                          ]}
                          wrapper="h3"
                          cursor={false}
                          className='heading text-center'
                        // style={{ fontSize: '2em', display: 'inline-block' }}
                        />
                      </div>
                    </article>

                    {/* <!-- step-2-form --> */}
                    <div className='mb-5'>
                      <UploadComponent onFileUpload={handleFileUpload} />
                    </div>
                    <h2>Liste des fichiers à charger</h2>
                    <div className='row mb-3'>

                      <div className='col-md-4'>
                        <p> - Pièce d'identité recto verso en cours de validité (carte nationale d’identité / carte de séjour / carte de résident /  4 premières pages du passeport). </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Deux (2) photos d'identité de même tirage 3.</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Une facture d'eau ou d'électricité (portant le nom du client) ou à défaut un certificat de résidence</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Le relevé d’identité bancaire (RIB)</p>
                      </div>

                      <div className='col-md-4'>
                        <p> - La documentation juridique de la banque (Statuts juridiques, RCCM, agrément, actionnariat, pacte d’actionnaire si applicable ...)</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Le Profil et CV des actionnaires y compris les copies de leurs passeports ;</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Le Management de la banque, le profil des principaux responsables et copies de leurs pièces d’identité ou passeports ;</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Organigramme détaillé ;</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - La copie des manuels, politiques et procédures qui régissent l’activité de la banque (crédit, Gestion du risque, gestion de la liquidité, gestion environnementale, AML, Trade finance….) ;</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Le plan stratégique de la banque ou tout autre document équivalent</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Une note d’informations détaillée sur la ligne de crédit sollicitée (objet, objectifs, pipeline indicatif global, répartition par secteur cible et justification)</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Plan d'affaires / Business plan de la banque incluant (i) la base de préparation, (ii) les hypothèses prises en compte et (iii) les indicateurs financiers et lles projections financières sur au moins 03 ans.</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Comptes audités au format PCB au 31/12/2020, 31/12/2021, 31/12/2022 et au 30/06/2023</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Balances générales PCB au 31/12/2015, 31/12/2016, 31/12/2017 et période 2018 la plus récente</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Etats DEC - Etats financiers communiqués à la Commission Bancaire au cours de la période 2015, 2016, 2017, 2018</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Rapports généraux, spéciaux et sur le contrôle interne des commissaires aux comptes au 331/12/2020, 31/12/2021, 31/12/2022 et rapport d'examen limité au 30/06/2023</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Les rapports annuels  lors de la période 2020, 2021 et 2022</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Rapports transmis à la Commission Bancaire tels que définis par les articles 34, 35 et 36 de la circulaire n° 003-2011/CB/C relative à l'organisation du système de contrôle interne des établissements de crédit de l'UMOA, lors de la période 2020, 2021 et 2022</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Rapports d'audit interne et de contrôle interne au cours de la période 2015, 2016, 2017, 2018</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Les rapports internes 2020, 2021 et 2022 sur la revue globale du portefeuille</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Rapports d'inspection de la commission bancaire au cours de la période 2020, 2021 et 2022</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Ratios prudentiels Commission Bancaire (FODEP) au 31/12/2020, 31/12/2021, 31/12/2022 et au 30/06/2023</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Les différents rapports AML annuels des exercices 2020, 2021 et 2022</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Détail du portefeuille de titres détenus par la banque à date avec leur échéancier</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Etat d'inventaire valorisé des actifs corporels et incoporels détenus par la banque au 31/12/2020, 31/12/2021, 31/12/2022 et au 30/06/2023</p>
                      </div>
                    </div>
                    {/* <!-- next previous button --> */}
                    <div className="next-prev-btn">
                      {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                      <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Soumettre</button>
                    </div>
                  </div>
                </div>
              </div></> : null
          }
          {
            // Finance moral
            (moral && financialInstitution) ? <> <Sibebar />
              <div className='step-2 tab-sm-100 col-lg-9 col-md-8' style={{ paddingLeft: 0 }}>
                <ProgressBar progress={progress} />
                <div className="step-inner container">
                  <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                    <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                    <p className='ms-2'>Retour</p>
                  </div>
                  <div className="p-5">
                    <article>
                      <div className="step-heading text-center mb-5">
                        <div className="heading-marker"></div>
                        {/* <h3 className="heading text-center" ref={step1} /> */}
                        <TypeAnimation
                          sequence={[
                            "Charger les documents",
                            1000,
                            // () => {
                            //   console.log('Sequence completed');
                            // },
                          ]}
                          wrapper="h3"
                          cursor={false}
                          className='heading text-center'
                        // style={{ fontSize: '2em', display: 'inline-block' }}
                        />
                      </div>
                    </article>

                    {/* <!-- step-2-form --> */}
                    <div className='mb-5'>
                      <UploadComponent onFileUpload={handleFileUpload} />
                    </div>
                    {/* <h2>Uploaded Files</h2>
                                  <ul>
                                      {files.map((file, index) => (
                                          <li key={index}>{file.name}</li>
                                      ))}
                                  </ul> */}
                    <h2>Liste des fichiers à charger</h2>
                    <div className='row mb-3'>

                      <div className='col-md-4'>
                        <p> - Statuts de l’entreprise</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Registre de commerce</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Procès-verbaux d’assemblées générales de constitution</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Délibérations du conseil d’administration ou du directoire</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Délégation de pouvoirs</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Recueil de signatures autorisées, Pour le(s) représentant(s) de la personne morale : Tous documents justifiant de l’identité du (des) représentant(s) </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Carte nationale d’identité (recto et du verso), • ou carte de séjour, • ou carte de résident, • ou passeport (4 premières pages).  </p>
                      </div>
                      <div className='col-md-4'>
                        <p> - photos d’identité</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Le relevé d’identité bancaire (RIB) de la personne morale</p>
                      </div>

                      <div className='col-md-4'>
                        <p> - La documentation juridique de la banque (Statuts juridiques, RCCM, agrément, actionnariat, pacte d’actionnaire si applicable ...)</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Le Profil et CV des actionnaires y compris les copies de leurs passeports ;</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Le Management de la banque, le profil des principaux responsables et copies de leurs pièces d’identité ou passeports ;</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Organigramme détaillé ;</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - La copie des manuels, politiques et procédures qui régissent l’activité de la banque (crédit, Gestion du risque, gestion de la liquidité, gestion environnementale, AML, Trade finance….) ;</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Le plan stratégique de la banque ou tout autre document équivalent</p>
                      </div>
                      <div className='col-md-4'>
                        <p> - Une note d’informations détaillée sur la ligne de crédit sollicitée (objet, objectifs, pipeline indicatif global, répartition par secteur cible et justification)</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Plan d'affaires / Business plan de la banque incluant (i) la base de préparation, (ii) les hypothèses prises en compte et (iii) les indicateurs financiers et lles projections financières sur au moins 03 ans.</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Comptes audités au format PCB au 31/12/2020, 31/12/2021, 31/12/2022 et au 30/06/2023</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Balances générales PCB au 31/12/2015, 31/12/2016, 31/12/2017 et période 2018 la plus récente</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Etats DEC - Etats financiers communiqués à la Commission Bancaire au cours de la période 2015, 2016, 2017, 2018</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Rapports généraux, spéciaux et sur le contrôle interne des commissaires aux comptes au 331/12/2020, 31/12/2021, 31/12/2022 et rapport d'examen limité au 30/06/2023</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Les rapports annuels  lors de la période 2020, 2021 et 2022</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Rapports transmis à la Commission Bancaire tels que définis par les articles 34, 35 et 36 de la circulaire n° 003-2011/CB/C relative à l'organisation du système de contrôle interne des établissements de crédit de l'UMOA, lors de la période 2020, 2021 et 2022</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Rapports d'audit interne et de contrôle interne au cours de la période 2015, 2016, 2017, 2018</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Les rapports internes 2020, 2021 et 2022 sur la revue globale du portefeuille</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Rapports d'inspection de la commission bancaire au cours de la période 2020, 2021 et 2022</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Ratios prudentiels Commission Bancaire (FODEP) au 31/12/2020, 31/12/2021, 31/12/2022 et au 30/06/2023</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Les différents rapports AML annuels des exercices 2020, 2021 et 2022</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Détail du portefeuille de titres détenus par la banque à date avec leur échéancier</p>
                      </div>
                      <div className='col-md-4'>
                        <p> -Etat d'inventaire valorisé des actifs corporels et incoporels détenus par la banque au 31/12/2020, 31/12/2021, 31/12/2022 et au 30/06/2023</p>
                      </div>
                    </div>
                    {/* <!-- next previous button --> */}
                    <div className="next-prev-btn">
                      {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                      <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Soumettre</button>
                    </div>
                  </div>
                </div>
              </div></> : null
          }
        </section>
      )}
    </div>
  );
}
