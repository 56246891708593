import React, { useState } from 'react';

import { toast, Slide } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { TypeAnimation } from 'react-type-animation';

import Sibebar from '../../../components/signup/sidebar/sibebar';
import { ProgressBar } from '../progressbar/progressbar';
import { isNotEmptyStringWithoutSpaces } from '../../../helpers/validate';


export function Industry({ onBack, onNext }) {


  const [step, setStep] = useState(1);
  const totalSteps = 7;
  const progress = (step / totalSteps) * 100;

  const [domaineActivite, setDomaineActivite] = useState("");
  const [autreDomaineActivite, setAutreDomaineActivite] = useState("");
  const [stadeProjet, setStadeProjet] = useState("");
  const [capaciteProduction, setCapaciteProduction] = useState("");
  const [principauxFournisseurs, setPrincipauxFournisseurs] = useState("");
  const [items, setItems] = useState([{ name: "" }]);
  const [planGestion, setPlanGestion] = useState("");
  const [marcheCible, setMarcheCible] = useState("");
  const [certificationIndustriel, setCertificationIndustriel] = useState("");


  const sendToasError = (message, time) => {
    toast.error(message, {
      position: "top-right",
      autoClose: time,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Slide,
    });
  }

  const handleAddItem = () => {
    setItems([...items, { name: "" }]);
  };

  const handleDeleteItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const handleInputsChange = (index, event) => {
    const newItems = [...items];
    newItems[index].name = event.target.value;
    setItems(newItems);
  };

  const nextStep = () => {
    if (step === 1) {
      if (isNotEmptyStringWithoutSpaces(domaineActivite)) {
        if (domaineActivite === 'secteur_autre') {
          if (!isNotEmptyStringWithoutSpaces(autreDomaineActivite)) {
            return sendToasError("Saisir le domaine d'activité", 3500)
          }
          setStep(step + 1)
        } else {
          setStep(step + 1);
        }
      } else {
        sendToasError("Veuillez selectionner", 3500);
        // setStep(step + 1)
      }
    }
    else if (step === 2) {
      if (!isNotEmptyStringWithoutSpaces(stadeProjet)) {
        return sendToasError("Veuillez selectionner", 3500);
      }
      setStep(step + 1)
    }
    else if (step === 3) {
      if (!isNotEmptyStringWithoutSpaces(capaciteProduction)) {
        return sendToasError("Veuillez selectionner", 3500);
      }
      setStep(step + 1)
    }
    else if (step === 4) {

      if (isNotEmptyStringWithoutSpaces(principauxFournisseurs)) {
        if (principauxFournisseurs === 'principaux_fournisseur_non') {
          setStep(step + 1)
        }
        if (principauxFournisseurs === 'principaux_fournisseur_oui') {
          if (!isNotEmptyStringWithoutSpaces(items[0].name)) {
            sendToasError("Veuillez saisir un champ*", 3500)
          }
          else {
            setStep(step + 1)
          }
        }
      } else {
        sendToasError("Veuillez selectionner", 3500);
      }
    }
    else if (step === 5) {
      if (!isNotEmptyStringWithoutSpaces(planGestion)) {
        return sendToasError("Veuillez selectionner", 3500);
      }
      setStep(step + 1)
    }
    else if (step === 6) {
      if (!isNotEmptyStringWithoutSpaces(marcheCible)) {
        return sendToasError("Veuillez selectionner", 3500);
      }
      setStep(step + 1)
    }
    else if (step === 7) {
      if (!isNotEmptyStringWithoutSpaces(certificationIndustriel)) {
        return sendToasError("Veuillez selectionner", 3500);
      }
      onNext();
    }

  }

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  }

  const handleDomaineActivite = e => {
    setDomaineActivite(e.target.value)
    if (e.target.value !== 'secteur_autre') {
      setStep(step + 1)
    }
  }
  const handleStadeProjet = e => {
    setStadeProjet(e.target.value)
    setStep(step + 1)
  }
  const handleFournisseurPrincipaux = e => {
    setPrincipauxFournisseurs(e.target.value)
    if (e.target.value === 'principaux_fournisseur_non') {
      setStep(step + 1)
    }
  }
  const handlePlanDeGestion = e => {
    setPlanGestion(e.target.value)
    setStep(step + 1)
  }
  const handleMarcheCible = e => {
    setMarcheCible(e.target.value)
    setStep(step + 1)
  }
  const handleCertificationIndustriel = e => {
    setCertificationIndustriel(e.target.value)
    onNext();
  }

  return (
    <div style={{ overflow: 'clip' }}>
      {step === 1 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={onBack}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Domaine d'Activité",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={domaineActivite} onChange={handleDomaineActivite}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="secteur_auto">Automobile</option>
                                <option value="secteur_cosmetique">Cosmétique</option>
                                <option value="secteur_textile">Textile</option>
                                <option value="secteur_metalurgie">Métallurgie</option>
                                <option value="secteur_agro_industrie">Agro Industrie</option>
                                <option value="secteur_agro_pharmaceutique">Pharmaceutique</option>
                                <option value="secteur_sante">Santé</option>
                                <option value="secteur_raffinerie">Raffinerie</option>
                                <option value="secteur_energie">Energie</option>
                                <option value="secteur_grande_distribution">Grande distribution</option>
                                <option value="secteur_autre">Autres</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                        {domaineActivite === "secteur_autre" &&
                          <>
                            <div className='col-md-12'>
                              <h3 className='mb-3 heading'>Saisir le domaine d'activité</h3>
                              <div class="select-field">
                                <input type="text" placeholder="Saisir le domaine d'activité" value={autreDomaineActivite} onChange={e => setAutreDomaineActivite(e.target.value)} />
                              </div>
                            </div>
                            <div className="next-prev-btn">
                              {/* <button type="button" className="prev" onClick={onBack}>Précédent</button> */}
                              <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                            </div>
                          </>
                        }
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={onBack}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 2 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Quel est le stade actuel de votre projet industriel ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={stadeProjet} onChange={handleStadeProjet}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Idée/Concept</option>
                                <option value="usage_centre_commercial">Études de marché</option>
                                <option value="usage_logement">Conception et prototypage</option>
                                <option value="usage_entrepot">Construction</option>
                                <option value="usage_atelier">Production pilote</option>
                                <option value="usage_loisir">Production à grande échelle</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 3 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Quelle est la capacité de production pour votre projet industriel ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <input type="text" placeholder="différentes unités de sélection" value={capaciteProduction} onChange={e => setCapaciteProduction(e.target.value)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  <div className="next-prev-btn">
                    {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div>
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 4 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Avez-vous identifié vos principaux fournisseurs de matières premières ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={principauxFournisseurs} onChange={handleFournisseurPrincipaux}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="principaux_fournisseur_oui">Oui</option>
                                <option value="principaux_fournisseur_non">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                        {principauxFournisseurs === 'principaux_fournisseur_oui' && <div className='col-md-12'>
                          <div >
                            <h3 className='text-center mb-3'>Sasir la liste des fournisseurs</h3>
                            {/* Afficher tous les éléments de la liste */}
                            {items.map((item, index) => (
                              <div key={index} className="form-group">
                                <div className="row align-items-center">
                                  <div className="col-md-10">
                                    <div className='select-field'>
                                      <input key={index} type="text" placeholder="Saisir le nom" value={item.name} onChange={e => handleInputsChange(index, e)} />
                                    </div>
                                  </div>
                                  {items.length > 1 && (
                                    <div className='col-md-2'>
                                      <div className='container'>
                                        <FontAwesomeIcon icon={faMinus} onClick={() => handleDeleteItem(index)} className='cursor-pointer p-1' style={{ cursor: "pointer", border: "2px solid red", borderRadius: "50px", color: "red" }} />
                                      </div>

                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                            <div className='container'>
                              <FontAwesomeIcon icon={faPlus} onClick={handleAddItem} className='cursor-pointer p-1' style={{ cursor: "pointer", border: "2px solid #8e736a", borderRadius: "50px", color: "#8e736a" }} />
                            </div>
                          </div>
                          <div className="next-prev-btn">
                            {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                            <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                          </div>

                        </div>}

                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 5 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Disposez-vous d'un plan de gestion de la qualité ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={planGestion} onChange={handlePlanDeGestion}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Oui</option>
                                <option value="usage_bureau_">En cours d'élaboration</option>
                                <option value="usage_centre_commercial">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 6 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Quels sont les principaux marchés cibles pour vos produits ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={marcheCible} onChange={handleMarcheCible}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">B2B</option>
                                <option value="usage_bureau">B2C</option>
                                <option value="usage_centre_commercial">B2B2C</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 7 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Avez-vous obtenu les permis et certifications industrielles requises ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={certificationIndustriel} onChange={handleCertificationIndustriel}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Oui</option>
                                <option value="usage_bureau">En cours d'obtention</option>
                                <option value="usage_centre_commercial">Non, pas encore demandé</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}

    </div>
  )
}
