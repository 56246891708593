import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import './upload.css';

import ImgVector from '../../../assets/images/img-vector.png';

const UploadComponent = ({ onFileUpload }) => {

    const [files, setFiles] = useState([]);
    const onDrop = useCallback((acceptedFiles) => {
        const newFiles = [...files, ...acceptedFiles];
        setFiles(newFiles);
        onFileUpload(newFiles);
    }, [files, onFileUpload]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: true
    });

    return (
        <div {...getRootProps()} className="upload-field">
            <input {...getInputProps()} />
            <div className="file-upload-area">
                <label className="upload-label">
                    <img src={ImgVector} alt="vector" className="image" />
                    <span className="filename">
                        {isDragActive ? (
                            <span>Deposer vos fichiers ici...</span>
                        ) : (
                            <span>
                                Deposer vos fichiers ici, ou <span className="headupload">explorer</span>
                            </span>
                        )}
                    </span>
                    <span className="textupload">Supports all file types</span>
                </label>
            </div>
            <div className="uploaded-files">
                {/* <h3>Uploaded Files</h3> */}
                <ul>
                    {files.map((file, index) => (
                        <li key={index}>{file.name}</li>
                    ))}
                </ul>
            </div>
        </div>

    );
};

export default UploadComponent;
