import React, { useState } from 'react'

import { toast, Slide } from 'react-toastify';
import { TypeAnimation } from 'react-type-animation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import Sibebar from '../../../components/signup/sidebar/sibebar';
import { ProgressBar } from '../progressbar/progressbar';

import { isNotEmptyStringWithoutSpaces } from '../../../helpers/validate';


export function Tourisme({ onBack, onNext }) {

  const [step, setStep] = useState(1);
  const totalSteps = 6;
  const progress = (step / totalSteps) * 100;


  const [stadeProjet, setStadeProjet] = useState("");
  const [capaciteAccueil, setCapaciteAccueil] = useState("");
  const [budgetTotal, setBudgetTotal] = useState("");
  const [typeClientele, setTypeClientele] = useState("");
  const [localisationSite, setLocalisationSite] = useState("");
  const [licenceEtPermis, setLicenceEtPermis] = useState("");

  const sendToasError = (message, time) => {
    toast.error(message, {
      position: "top-right",
      autoClose: time,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Slide,
    });
  }

  const nextStep = () => {
    if (step === 1) {
      if (!isNotEmptyStringWithoutSpaces(stadeProjet)) {
        return sendToasError("Faites votre choix", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 2) {
      if (!isNotEmptyStringWithoutSpaces(capaciteAccueil)) {
        return sendToasError("Faites votre choix", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 3) {
      if (!isNotEmptyStringWithoutSpaces(budgetTotal)) {
        return sendToasError("Faites votre choix", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 4) {
      if (!isNotEmptyStringWithoutSpaces(typeClientele)) {
        return sendToasError("Faites votre choix", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 5) {
      if (!isNotEmptyStringWithoutSpaces(localisationSite)) {
        return sendToasError("Saisir le champ", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 6) {
      if (!isNotEmptyStringWithoutSpaces(licenceEtPermis)) {
        return sendToasError("Faites votre choix", 3500)
      }
      onNext()
    }
  }

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  }

  const handleStadeProjet = e => {
    setStadeProjet(e.target.value)
    setStep(step + 1)
  }
  const handleCapaciteAccueil = e => {
    setCapaciteAccueil(e.target.value)
    setStep(step + 1)
  }
  const handleBudgetTotal = e => {
    setBudgetTotal(e.target.value)
    setStep(step + 1)
  }
  const handleTypeClientele = e => {
    setTypeClientele(e.target.value)
    setStep(step + 1)
  }
  const handleLicenceEtPermis = e => {
    setLicenceEtPermis(e.target.value)
    onNext()
  }

  return (
    <div style={{ overflow: 'clip' }}>
      {step === 1 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={onBack}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "À quel stade se trouve votre projet d'hôtellerie ou de tourisme ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={stadeProjet} onChange={handleStadeProjet}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Étude de marché</option>
                                <option value="usage_centre_commercial">Acquisition de site</option>
                                <option value="usage_logement">Conception et planification</option>
                                <option value="usage_entrepot">Construction sans calendrier des travaux</option>
                                <option value="usage_atelier">Construction avec calendrier des travaux</option>
                                <option value="usage_tech">Pré-ouverture</option>
                                <option value="usage_green">Ouvert au public</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={onBack}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 2 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Quelle est la capacité d'accueil prévue de votre établissement ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={capaciteAccueil} onChange={handleCapaciteAccueil}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Moins de 50 chambres</option>
                                <option value="usage_centre_commercial">50 - 100 chambres</option>
                                <option value="usage_logement">100 - 200 chambres</option>
                                <option value="usage_entrepot">Plus de 200 chambres</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 3 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Quel est le budget total estimé pour votre projet ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={budgetTotal} onChange={handleBudgetTotal}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Moins de 200 000 000 F.CFA</option>
                                <option value="usage_centre_commercial">200 000 000 F.CFA - 500 000 000 F.CFA</option>
                                <option value="usage_logement">500 000 000 F.CFA - 1 000 000 000 F.CFA</option>
                                <option value="usage_entrepot">Plus de 1 000 000 000 F.CFA</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 4 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Quel type de clientèle ciblez-vous ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={typeClientele} onChange={handleTypeClientele}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Touristes</option>
                                <option value="usage_centre_commercial">Voyageurs d'affaires</option>
                                <option value="usage_logement">Groupes et événements</option>
                                <option value="usage_entrepot">Autre</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 5 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Veuillez renseigner la localisation du site",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <input type="text" placeholder="addresse" value={localisationSite} onChange={e => setLocalisationSite(e.target.value)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  <div className="next-prev-btn">
                    {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div>
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 6 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Avez-vous obtenu les licences et permis nécessaires ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={licenceEtPermis} onChange={handleLicenceEtPermis}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Oui</option>
                                <option value="usage_centre_commercial">En cours d'obtention</option>
                                <option value="usage_logement">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
    </div>
  )
}
