import React, { useState } from 'react';

import { toast, Slide } from 'react-toastify';
import { TypeAnimation } from 'react-type-animation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import Sibebar from '../../../components/signup/sidebar/sibebar';
import { ProgressBar } from '../progressbar/progressbar';

import { isNotEmptyStringWithoutSpaces } from '../../../helpers/validate';

export function Tech({ onBack, onNext }) {


  const [step, setStep] = useState(1);
  const totalSteps = 9;
  const progress = (step / totalSteps) * 100;

  const [domaineActivite, setDomaineActivite] = useState("");
  const [stadeProjet, setStadeProjet] = useState("");
  const [principauxMarche, setPrincipauxMarche] = useState("");
  const [mvp, setMvp] = useState("");
  const [prototype, setPrototype] = useState("");
  const [testUtilisateur, setTestUtilisateur] = useState("");
  const [planMarche, setPlanMarche] = useState("");
  const [modelRevenu, setModelRevenu] = useState("");
  const [proprieteIntelectuelle, setProprieteIntelectuelle] = useState("");

  const sendToasError = (message, time) => {
    toast.error(message, {
      position: "top-right",
      autoClose: time,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Slide,
    });
  }


  const nextStep = () => {
    if (step === 1) {
      if (!isNotEmptyStringWithoutSpaces(domaineActivite)) {
        return sendToasError("Faites votre choix", 3500)
      }
      setStep(step + 1);
    } else if (step === 2) {
      if (!isNotEmptyStringWithoutSpaces(stadeProjet)) {
        return sendToasError("Faites votre choix", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 3) {
      if (!isNotEmptyStringWithoutSpaces(principauxMarche)) {
        return sendToasError("Faites votre choix", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 4) {
      if (!isNotEmptyStringWithoutSpaces(mvp)) {
        return sendToasError("Faites votre choix", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 5) {
      if (!isNotEmptyStringWithoutSpaces(prototype)) {
        return sendToasError("Faites votre choix", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 6) {
      if (!isNotEmptyStringWithoutSpaces(testUtilisateur)) {
        return sendToasError("Faites votre choix", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 7) {
      if (!isNotEmptyStringWithoutSpaces(planMarche)) {
        return sendToasError("Faites votre choix", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 8) {
      if (!isNotEmptyStringWithoutSpaces(modelRevenu)) {
        return sendToasError("Faites votre choix", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 9) {
      if (!isNotEmptyStringWithoutSpaces(proprieteIntelectuelle)) {
        return sendToasError("Faites votre choix", 3500)
      }
      onNext()
    }
  }

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  }

  const handleDomaineActivite = e => {
    setDomaineActivite(e.target.value)
    setStep(step + 1)
  }
  const handleStadeProjet = e => {
    setStadeProjet(e.target.value)
    setStep(step + 1)
  }
  const handlePrincipauxMarche = e => {
    setPrincipauxMarche(e.target.value)
    setStep(step + 1)
  }
  const handleMvp = e => {
    setMvp(e.target.value)
    setStep(step + 1)
  }
  const handlePrototype = e => {
    setPrototype(e.target.value)
    setStep(step + 1)
  }
  const handleTestUtilisateur = e => {
    setTestUtilisateur(e.target.value)
    setStep(step + 1)
  }
  const handlePlanMarcher = e => {
    setPlanMarche(e.target.value)
    setStep(step + 1)
  }
  const handleModelRevenu = e => {
    setModelRevenu(e.target.value)
    setStep(step + 1)
  }
  const handleProprieteIntelectuelle = e => {
    setProprieteIntelectuelle(e.target.value)
    onNext()
  }


  return (
    <div style={{ overflow: 'clip' }}>
      {step === 1 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={onBack}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Quel est le domaine d'activité technologique ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={domaineActivite} onChange={handleDomaineActivite}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Idée/Concept</option>
                                <option value="usage_centre_commercial">PropTech</option>
                                <option value="usage_logement">FinTech</option>
                                <option value="usage_entrepot">AgriTech</option>
                                <option value="usage_atelier">EduTech</option>
                                <option value="usage_tech">LogiTech</option>
                                <option value="usage_green">GreenTech</option>
                                <option value="usage_sante">E-santé</option>
                                <option value="usage_realtech">RetailTech</option>
                                <option value="usage_st">Services Technologie (Cybersecurity, Data, etc)</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={onBack}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 2 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Quel est le stade actuel de votre projet technnique ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />

                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={stadeProjet} onChange={handleStadeProjet}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Idée/Concept</option>
                                <option value="usage_centre_commercial">Études de marché</option>
                                <option value="usage_logement">Conception et prototypage</option>
                                <option value="usage_entrepot">Construction</option>
                                <option value="usage_atelier">Production pilote</option>
                                <option value="usage_loisir">Production à grande échelle</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 3 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Quels sont les principaux marchés cibles pour vos produits ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={principauxMarche} onChange={handlePrincipauxMarche}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">B2B</option>
                                <option value="usage_centre_commercial">B2C</option>
                                <option value="usage_logement">B2B2C</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 4 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Disposez-vous d'un MVP (Minimum Viable Product) ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />

                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={mvp} onChange={handleMvp}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Oui</option>
                                <option value="usage_centre_commercial">En cours de développement</option>
                                <option value="usage_logement">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 5 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Avez-vous développé un prototype fonctionnel ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={prototype} onChange={handlePrototype}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Oui</option>
                                <option value="usage_centre_commercial">En cours de développement</option>
                                <option value="usage_logement">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 6 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Avez-vous réalisé des tests utilisateurs pour votre technologie ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={testUtilisateur} onChange={handleTestUtilisateur}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Oui</option>
                                <option value="usage_centre_commercial">En cours</option>
                                <option value="usage_logement">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 7 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Disposez-vous d'un plan de mise sur le marché pour votre technologie ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>
                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={planMarche} onChange={handlePlanMarcher}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Oui</option>
                                <option value="usage_centre_commercial">En cours de préparation</option>
                                <option value="usage_logement">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 8 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Quel est le modèle de revenu pour votre technologie ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={modelRevenu} onChange={handleModelRevenu}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Vente directe</option>
                                <option value="usage_centre_commercial">Abonnement</option>
                                <option value="usage_logement">Freemium</option>
                                <option value="usage_pub">Publicité</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 9 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Disposez vous d'une propriété intellectuelle ?",
                          1000,
                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={proprieteIntelectuelle} onChange={handleProprieteIntelectuelle}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Brevet</option>
                                <option value="usage_centre_commercial">Marque déposée</option>
                                <option value="usage_copy">Copyright</option>
                                <option value="usage_secret">Secret commercial</option>
                                <option value="usage_pas_encore">Pas encore</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
    </div>
  )
}
