export const validateEmail = (emailAdress) => {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (emailAdress.match(regexEmail)){
        return true; 
    }else{
        return false; 
    }
}


export function isNotEmptyStringWithoutSpaces(str){
    return str?.trim() !== '' && str?.trim().length !== 0;
}
