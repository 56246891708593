import React from 'react';
import './progressbar.css';

export function ProgressBar({ progress }) {
    return (
      <div className="progress-bar">
        <div className="progress-bar-inner" style={{ width: `${progress}%` }}></div>
      </div>
    );
  }
