import React, {useState} from 'react';

import { Link, useHistory } from 'react-router-dom';

import Sibebar from '../../components/signup/sidebar/sibebar';

import Service1 from '../../assets/images/service_icons/service-1.png'
import Service2 from '../../assets/images/service_icons/service-2.png'
import Service3 from '../../assets/images/service_icons/service-3.png'


export default function SignUp() {
    const history = useHistory();

	const [selectedPorteur, setSelectedPorteur] = useState(false);
	const [selectedPrivateInvest, setSelectedPrivateInvest] = useState(false);
	const [selectedInstitutionalInvest, setSelectedInstitutionalInvest] = useState(false);

	const handlePorteur = () => {
		setSelectedPorteur(true);
		setSelectedPrivateInvest(false);
		setSelectedInstitutionalInvest(false);
		history.push('/porteurprojet')
	}
	const handlePrivateInvest = () => {
		setSelectedPrivateInvest(true);
		setSelectedPorteur(false);
		setSelectedInstitutionalInvest(false);
		history.push('/investisseurprive')
	}
	const handleInstitutionalInvest = () => {
		setSelectedInstitutionalInvest(true);
		setSelectedPorteur(false);
		setSelectedPrivateInvest(false);
		history.push('/investisseurinstitutionel')
	}

	// const nextStep = () => {
	// 	if(selectedPorteur){
	// 		history.push('/porteurprojet')
	// 	}
	// 	else if(selectedPrivateInvest){
	// 		history.push('/investisseurprive')
	// 	}
	// 	else if(selectedInstitutionalInvest){
	// 		history.push('/investisseurinstitutionel')
	// 	}else{
	// 		return;
	// 	}
	// }

  return (
    <div style={{overflow: 'clip'}}>
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
            {/* <!-- step-1 --> */}
			<section class="steps row">
                {/* <!-- step-1-sidebar --> */}
				<Sibebar/>
				<div class="step-1 tab-sm-100 col-lg-9 col-md-8">
					<div class="step-inner">
						<div class="p-5">
							<div class="step-heading">
								<div class="heading-marker"></div>
								<div class="heading">Type de compte</div> 
							</div>
	
							{/* <!-- step-1 form--> */}
							<div class="pop-right step-form" id="step1">
								<div class="row justify-content-center">
									<div class="tab-50 col-md-4" onClick={() => handlePorteur()}>
										<div class="radio-field p-5">
											<div class="icon mx-auto">
												<img src={Service1} alt="service 1"/>
											</div>
											<div class="service-text">
												Porteur de projet
											</div>
											<input class="gradiant_border"  type="radio" name="services" value="corporate service" checked={selectedPorteur}  />
										</div>
									</div>
									<div class="tab-50 col-md-4" onClick={() => handlePrivateInvest()}>
										<div class="radio-field p-5">
											<div class="icon mx-auto">
												<img src={Service2} alt="service 2"/>
											</div>
											<div class="service-text">
                                                Investisseur privé
											</div>
											<input class="gradiant_border"  type="radio" name="services" value="freelancing services" checked={selectedPrivateInvest}  />
										</div>
									</div>
									<div class="tab-50 col-md-4" onClick={() => handleInstitutionalInvest() }>
										<div class="radio-field p-5">
											<div class="icon mx-auto">
												<img src={Service3} alt="service 3"/>
											</div>
											<div class="service-text">
                                                Investisseur institutionnel
											</div>
											<input class="gradiant_border" type="radio" name="services" value="development" checked={selectedInstitutionalInvest}/>
										</div>
									</div>
								</div>
							</div>
	
							{/* <!-- next button--> */}
							{/* <div class="next-prev-btn">
								<button type="button" id="step1btn" style={{backgroundImage: (selectedInstitutionalInvest || selectedPorteur || selectedPrivateInvest) ? 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' : ''}} onClick={() => nextStep()}>Continuer</button>
							</div> */}
						</div>
					</div>
					{/* <div class="steps-counter">
						<span>Step: 1/5</span>
						<div class="step-counter-inner">
							<div class="a0 step-move"></div>
						</div>
					</div> */}
				</div>
			</section>
	
		</form>
    </div>
  )
}
