import React, { useState } from 'react';

import { toast, Slide } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { TypeAnimation } from 'react-type-animation';

import Sibebar from '../../../components/signup/sidebar/sibebar';
import { ProgressBar } from '../progressbar/progressbar';

import { isNotEmptyStringWithoutSpaces } from '../../../helpers/validate';

import Service1 from '../../../assets/images/service_icons/service-1.png'
import Service2 from '../../../assets/images/service_icons/service-2.png'
import Service3 from '../../../assets/images/service_icons/service-3.png'

export function Btp({ onBack, onNext, onDataChange }) {

  const [step, setStep] = useState(1);
  const totalSteps = 23;
  const progress = (step / totalSteps) * 100;


  const [secteurActivite, setSecteurActivite] = useState("");
  // IMMOBILIER STATE
  const [selectedImmobilier, setSelectedImmobilier] = useState(false);
  const [usageImmobilier, setUsageImmobilier] = useState("");
  const [budgetTotalBtp, setBudgetTotalBtp] = useState("");
  const [precommercialisation, setPrecommercialisation] = useState("");
  const [siteAdress, setSiteAdress] = useState("");
  const [siteSuperficie, setSiteSuperficie] = useState("");
  const [valeurSite, setValeurSite] = useState("");
  const [studyNames, setStudyNames] = useState("");
  const [numberProject, setNumberProject] = useState("")
  const [montantFinancement, setMontantFinancement] = useState("")
  const [typeFinancement, setTypeFinancement] = useState("");
  const [calendar, setCalendar] = useState("");
  const [businessPlan, setBusinessPlan] = useState("");
  const [planTresorerie, setPlanTresorerie] = useState("");
  const [budgetTotalBtp2, setBudgetTotalBtp2] = useState("");
  const [percentageInvest, setPercentageInvest] = useState("");
  const [investAmount, setInvestAmount] = useState("");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedStudy, setSelectedStudy] = useState("");
  const [selectedFinancement, setSelectedFinancement] = useState("");
  const [selectedSubscriber, setSelectedSubscriber] = useState("");
  const [subscriberNumber, setSubscriberNumber] = useState("");
  const [subscriber, setSubscriber] = useState([]);
  const [investedIn, setInvestedIn] = useState("");
  const [items, setItems] = useState([{ name: "" }]);
  const [haveAbe, setHaveAbe] = useState("");
  // END IMMOBILIER STATE


  // INFRASTRUCTURE STATE
  const [selectedInfrastructure, setSelectedInfrastructure] = useState(false);
  const [typeDeMarcher, setTypeDeMarcher] = useState("");
  const [secteur, setSecteur] = useState("");
  const [bonDeCommande, setBonDeCommande] = useState("");
  const [lettreAttribution, setLettreAttribution] = useState("");
  const [contractCommerciaux, setContratCommerciaux] = useState("");
  const [contratClientNames, setContratClientNames] = useState([{ name: "" }]);
  const [montantContratAFinancer, setMontantContratAFinancer] = useState("");
  const [contratDeConcessionBot, setContratDeConcessionBot] = useState("");
  // END INFRASTUCTURE STATE

  const [formData, setFormData] = useState({});


  const handleSubmit = () => {
    const data = {
      btpSecteurActivite: secteurActivite,
    }
    // console.log('data==', data)
    // setFormData(data);
    onDataChange(data);
    onNext();
  };

  const sendToasError = (message, time) => {
    toast.error(message, {
      position: "top-right",
      autoClose: time,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Slide,
    });
  }

  const categories = ['Recherche de terrain', 'Acquisition de terrain', 'ACD en cours', 'ACD en délivré', 'Etude technique', 'Etude architecturale', 'DQE', 'Obtention de permis de construire', 'Début des travaux de construction', 'Phase de finition', 'Autres']

  const handleInfrastructure = () => {
    setSelectedInfrastructure(true);
    setSelectedImmobilier(false);
    setSecteurActivite('btp_infrastructure')
    setStep(step + 1)
  }

  const handleImmobilier = () => {
    setSelectedImmobilier(true);
    setSelectedInfrastructure(false);
    setSecteurActivite('btp_immobilier')
    setStep(step + 1)
  }

  const handleAddItem = () => {
    setItems([...items, { name: "" }]);
  };

  const handleDeleteItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const handleInputsChange = (index, event) => {
    const newItems = [...items];
    newItems[index].name = event.target.value;
    setItems(newItems);
  };

  const handleAddContractClients = () => {
    setContratClientNames([...contratClientNames, { name: "" }]);
  };

  const handleDeleteContractClients = (index) => {
    const updatedItems = [...contratClientNames];
    updatedItems.splice(index, 1);
    setContratClientNames(updatedItems);
  };

  const handleInputsContractClients = (index, event) => {
    const newItems = [...contratClientNames];
    newItems[index].name = event.target.value;
    setContratClientNames(newItems);
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedCategory([...selectedCategory, value]);
    } else {
      setSelectedCategory(selectedCategory.filter((category) => category !== value));
    }
  };

  // const handleAddPartner = () => {
  //   setAbePartners([...abePartners, {}]);
  // };

  // const handleDeletePartner = (index) => {
  //   const updatedItems = [...abePartners];
  //   updatedItems.splice(index, 1);
  //   setAbePartners(updatedItems);
  // };

  const nextStep = () => {
    if (step === 1) {
      if (selectedImmobilier || selectedInfrastructure) {
        if (step < totalSteps) {
          setStep(step + 1);
        }
      }
    }
    else if (step === 2) {
      setTypeDeMarcher("");
      if (selectedImmobilier) {
        if (!isNotEmptyStringWithoutSpaces(usageImmobilier)) {
          return sendToasError("Veuillez sélectionner le champ", 3500);
        }
        setStep(step + 6);
      }
      if (selectedInfrastructure) {
        if (!isNotEmptyStringWithoutSpaces(secteur)) {
          return sendToasError("Veuillez faire une selection", 3500);
        }
        setStep(step + 1);
      }
    }
    else if (step === 3) {
      // if (!isNotEmptyStringWithoutSpaces(typeDeMarcher)) {
      //   return sendToasError('Veuillez sélectionner un champ', 3500)
      // }
      // setStep(step + 1);
    }
    else if (step === 4) {
      if (!isNotEmptyStringWithoutSpaces(bonDeCommande)) {
        return sendToasError('Veuillez sélectionner un champ', 3500)
      }
      setStep(step + 1);
    }
    else if (step === 5) {
      if (!isNotEmptyStringWithoutSpaces(lettreAttribution)) {
        return sendToasError("Veuillez faire une sélection", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 6) {
      // setStep(step + 1);
    }
    else if (step === 7) {
      setStep(step + 1);
    }
    else if (step === 8) {

      if (selectedCategory.length === 0) {
        return sendToasError('Veuillez sélectionnez au moins un élément*', 3500)
      }

      setStep(step + 1);
    }
    else if (step === 9) {
      if (!isNotEmptyStringWithoutSpaces(budgetTotalBtp)) {
        return sendToasError("Veuillez remplir un champ*", 3500)
      }

      setStep(step + 1);
    }
    else if (step === 10) {
      if (!isNotEmptyStringWithoutSpaces(precommercialisation)) {
        return sendToasError('Veuillez sélectionner un champ*', 3500)
      }
      setStep(step + 1);
    }
    else if (step === 11) {
      if (!isNotEmptyStringWithoutSpaces(siteAdress)) {
        return sendToasError("Veuillez saisir le champ*", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 12) {
      if (!isNotEmptyStringWithoutSpaces(siteSuperficie)) {
        return sendToasError('Veuillez saisir le champ*', 3500)
      }
      setStep(step + 1);
    }
    else if (step === 13) {
      if (!isNotEmptyStringWithoutSpaces(valeurSite)) {
        return sendToasError("Veuillez saisir le champ*", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 14) {
      if (isNotEmptyStringWithoutSpaces(selectedStudy)) {
        if (selectedStudy === "study_non") {
          setStep(step + 1);
        }
        if (selectedStudy === 'study_oui') {
          if (!isNotEmptyStringWithoutSpaces(items[0].name) || !isNotEmptyStringWithoutSpaces(studyNames)) {
            sendToasError("Veuillez saisir un champ*", 3500)
          }
          else {
            setStep(step + 1)
          }
        }
      } else {
        sendToasError("Veuillez saisir le champ*", 3500)
      }
    }
    else if (step === 15) {
      if (!isNotEmptyStringWithoutSpaces(numberProject)) {
        return sendToasError("Veuillez selectionner le champ*")
      }
      setStep(step + 1);
    }
    else if (step === 16) {
      if (isNotEmptyStringWithoutSpaces(selectedFinancement)) {
        if (selectedFinancement === "financement_non") {
          setStep(step + 1);
        }
        if (selectedFinancement === "financement_oui") {
          if (!isNotEmptyStringWithoutSpaces(montantFinancement) || !isNotEmptyStringWithoutSpaces(typeFinancement)) {
            sendToasError("Veuillez selectionner le champ*", 3500)
          } else {
            setStep(step + 1);
          }
        }
      } else {
        sendToasError("Veuillez selectionner le champ*", 3500);
      }
    }
    else if (step === 17) {

      if (!isNotEmptyStringWithoutSpaces(calendar)) {
        return sendToasError('Veuillez selectionner le champ*', 3500);
      }
      setStep(step + 1);
    }
    else if (step === 18) {
      if (isNotEmptyStringWithoutSpaces(selectedSubscriber)) {
        if (selectedSubscriber === "subscriber_non") {
          setStep(step + 1);
        }
        if (selectedSubscriber === "subscriber_oui") {
          if (isNotEmptyStringWithoutSpaces(subscriberNumber)) {
            if (subscriber[0] === "" || subscriber.length === 0) {
              sendToasError("Veuillez saisir le/les champ(s) souscripteur*", 3500)
            } else {
              setStep(step + 1);
            }
          } else {
            sendToasError("Veuillez selectionner le champ nombre souscripteur*", 3500)
          }
        }
      } else {
        sendToasError("Veuillez selectionner le champ*", 3500);
      }
    }
    else if (step === 19) {
      if (!isNotEmptyStringWithoutSpaces(businessPlan)) {
        return sendToasError("Veuillez selectionner le champ*", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 20) {
      if (!isNotEmptyStringWithoutSpaces(planTresorerie)) {
        return sendToasError("Veuillez selectionner le champ*", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 21) {
      if (!isNotEmptyStringWithoutSpaces(budgetTotalBtp2)) {
        return sendToasError("Veuillez selectionner le champ*", 3500)
      }
      setStep(step + 1);
    }
    else if (step === 22) {
      if (isNotEmptyStringWithoutSpaces(investedIn)) {
        if (investedIn === "investin_in_no") {
          if (isNotEmptyStringWithoutSpaces(percentageInvest)) {
            setStep(step + 1);
          } else {
            sendToasError("Veuillez choisir le pourcentage d'investissment", 3500)
          }
        }
        if (investedIn === 'investin_in_yes') {
          if (isNotEmptyStringWithoutSpaces(investAmount)) {
            setStep(step + 1);
          } else {
            sendToasError("Veuillez saisir le montant", 3500);
          }
        }

      } else {
        sendToasError("Veuillez selectionnez", 3500)
      }
    }
    else if (step === 23) {
      if (!isNotEmptyStringWithoutSpaces(haveAbe)) {
        return sendToasError('Veuillez selectionner un element', 3500);
      }
      handleSubmit();

    }
  }

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  }

  const nextStepForTypeMarket = () => {
    if (isNotEmptyStringWithoutSpaces(typeDeMarcher)) {
      if (typeDeMarcher === 'marche_public') {
        setStep(step + 1);
      }
      if (typeDeMarcher === 'marche_ppp') {
        setStep(step + 4);
      }
      if (typeDeMarcher === 'marche_prive') {
        setStep(step + 3);
      }
    } else {
      return sendToasError("Veuillez faire la selection svp", 3500)
    }
  }

  const nextStepForPublicMarket = () => {
    setStep(step + 14)
  }
  const nextStepForPrivateMarket = () => {
    if (isNotEmptyStringWithoutSpaces(contractCommerciaux)) {

      if (contractCommerciaux === "contrat_commerciaux_oui") {
        if (!isNotEmptyStringWithoutSpaces(contratClientNames[0].name) || !isNotEmptyStringWithoutSpaces(montantContratAFinancer)) {
          return sendToasError("veuillez remplir les champs", 3500)
        }
        setStep(step + 13)
      } else {
        setStep(step + 13)
      }

    } else {
      sendToasError("Veuillez faire une sélection", 3500)
    }

    // setStep(step + 13)
  }
  const nextStepForPPPMarket = () => {
    if (!isNotEmptyStringWithoutSpaces(contratDeConcessionBot)) {
      return sendToasError("Veuillez faire un choix", 3500);
    }
    setStep(step + 12);
  }


  const prevStepForPrivateMarket = () => {
    setStep(step - 3)
  }
  const prevStepForPPPMarket = () => {
    setStep(step - 4);
  }

  const prevStepForBusinessPlan = () => {
    if (typeDeMarcher === 'marche_public') {
      setStep(step - 14);
    }
    else if (typeDeMarcher === 'marche_ppp') {
      setStep(step - 12);
    }
    else if (typeDeMarcher === 'marche_prive') {
      setStep(step - 13);
    } else {
      setStep(step - 1);
    }

  }

  const prevStepForImmobilier = () => {
    // console.log('step', step)
    setStep(step - 6)
  }

  const handleGenerateInputs = () => {
    const number = parseInt(subscriberNumber, 10);
    if (number > 0) {
      const newInputValues = Array(number).fill('');
      setSubscriber(newInputValues);
    } else {
      setSubscriber([]);
    }
  };

  const handleInputChange = (index, event) => {
    const newInputValues = [...subscriber];
    newInputValues[index] = event.target.value;
    setSubscriber(newInputValues);
  };

  const handleUsageImmobilier = e => {
    setUsageImmobilier(e.target.value)
    if (selectedImmobilier) {
      // if (!isNotEmptyStringWithoutSpaces(usageImmobilier)) {
      //   return sendToasError("Veuillez sélectionner le champ", 3500);
      // }
      setStep(step + 6);
    }
    if (selectedInfrastructure) {
      // if (!isNotEmptyStringWithoutSpaces(secteur)) {
      //   return sendToasError("Veuillez faire une selection", 3500);
      // }
      setStep(step + 1);
    }
    // setStep(step + 1)
  }

  const handleSecteur = e => {
    setSecteur(e.target.value)
    setStep(step + 1)
  }

  const handleTypeDeMarcher = e => {
    setTypeDeMarcher(e.target.value)

    if (e.target.value === 'marche_public') {
      setStep(step + 1);
    }
    if (e.target.value === 'marche_ppp') {
      setStep(step + 4);
    }
    if (e.target.value === 'marche_prive') {
      setStep(step + 3);
    }
  }

  const handleBonDeCommande = e => {
    setBonDeCommande(e.target.value)
    setStep(step + 1)
  }

  const handleLettreAttribution = e => {
    setLettreAttribution(e.target.value)
    setStep(step + 14)
  }

  const handleContratCommerciaux = e => {
    setContratCommerciaux(e.target.value)
    if (e.target.value !== "contrat_commerciaux_oui") {
      setStep(step + 13)
    }
  }

  const handleContratDeCessessionBot = e => {
    setContratDeConcessionBot(e.target.value)
    setStep(step + 12);
  }

  const handleBudgetTotalBtp = e => {
    setBudgetTotalBtp(e.target.value)
    setStep(step + 1)
  }

  const handlePrecommercialisation = e => {
    setPrecommercialisation(e.target.value)
    setStep(step + 1)
  }


  //   const handleTypeInvestissement = e => {
  //     setTypeInvestissement(e.target.value)
  //     if (e.target.value !== 'type_invest_autre') {
  //         setStep(step + 1)
  //     }
  //     // setStep(step + 1)
  // }

  const handleSelectedStudy = e => {
    setSelectedStudy(e.target.value)
    if (e.target.value === 'study_non') {
      setStep(step + 1)
    }

  }
  const handleNumberProject = e => {
    setNumberProject(e.target.value)
    setStep(step + 1)
  }
  const handleFinancement = e => {
    setSelectedFinancement(e.target.value)
    if (e.target.value === 'financement_non') {
      setStep(step + 1)
    }
  }
  const handleCalendar = e => {
    setCalendar(e.target.value)
    setStep(step + 1)
  }
  const handleSelectedSubscriber = e => {
    setSelectedSubscriber(e.target.value)
    if (e.target.value === 'subscriber_non') {
      setStep(step + 1)
    }
  }

  const handleBusinessPlan = e => {
    setBusinessPlan(e.target.value)
    setStep(step + 1)
  }
  const handlePlanDeTresorerie = e => {
    setPlanTresorerie(e.target.value)
    setStep(step + 1)
  }
  const handleHaveAbe = e => {
    setHaveAbe(e.target.value)
    handleSubmit();
  }


  return (
    <div style={{ overflow: 'clip' }}>
      {step === 1 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div class="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={onBack}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div class="p-5">
                  <div class="step-heading">
                    <div class="heading-marker"></div>
                    <TypeAnimation
                      sequence={[
                        "Spécifier le secteur d'activité",
                        1000,

                        // () => {
                        //   console.log('Sequence completed');
                        // },
                      ]}
                      wrapper="div"
                      cursor={false}
                      className='heading'
                    // style={{ fontSize: '2em', display: 'inline-block' }}
                    />

                  </div>

                  {/* <!-- step-1 form--> */}
                  <div class="pop-right step-form" id="step1">
                    <div class="row justify-content-center">
                      <div class="tab-50 col-md-4" onClick={handleImmobilier}>
                        <div class="radio-field p-5">
                          <div class="icon mx-auto">
                            <img src={Service1} alt="service 1" />
                          </div>
                          <div class="service-text">
                            Immobilier
                          </div>
                          <input class="gradiant_border" type="radio" checked={selectedImmobilier} />
                        </div>
                      </div>
                      <div class="tab-50 col-md-4" onClick={handleInfrastructure}>
                        <div class="radio-field p-5">
                          <div class="icon mx-auto">
                            <img src={Service2} alt="service 2" />
                          </div>
                          <div class="service-text">
                            Infrastructure
                          </div>
                          <input class="gradiant_border" type="radio" checked={selectedInfrastructure} />
                        </div>
                      </div>

                    </div>
                  </div>

                  {/* <!-- next button--> */}
                  {/* <div class="next-prev-btn">
                    <button type="button" id="step1btn" className='prev' onClick={onBack}>Précédent</button>
                    <button type="button" id="step1btn" style={{ marginLeft: "1rem", backgroundImage: (selectedImmobilier || selectedInfrastructure) ? 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' : '' }} onClick={() => nextStep()}>Continuer</button>
                  </div> */}
                </div>
              </div>
              {/* <div class="steps-counter">
              <span>Step: 1/5</span>
              <div class="step-counter-inner">
                <div class="a0 step-move"></div>
              </div>
            </div> */}
            </div>
          </section>

        </form>
      )}
      {step === 2 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              {selectedImmobilier &&
                <div className="step-inner container">
                  <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                    <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                    <p className='ms-2'>Retour</p>
                  </div>
                  <div className="p-5">
                    <article>
                      <div className="step-heading text-center mb-5">
                        <div className="heading-marker"></div>
                        <TypeAnimation
                          sequence={[
                            "A quel usage est destiné le bien immobilier ?",
                            1000,

                            // () => {
                            //   console.log('Sequence completed');
                            // },
                          ]}
                          wrapper="h3"
                          cursor={false}
                          className='heading text-center'
                        // style={{ fontSize: '2em', display: 'inline-block' }}
                        />
                      </div>
                    </article>

                    {/* <!-- step-2-form --> */}
                    <div className="pop-right step-form mx-auto" id="step3">
                      <div className='form-part'>
                        <div className='row mb-3'>
                          <div class="col-md-12">
                            <div class="select-field">
                              <select name="select-service" id="selectservice" value={usageImmobilier} onChange={handleUsageImmobilier}>
                                <optgroup label='Selectionnez'>
                                  <option hidden>Faites votre choix</option>
                                  <option value="usage_bureau">Bureau</option>
                                  <option value="usage_centre_commercial">Centre commercial</option>
                                  <option value="usage_logement">Logement</option>
                                  <option value="usage_entrepot">Entrepot</option>
                                  <option value="usage_atelier">Atelier</option>
                                  <option value="usage_loisir">Centre de loisir</option>
                                  <option value="usage_autre">Autres</option>
                                </optgroup>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!-- next previous button --> */}
                    {/* <div className="next-prev-btn">
                      <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                      <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                    </div> */}
                  </div>
                </div>
              }
              {selectedInfrastructure &&
                <div className="tab-sm-100 step-3 col-lg-9 col-md-8" style={{ paddingLeft: 0, margin: 'auto' }}>
                  <div className="step-inner">
                    <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                      <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                      <p className='ms-2'>Retour</p>
                    </div>
                    <div className="p-5">
                      <article className='mb-5'>
                        <div className="step-heading text-center">
                          <div class="heading-marker"></div>
                          <TypeAnimation
                            sequence={[
                              "Veuillez specifier le secteur.",
                              1000,

                              // () => {
                              //   console.log('Sequence completed');
                              // },
                            ]}
                            wrapper="h3"
                            cursor={false}
                            className='heading text-center'
                          // style={{ fontSize: '2em', display: 'inline-block' }}
                          />
                        </div>
                        {/* <div className="step-text">
                  I see great value in using collaborative tools to make my work more effective and efficient.
                </div> */}
                      </article>

                      {/* <!-- step-2-form --> */}
                      <div className="pop-right step-form" id="step3">
                        <div className='form-part'>
                          <div className='row'>
                            <div class="select-field">
                              <select name="select-service" id="selectservice" value={secteur} onChange={handleSecteur}>
                                <optgroup label='Selectionnez'>
                                  <option hidden>Faites votre choix</option>
                                  <option value="secteur_route">ROUTE</option>
                                  <option value="secteur_eau">EAU</option>
                                  <option value="secteur_education">EDUCATION</option>
                                  <option value="secteur_energie">ENERGIE</option>
                                  <option value="secteur_transport">TRANSPORT</option>
                                  <option value="secteur_collecte">COLLECTE/TRAITEMENT DE DECHETS</option>
                                  <option value="secteur_telecoms">TELECOMS</option>
                                </optgroup>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!-- next previous button --> */}
                      {/* <div className="next-prev-btn">
                        <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                        <button type="button"
                          id="step3btn"
                          className="next"
                          onClick={nextStep}
                          style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                      </div> */}
                    </div>
                  </div>
                </div>
              }
            </div>
          </section>

        </form>
      )}

      {/* Infrastructure Steps */}
      {step === 3 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Spécifier le type de marché",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />

                    </div>
                  </article>
                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={typeDeMarcher} onChange={handleTypeDeMarcher}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="marche_public">Marché public</option>
                                <option value="marche_ppp">PPP</option>
                                <option value="marche_prive">Marché privé</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStepForTypeMarket} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

        </form>
      )}
      {step === 4 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Avez vous le bon de commande ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />

                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={bonDeCommande} onChange={handleBonDeCommande}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="budget_total_1">Oui</option>
                                <option value="budget_total_2">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

        </form>
      )}
      {step === 5 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Disposez vous d'une lettre d'attribution du marché ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />


                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={lettreAttribution} onChange={handleLettreAttribution}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="lettre_attribution_oui">Oui</option>
                                <option value="lettre_attribution_non">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStepForPublicMarket} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

        </form>
      )}
      {step === 6 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStepForPrivateMarket}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Disposez vous de contrats commerciaux à financer ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />


                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={contractCommerciaux} onChange={handleContratCommerciaux}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="contrat_commerciaux_oui">Oui</option>
                                <option value="contrat_commerciaux_non">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                        {contractCommerciaux === "contrat_commerciaux_oui" &&
                          <div className='col-md-12'>
                            <h3 className="text-center mb-3">Montant du contrat à préfinancer</h3>
                            <div class="select-field">
                              <input type="text" placeholder="Entrer le montant du contrat à préfinancer" value={montantContratAFinancer} onChange={e => setMontantContratAFinancer(e.target.value)} />
                            </div>
                            <div>
                              <h3 className='text-center mb-3'>Nom du/des clients</h3>
                              {/* Afficher tous les éléments de la liste */}
                              {contratClientNames.map((item, index) => (
                                <div key={index} className="form-group">
                                  <div className="row align-items-center">
                                    <div className="col-md-10">
                                      <div className='select-field'>
                                        <input key={index} type="text" placeholder="Saisir le nom" value={item.name} onChange={e => handleInputsContractClients(index, e)} />
                                      </div>
                                    </div>
                                    {contratClientNames.length > 1 && (
                                      <div className='col-md-2'>
                                        <FontAwesomeIcon icon={faMinus} onClick={() => handleDeleteContractClients(index)} className='cursor-pointer p-1' style={{ cursor: "pointer", border: "2px solid red", borderRadius: "50px", color: "red" }} />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}

                              <div className='container'>
                                <FontAwesomeIcon icon={faPlus} onClick={handleAddContractClients} className='cursor-pointer p-1' style={{ cursor: "pointer", border: "2px solid #8e736a", borderRadius: "50px", color: "#8e736a" }} />
                              </div>
                            </div>
                            <div className="next-prev-btn">
                              {/* <button type="button" className="prev" onClick={prevStepForPrivateMarket}>Précédent</button> */}
                              <button type="button" id="step3btn" className="next" onClick={nextStepForPrivateMarket} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStepForPrivateMarket}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStepForPrivateMarket} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

        </form>
      )}
      {step === 7 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStepForPPPMarket}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Êtes vous en disposition d'un contrat de concession BOT? ",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />


                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={contratDeConcessionBot} onChange={handleContratDeCessessionBot}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="contrat_concession_bot_oui">Oui</option>
                                <option value="contrat_concession_bot_non">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStepForPPPMarket}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStepForPPPMarket} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

        </form>
      )}

      {/* End Infrastructure Steps */}


      {/* Immobilier Steps */}
      {step === 8 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStepForImmobilier}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Quelles sont les étapes déja realisées ? ",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />

                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="row justify-content-center">
                            {categories.map((category) => {
                              return <div class="category">
                                <div class="category-inner">
                                  <input class="gradiant_border" type="checkbox" name="category" value={category} checked={selectedCategory.includes(category)} onChange={handleCheckboxChange} />
                                  <label>{category}</label>
                                </div>
                              </div>
                            })}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  {/* <!-- next previous button --> */}
                  <div className="next-prev-btn">
                    {/* <button type="button" className="prev" onClick={prevStepForImmobilier}>Précédent</button> */}
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </form>
      )}
      {step === 9 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />

              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Quel est le budget total estimé pour votre projet BTP ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />


                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={budgetTotalBtp} onChange={handleBudgetTotalBtp}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="budget_total_1">Moins de 200 000 000 F.CFA</option>
                                <option value="budget_total_2">200 000 000 F.CFA - 500 000 000 F.CFA</option>
                                <option value="budget_total_3">500 000 000 F.CFA - 1 000 000 000 F.CFA</option>
                                <option value="budget_total_4">Plus de 1 000 000 000 F.CFA</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 10 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "La pré-commercialisation a t'elle déja débuté ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />


                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={precommercialisation} onChange={handlePrecommercialisation}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="precommercialisation_oui">Oui</option>
                                <option value="precommercialisation_non">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 11 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Où le site est-il localisé ? ",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />

                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <input type="text" placeholder="Saisir l'adresse" value={siteAdress} onChange={e => setSiteAdress(e.target.value)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  <div className="next-prev-btn">
                    {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 12 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />

              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Quelle est la superficie du site ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />

                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <input type="text" placeholder="Saisir la superficie en m²" value={siteSuperficie} onChange={e => setSiteSuperficie(e.target.value)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  <div className="next-prev-btn">
                    {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 13 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />

              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Valeur du site",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />

                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <input type="text" placeholder="Saisir la valeur du site" value={valeurSite} onChange={e => setValeurSite(e.target.value)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  <div className="next-prev-btn">
                    {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 14 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />

              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "les études ont t'elles déja été réalisées ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />

                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={selectedStudy} onChange={handleSelectedStudy} >
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="study_oui">Oui</option>
                                <option value="study_non">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                        {selectedStudy === "study_oui" && <div className='col-md-12'>
                          <h3 className='text-center mb-3'>Entrer le montant des études</h3>
                          <div class="select-field">
                            <input type="text" placeholder="Entrer le montant des études" value={studyNames} onChange={e => setStudyNames(e.target.value.replace(/\D/g, ''))} />
                          </div>
                          <div>
                            <h3 className='text-center mb-3'>Nom des prestataires ayant effectué les travaux</h3>
                            {/* Afficher tous les éléments de la liste */}
                            {items.map((item, index) => (
                              <div key={index} className="form-group">
                                <div className="row align-items-center">
                                  <div className="col-md-10">
                                    <div className='select-field'>
                                      <input key={index} type="text" placeholder="Saisir le nom" value={item.name} onChange={e => handleInputsChange(index, e)} />
                                    </div>
                                  </div>
                                  {items.length > 1 && (
                                    <div className='col-md-2'>
                                      <div className='container'>
                                        <FontAwesomeIcon icon={faMinus} onClick={() => handleDeleteItem(index)} className='cursor-pointer p-1' style={{ cursor: "pointer", border: "2px solid red", borderRadius: "50px", color: "red" }} />
                                      </div>

                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                            <div className='container'>
                              <FontAwesomeIcon icon={faPlus} onClick={handleAddItem} className='cursor-pointer p-1' style={{ cursor: "pointer", border: "2px solid #8e736a", borderRadius: "50px", color: "#8e736a" }} />
                            </div>
                          </div>
                          <div className="next-prev-btn">
                            {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                            <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                          </div>
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 15 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Combien de projets avez vous eu l'opportunité de conduire ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />

                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={numberProject} onChange={handleNumberProject}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="number_0">0</option>
                                <option value="number_1">1</option>
                                <option value="number_2">2</option>
                                <option value="number_3">3</option>
                                <option value="number_plus_3">Plus de 3</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 16 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Des banques ou institutions ont-elles déjà été approché pour le projet ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />

                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={selectedFinancement} onChange={handleFinancement}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="financement_oui">Oui</option>
                                <option value="financement_non">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                        {selectedFinancement === "financement_oui" && <div className='col-md-12'>
                          <h3 className='text-center mb-3'>Entrer le montant du financement reçu</h3>
                          <div class="select-field">
                            <input type="text" placeholder="Entrer le montant du financement reçu" value={montantFinancement} onChange={e => setMontantFinancement(e.target.value.replace(/\D/g, ''))} />
                          </div>
                          <h3 className='text-center mb-3'>Type de financement</h3>
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={typeFinancement} onChange={e => setTypeFinancement(e.target.value)} >
                              <optgroup label='Selectionnez'>
                                <option hidden>Type de financement</option>
                                <option value="type_fond_propre">Fonds Propre</option>
                                <option value="type_dette_court_terme">Dette à Court Terme</option>
                                <option value="type_dette_moyen_terme">Dette à Moyen Terme</option>
                                <option value="type_dette_long_terme">Dette à Long Terme</option>
                                <option value="type_subvention">Subvention</option>
                                <option value="type_capital_risque">Capital Risque</option>
                              </optgroup>
                            </select>
                          </div>
                          <div className="next-prev-btn">
                            {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                            <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                          </div>
                        </div>
                        }
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 17 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />

              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Disposez-vous d'un calendrier détaillé des travaux ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />

                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={calendar} onChange={handleCalendar}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="calendar_oui">Oui</option>
                                <option value="calendar_pending">En cours de finalisation</option>
                                <option value="calendar_non">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 18 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />

              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Avez vous une liste de souscripteurs ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />

                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={selectedSubscriber} onChange={handleSelectedSubscriber}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="subscriber_oui">Oui</option>
                                <option value="subscriber_non">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                        {selectedSubscriber === "subscriber_oui" &&
                          <div className='row'>
                            <h3 className="heading text-center">Nombre de souscripteur</h3>
                            <div className='col-md-10'>
                              <div class="select-field">
                                <input type="text" placeholder="Entrer le nombre de souscripteur" value={subscriberNumber} onChange={e => setSubscriberNumber(e.target.value.replace(/\D/g, ''))} />
                              </div>
                            </div>
                            <div className='col-md-2'>
                              <div className="next-prev-btn-custom">
                                <button type="button" className="prev" onClick={handleGenerateInputs}>Valider</button>
                              </div>
                            </div>
                            <div className='col-md-12'>
                              {subscriber.map((value, index) => (
                                <div key={index} className="select-field">
                                  <input
                                    type="text"
                                    placeholder={`Souscripteur ${index + 1}`}
                                    value={value}
                                    onChange={(event) => handleInputChange(index, event)}
                                  />
                                </div>
                              ))}
                            </div>

                            <div className="next-prev-btn">
                              {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                              <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {/* End Immobilier Steps */}

      {/* Common Steps */}
      {step === 19 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />

              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStepForBusinessPlan}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Le Business Plan a t'il déja été établi ? ",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={businessPlan} onChange={handleBusinessPlan}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="business_plan_oui">Oui</option>
                                <option value="business_plan_non">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStepForBusinessPlan}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 20 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Disposez vous d'un plan prévisionnel de trésorerie ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />

                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={planTresorerie} onChange={handlePlanDeTresorerie}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="plan_tresorerie_oui">Oui</option>
                                <option value="plan_tresorerie_non">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 21 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Quel est le budget total estimé pour votre projet BTP ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <input type="text" placeholder="Entrer le budget" value={budgetTotalBtp2} onChange={e => setBudgetTotalBtp2(e.target.value)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  <div className="next-prev-btn">
                    {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 22 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Avez vous investit dans le projet ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={investedIn} onChange={e => setInvestedIn(e.target.value)}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="investin_in_yes">Oui</option>
                                <option value="investin_in_no">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                        {investedIn === "investin_in_yes" &&
                          <div className='row'>
                            <h3 className="mb-3 text-center">Saisir le montant</h3>
                            <div className='col-md-12'>
                              <div class="select-field">
                                <input type="text" placeholder="Saisir le montant" value={investAmount} onChange={e => setInvestAmount(e.target.value.replace(/\D/g, ''))} />
                              </div>
                            </div>
                          </div>
                        }
                        {investedIn === "investin_in_no" &&
                          <div className='row'>
                            <h3 className="mb-3 text-center">Que serait en pourcentage votre investissement dans le projet?</h3>
                            <div className='col-md-12'>
                              <div class="select-field">
                                <select name="select-service" id="selectservice" value={percentageInvest} onChange={e => setPercentageInvest(e.target.value)}>
                                  <optgroup label='Selectionnez'>
                                    <option hidden>Que serait en pourcentage votre investissement dans le projet?</option>
                                    <option value="percentage_invest_2">entre 1 et 10 %</option>
                                    <option value="percentage_invest_3">Entre 10 et 30 %</option>
                                    <option value="percentage_invest_4">Entre 30 et 50 %</option>
                                    <option value="percentage_invest_5">Plus de 50 %</option>
                                  </optgroup>
                                </select>
                              </div>
                            </div>

                          </div>
                        }
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  <div className="next-prev-btn">
                    {/* <button type="button" className="prev" onClick={prevStep}>Précédent</button> */}
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {step === 23 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Avez vous des attestations de bonne exécution ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-2-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">
                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={haveAbe} onChange={handleHaveAbe}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="have_abe_oui">Oui</option>
                                <option value="have_abe_non">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                        {/* {haveAbe === "have_abe_oui" &&
                          <div>
                            <h3 className='text-center mb-3'>Citez les partenaires</h3>
                            
                            {abePartners.map((item, index) => (
                              <div key={index} className="form-group">
                                <div className="row align-items-center">
                                  <div className="col-md-10">
                                    <div className='select-field'>
                                      <input type="text" placeholder="Saisir le nom" />
                                    </div>
                                  </div>
                                  {abePartners.length > 1 && (
                                    <div className='col-md-2'>
                                      <button onClick={() => handleDeletePartner(index)} type='button' className="btn prev" style={{ backgroundColor: 'red', borderRadius: '50%', border: 0, padding: "1rem", color: "#fff" }}>
                                        Supprimer
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}

                            
                            <div className="next-prev-btn-custom">
                              <button onClick={handleAddPartner} type='button' className="btn">
                                Ajouter un autre partenaire
                              </button>
                            </div>
                          </div>
                        } */}
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
      {/* End Common Steps */}
    </div>
  )
}
