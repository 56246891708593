import React, { useState } from 'react';

import { toast, Slide } from 'react-toastify';
import { TypeAnimation } from 'react-type-animation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import Sibebar from '../../../components/signup/sidebar/sibebar';
import { ProgressBar } from '../progressbar/progressbar';

import { isNotEmptyStringWithoutSpaces } from '../../../helpers/validate';

export function FinancialInstitution({ onBack, onNext }) {

  const [step, setStep] = useState(1);
  const totalSteps = 5;
  const progress = (step / totalSteps) * 100;


  const [typeService, setTypeService] = useState("");
  const [infrastructureTech, setInfrastructureTech] = useState("");
  const [marcheCible, setMarcheCible] = useState("");
  const [projetInstitutionFinanciere, setProjetInstitutionFinanciere] = useState("");
  const [capitalInitial, setCapitalInitial] = useState("");


  const sendToasError = (message, time) => {
    toast.error(message, {
      position: "top-right",
      autoClose: time,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Slide,
    });
  }

  const nextStep = () => {
    if (step === 1) {
      if (!isNotEmptyStringWithoutSpaces(typeService)) {
        return sendToasError('Veuillez faire une selection')
      }
      setStep(step + 1);
    }
    else if (step === 2) {
      if (!isNotEmptyStringWithoutSpaces(infrastructureTech)) {
        return sendToasError('Veuillez faire une selection')
      }
      setStep(step + 1);
    }
    else if (step === 3) {
      if (!isNotEmptyStringWithoutSpaces(marcheCible)) {
        return sendToasError('Veuillez faire une selection')
      }
      setStep(step + 1);
    }
    else if (step === 4) {
      if (!isNotEmptyStringWithoutSpaces(projetInstitutionFinanciere)) {
        return sendToasError('Veuillez faire une selection')
      }
      setStep(step + 1);
    }
    else if (step === 5) {
      if (!isNotEmptyStringWithoutSpaces(capitalInitial)) {
        return sendToasError('Veuillez faire une selection')
      }
      onNext();
    }

  }

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  }
  const handleTypeDeService = e => {
    setTypeService(e.target.value)
    setStep(step + 1)
  }
  const handleInfrastructureTech = e => {
    setInfrastructureTech(e.target.value)
    setStep(step + 1)
  }
  const handleMarcheCible = e => {
    setMarcheCible(e.target.value)
    setStep(step + 1)
  }
  const handleProjetInstitutionFinanciere = e => {
    setProjetInstitutionFinanciere(e.target.value)
    setStep(step + 1)
  }
  const handleCapitalInitial = e => {
    setCapitalInitial(e.target.value)
    onNext()
  }

  return (
    <div style={{ overflow: 'clip' }}>
      {step === 1 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={onBack}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Quels types de services financiers prévoyez-vous offrir ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={typeService} onChange={handleTypeDeService}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Comptes bancaires</option>
                                <option value="usage_centre_commercial">Prêts</option>
                                <option value="usage_logement">Services d'investissement</option>
                                <option value="usage_entrepot">Assurance</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={onBack}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 2 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Disposez-vous d'une infrastructure technologique adéquate pour vos opérations ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={infrastructureTech} onChange={handleInfrastructureTech}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Oui</option>
                                <option value="usage_centre_commercial">En cours d'installation</option>
                                <option value="usage_logement">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 3 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Quels sont vos principaux marchés cibles ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={marcheCible} onChange={handleMarcheCible}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">B2B</option>
                                <option value="usage_centre_commercial">B2C</option>
                                <option value="usage_logement">B2B2C</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 4 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "À quel stade se trouve votre projet d'institution financière ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={projetInstitutionFinanciere} onChange={handleProjetInstitutionFinanciere}>
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Étude de faisabilité</option>
                                <option value="usage_centre_commercial">Demande de licence</option>
                                <option value="usage_logement">Mise en place des systèmes de gestion</option>
                                <option value="usage_log">Préparation pour le lancement</option>
                                <option value="usage_lo">Opérationnel</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
      {step === 5 && (
        <form id="steps" class="show-section" method="post" enctype="multipart/form-data">
          {/* <!-- step-1 --> */}
          <section class="steps row">
            {/* <!-- step-1-sidebar --> */}
            <Sibebar />
            <div class="step-1 tab-sm-100 col-lg-9 col-md-8" style={{ paddingLeft: 0 }}>
              <ProgressBar progress={progress} />
              <div className="step-inner container">
                <div className='d-flex align-items-center mb-3 mt-4 prev-button' style={{ cursor: 'pointer' }} onClick={prevStep}>
                  <FontAwesomeIcon icon={faArrowLeft} className='icons' />
                  <p className='ms-2'>Retour</p>
                </div>
                <div className="p-5">
                  <article>
                    <div className="step-heading text-center mb-5">
                      <div className="heading-marker"></div>
                      <TypeAnimation
                        sequence={[
                          "Avez-vous sécurisé le capital initial pour lancer l'institution financière ?",
                          1000,

                          // () => {
                          //   console.log('Sequence completed');
                          // },
                        ]}
                        wrapper="h3"
                        cursor={false}
                        className='heading text-center'
                      // style={{ fontSize: '2em', display: 'inline-block' }}
                      />
                    </div>
                  </article>

                  {/* <!-- step-1-form --> */}
                  <div className="pop-right step-form mx-auto" id="step3">

                    <div className='form-part'>
                      <div className='row mb-3'>
                        <div class="col-md-12">
                          <div class="select-field">
                            <select name="select-service" id="selectservice" value={capitalInitial} onChange={handleCapitalInitial} >
                              <optgroup label='Selectionnez'>
                                <option hidden>Faites votre choix</option>
                                <option value="usage_bureau">Oui</option>
                                <option value="usage_centre_commercial">En cours de sécurisation</option>
                                <option value="usage_logement">Mise en place des systèmes de gestion</option>
                                <option value="usage_log">Non</option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- next previous button --> */}
                  {/* <div className="next-prev-btn">
                    <button type="button" className="prev" onClick={prevStep}>Précédent</button>
                    <button type="button" id="step3btn" className="next" onClick={nextStep} style={{ marginLeft: "1rem", backgroundImage: 'linear-gradient(47deg, #8e736a 0%, #8e736a 100%)' }}>Suivant</button>
                  </div> */}
                </div>
              </div>

            </div>
          </section>

        </form>
      )}
    </div>
  )
}