// IMPORT CONFIG & DEPENDENCIES
import {Switch, Route,withRouter} from 'react-router-dom';

// IMPORT COMPONENT
import SignUp from './pages/login/signup';
import PorteurProjet from './pages/login/porteurprojet';
import InvestisseurPrive from './pages/login/investisseurprive';
import InvestisseurInstitutionnel from './pages/login/investisseurinstitutionel';


import './App.css';
import './assets/css/signup/bootstrap.min.css';
import './assets/css/signup/responsive.css';
import './assets/css/signup/animation.css';
import './assets/css/signup/style.css';




const App = () => {
  let route = (
    <Switch>
      <Route  path="/signup" component={SignUp} />
      <Route  path="/porteurprojet" component={PorteurProjet} />
      <Route  path="/investisseurprive" component={InvestisseurPrive} />
      <Route  path="/investisseurinstitutionel" component={InvestisseurInstitutionnel} />
    </Switch>
  )

  return (
    <>
      {route}
    </>
  )
}

export default withRouter(App);
